import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import { Form, Modal, Input, Button, notification, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  LIST_COLLECTION,
  NOTIFICATION_DURATION,
  cancelButtonStyle,
  okButtonStyle,
} from '~/common/constants'
import { DeleteOutlined } from '@ant-design/icons'
// import { REGEX } from '~/helpers/regex'
import { getListOptionItem, getNameItemV3 } from '~/helpers/ItemName'
import { useGift } from '~/views/app/redux/hooks/useGift'
import { isAddress } from 'ethers/lib/utils'
import TextArea from 'antd/es/input/TextArea'
import { formatAddress } from '~/helpers/common'

function ManagementGiftForm(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, refreshData } = props
  const [form] = Form.useForm()
  const [formGift] = Form.useForm()
  const [formAddress] = Form.useForm()
  const [listGift, setListGift] = useState([])
  const [listAddress, setListAddress] = useState([])
  const [selectedCollection, setSelectedCollection] = useState(null)
  const [selectedAddress, setSelecteAddress] = useState(null)
  const [checkRedonlyType, setCheckRedonlyType] = useState(true)
  const [checkRedonlyAmount, setCheckRedonlyAmount] = useState(true)
  const [exceededItems, setExceededItems] = useState(false)
  const [exceededItemsSubmit, setExceededItemsSubmit] = useState(false)
  const [listGiftError, setListGiftError] = useState([])
  const remaining = 100
  const maxItem = 3000
  const person = 500
  const {
    actions,
    data: { isLoading },
  } = useGift()
  const rules = {
    note: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementGift.note'),
        }),
      },
      {
        max: 255,
        message: t('validate.max256'),
      },
    ],
    to: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementGift.toAddress'),
        }),
      },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (!value) {
              resolve()
            } else {
              const addresses = value
                .split(/,|\n/)
                .map((address) => address.trim())
              for (let address of addresses) {
                if (!isAddress(address)) {
                  reject(t('validate.walletAddress'))
                  return
                }
              }
              resolve()
            }
          })
        },
      },
    ],
    address: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementGift.toAddress'),
        }),
      },
    ],

    BIT: [
      // {
      //   required: true,
      //   message: t('validate.required', {
      //     0: t('managementGift.BIT'),
      //   }),
      // },
      // {
      //   pattern: REGEX.INT_NUMBER,
      //   message: t('validate.validIntPrice'),
      // },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (!value) {
              resolve()
            } else if (value <= 0) {
              reject(t('common.schema.bitAmountSell'))
            } else if (isNaN(value) || !Number.isInteger(parseFloat(value))) {
              reject(t('validate.validIntPrice'))
            } else {
              resolve()
            }
          })
        },
      },
    ],
    maPoint: [
      // {
      //   required: true,
      //   message: t('validate.required', {
      //     0: t('managementGift.maPoint'),
      //   }),
      // },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (!value) {
              resolve()
            } else if (value <= 0) {
              reject(t('common.schema.maAmountSell'))
            } else if (isNaN(value) || !Number.isInteger(parseFloat(value))) {
              reject(t('validate.validIntPrice'))
            } else {
              resolve()
            }
          })
        },
      },
    ],
    collection: [
      // {
      //   required: true,
      //   message: t('validate.required', {
      //     0: t('managementGift.collection'),
      //   }),
      // },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (value === undefined) {
              reject(new Error(t('managementGift.collectionRequired')))
            } else {
              resolve()
            }
          })
        },
      },
    ],

    type: [
      // {
      //   required: true,
      //   message: t('validate.required', {
      //     0: t('managementGift.type'),
      //   }),
      // },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (value === undefined) {
              reject(new Error(t('managementGift.typeRequired')))
            } else {
              resolve()
            }
          })
        },
      },
    ],
    amount: [
      // {
      //   required: true,
      //   message: t('validate.required', {
      //     0: t('managementGift.amount'),
      //   }),
      // },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (value === undefined || value === '') {
              reject(new Error(t('managementGift.amountRequired')))
            } else if (value <= 0) {
              reject(t('common.schema.amountSell'))
            } else if (isNaN(value) || !Number.isInteger(parseFloat(value))) {
              reject(t('validate.validIntPrice'))
            } else {
              resolve()
            }
          })
        },
      },
    ],
  }
  useEffect(() => {
    const exceeded = listGift.filter((item) => item.amount > person)
    if (exceeded.length > 0) {
      setExceededItems(true)
    } else {
      setExceededItemsSubmit(false)
      setExceededItems(false)
    }
  }, [listGift])
  const handleSubmit = (values) => {
    const exceededError = listGift.filter((item) => item.amount > person)
    setListGiftError(exceededError)
    if (exceededItems === true) {
      setExceededItemsSubmit(true)
      return
    }

    const items = []
    const Training_Item = listGift.filter(function (item) {
      return String(item?.collection) === 'Training_Item'
    })
    const Enhance_Item = listGift.filter(function (item) {
      return String(item?.collection) === 'Enhance_Item'
    })
    const Fusion_Item = listGift.filter(function (item) {
      return String(item?.collection) === 'Fusion_Item'
    })
    const Regeneration_Item = listGift.filter(function (item) {
      return String(item?.collection) === 'Regeneration_Item'
    })
    if (Training_Item?.length > 0) {
      const dataPushTraining_Item = {
        collection: 1,
        type: Training_Item?.map((data) => data?.type),
        number: Training_Item?.map((data) => Number(data?.amount)),
      }
      items?.push(dataPushTraining_Item)
    }
    if (Enhance_Item?.length > 0) {
      const dataPushEnhance_Item = {
        collection: 1,
        type: Enhance_Item?.map((data) => data?.type),
        number: Enhance_Item?.map((data) => Number(data?.amount)),
      }
      items?.push(dataPushEnhance_Item)
    }
    if (Fusion_Item?.length > 0) {
      const dataPushFusion_Item = {
        collection: 1,
        type: Fusion_Item?.map((data) => data?.type),
        number: Fusion_Item?.map((data) => Number(data?.amount)),
      }
      items?.push(dataPushFusion_Item)
    }
    if (Regeneration_Item?.length > 0) {
      const dataPushRegeneration_Item = {
        collection: 1,
        type: Regeneration_Item?.map((data) => data?.type),
        number: Regeneration_Item?.map((data) => Number(data?.amount)),
      }
      items?.push(dataPushRegeneration_Item)
    }
    const addresses = listAddress.map((item) => item.to)
    const formData = {
      address: addresses,
      items: items,
      bit: Number(values?.bit),
      maPoint: Number(values?.maPoint),
      note: values?.note,
    }
    if (items.length === 0 && !formData.bit && !formData.maPoint) {
      notification.error({
        // message: t('common.message.error'),
        message: 'Gift is empty!',
        duration: NOTIFICATION_DURATION,
      })
      return
    }
    return actions?.sendGift(formData, (res) => {
      if (res?.message === 'Mint gift successfully!') {
        form.resetFields()
        form.resetFields(['note', 'maPoint'])
        formGift.resetFields()
        formAddress.resetFields()
        setListGift([])
        setListAddress([])
        setSelecteAddress(null)
        setExceededItems([])
        onSuccess()
      }
    })
  }

  const handleAddGift = (values) => {
    // eslint-disable-next-line
    const formData = {
      id: values?.collection + values?.type,
      collection: values?.collection,
      type: values?.type,
      amount: values?.amount,
    }
    var exists = listGift.some(function (obj) {
      return obj.id === formData?.id
    })
    if (exists) {
      var index = listGift.findIndex(function (obj) {
        return obj.id === formData?.id
      })
      const updatedList = [...listGift]

      updatedList[index] = { ...updatedList[index], amount: values?.amount }
      setListGift(updatedList)
    } else {
      setListGift((old) => [...old, formData])
    }
    formGift.resetFields()
    setCheckRedonlyType(true)
    setCheckRedonlyAmount(true)
  }

  const handleAddAddress = (values) => {
    const addresses = values?.to.split(/,|\n/).map((address) => address.trim())
    const uniqueAddresses = []

    addresses.forEach((toAddress) => {
      if (
        toAddress &&
        !uniqueAddresses.includes(toAddress) &&
        !listAddress.some((item) => item.to === toAddress)
      ) {
        const formData = {
          address: values?.address,
          to: toAddress,
        }
        uniqueAddresses.push(toAddress)
        setListAddress((old) => [...old, formData])
      }
    })

    formAddress.resetFields(['to'])
  }

  const onSuccess = () => {
    notification.success({
      message: t('common.message.updateSuccessfully'),
      duration: NOTIFICATION_DURATION,
    })
    onClose()
    refreshData()
  }

  const handleClose = () => {
    form.resetFields()
    form.resetFields(['note', 'maPoint'])
    formGift.resetFields()
    formAddress.resetFields()
    setListGift([])
    setListAddress([])
    setSelecteAddress(null)
    onClose()
  }
  const handleCollectionChange = (value) => {
    setSelectedCollection(value)
    setCheckRedonlyType(false)
    formGift.resetFields(['type']) // Reset the 'type' field when 'collection' changes
  }
  const handleTypeChange = () => {
    setCheckRedonlyAmount(false)
  }
  const handleAddressChange = (value) => {
    setSelecteAddress(value)
    formAddress.resetFields(['to'])
    setListAddress([])
  }
  const optionToAddress = [
    {
      value: 'All User Addresses',
      label: 'All User Addresses',
    },
    {
      value: 'Specified Addresses',
      label: 'Specified Addresses',
    },
  ]

  return (
    <Modal
      title={t('common.btn.sendGift')}
      onCancel={handleClose}
      open={isShowModal}
      width={650}
      centered
      destroyOnClose
      footer={
        <div key="buttons" className="flex justify-center">
          <Button key="cancel" onClick={handleClose} style={cancelButtonStyle}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            onClick={form.submit}
            className="border-hidden"
            key="ok"
            type="primary"
            style={okButtonStyle}
            loading={isLoading}
          >
            {t('common.btn.send')}
          </Button>
        </div>
      }
    >
      <Form
        name="SendGift"
        layout="vertical"
        onFinish={handleSubmit}
        onSubmit={(e) => e.preventDefault()}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        <Form.Item
          label={t('managementGift.note')}
          name="note"
          rules={rules.note}
          labelAlign="left"
        >
          <Input placeholder={t('managementGift.note')} />
        </Form.Item>

        <Form
          name="addGift"
          layout="vertical"
          onFinish={handleAddAddress}
          form={formAddress}
          translate="yes"
          autoComplete="off"
          size="middle"
          validateTrigger={['onBlur', 'onChange']}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              formAddress.submit()
            }
          }}
          initialValues={{ address: 'All User Addresses' }}
        >
          <Form.Item
            label={t('managementGift.toAddress')}
            name="address"
            rules={rules.address}
            labelAlign="left"
          >
            <Select
              options={optionToAddress}
              // placeholder={t('managementGift.collection')}
              onChange={handleAddressChange}
            ></Select>
          </Form.Item>
          {selectedAddress === 'Specified Addresses' ? (
            <div>
              <Form.Item
                // label={t('managementGift.type')}
                name="to"
                rules={rules.to}
                labelAlign="left"
              >
                <TextArea
                  placeholder={t('managementGift.toAddress')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.shiftKey) {
                      e.preventDefault()
                      const value = formAddress.getFieldValue('to')
                      formAddress.setFieldsValue({ to: `${value}\n` })
                    } else if (e.key === 'Enter' && !e.shiftKey) {
                      formAddress.submit()
                    }
                  }}
                />
              </Form.Item>
              <Button
                onClick={formAddress.submit}
                type="primary"
                className="mb-3"
              >
                {t('common.btn.add')}
              </Button>
              {listAddress?.map((data, i) => (
                <React.Fragment key={i?.toString()}>
                  <div
                    className={`rounded-[6px] p-[10px] w-full h-[44px] flex items-center justify-between mb-2 bg-[#314385] ${data?.collection} ${data?.type}`}
                  >
                    <div className={`flex`}>
                      <div>{formatAddress(data?.to)}</div>
                    </div>
                    <DeleteOutlined
                      onClick={() => {
                        const newList = listAddress.filter(function (item) {
                          return String(item?.to) !== String(data?.to)
                        })

                        setListAddress(newList)
                      }}
                      twoToneColor="#FF4D4F"
                      style={{ color: '#FF4D4F' }}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          ) : (
            ''
          )}
        </Form>

        <Form.Item
          label={t('managementGift.BIT')}
          name="bit"
          rules={rules.BIT}
          labelAlign="left"
        >
          <Input placeholder={t('managementGift.BIT')} />
        </Form.Item>

        <Form.Item
          label={t('managementGift.maPoint')}
          name="maPoint"
          rules={rules.maPoint}
          labelAlign="left"
        >
          <Input placeholder={t('managementGift.maPoint')} />
        </Form.Item>
      </Form>

      <hr className="my-3" />
      <Form
        name="addGift"
        layout="vertical"
        onFinish={handleAddGift}
        form={formGift}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        <Form.Item
          label={t('managementGift.collection')}
          name="collection"
          rules={rules.collection}
          labelAlign="left"
        >
          <Select
            options={LIST_COLLECTION?.map((data, i) => {
              const collection = {
                key: i?.toString(),
                value: data,
                label: t(`managementGift.${data}`),
              }
              return collection
            })}
            placeholder={t('managementGift.collection')}
            onChange={handleCollectionChange}
          ></Select>
        </Form.Item>

        <Form.Item
          label={t('managementGift.type')}
          name="type"
          rules={rules.type}
          labelAlign="left"
        >
          <Select
            disabled={checkRedonlyType}
            onChange={handleTypeChange}
            options={getListOptionItem(selectedCollection, remaining, maxItem)}
            placeholder={t('managementGift.type')}
            showSearch
            filterOption={(input, option) =>
              option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>

        <Form.Item
          label={t('managementGift.amount')}
          name="amount"
          rules={rules.amount}
          labelAlign="left"
        >
          <Input
            placeholder={t('managementGift.amount')}
            disabled={checkRedonlyAmount}
          />
        </Form.Item>

        <Button onClick={formGift.submit} type="primary" className="mb-3">
          {t('common.btn.add')}
        </Button>
      </Form>
      {listGift?.map((data, i) => {
        const isError = listGiftError.some(
          (errorItem) => errorItem.id === data.id,
        )

        return (
          <React.Fragment key={i?.toString()}>
            <div
              className={`rounded-[6px] p-[10px] w-full h-[44px] flex items-center justify-between mb-2 bg-[#314385] ${data?.collection} ${data?.type}`}
            >
              <div className={`flex`}>
                <div className="mr-2 flex items-center object-contain">
                  <img
                    width={24}
                    height={24}
                    src={`${
                      process.env.REACT_APP_BASE_URL_IMAGE
                    }/public/image/image/items/${
                      getNameItemV3(data?.type)?.image
                    }.png`}
                    alt=""
                  />
                </div>
                <div style={{ color: isError ? '#FF4D4F' : '' }}>
                  {t(`managementGift.${data?.collection}`)}{' '}
                  {getNameItemV3(data?.type)?.name +
                    ' ' +
                    getNameItemV3(data?.type)?.quanlity}{' '}
                  x{data?.amount}
                </div>
              </div>

              <DeleteOutlined
                onClick={() => {
                  const newList = listGift.filter(function (item) {
                    return String(item?.id) !== String(data?.id)
                  })

                  setListGift(newList)
                }}
                twoToneColor="#FF4D4F"
                style={{ color: '#FF4D4F' }}
              />
            </div>
          </React.Fragment>
        )
      })}
      {exceededItemsSubmit === true && (
        <div style={{ color: '#ff4d4f' }}>
          {t('managementGift.messageCheckSendGift')}
        </div>
      )}
    </Modal>
  )
}

ManagementGiftForm.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default ManagementGiftForm
