export const GET_LIST_ADMIN_START = 'GET_LIST_ADMIN_START'
export const GET_LIST_ADMIN_SUCCESS = 'GET_LIST_ADMIN_SUCCESS'
export const GET_LIST_ADMIN_FAILED = 'GET_LIST_ADMIN_FAILED'

export const CREATE_ADMIN_START = 'CREATE_ADMIN_START'
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS'
export const CREATE_ADMIN_FAILED = 'CREATE_ADMIN_FAILED'

export const GET_DETAIL_ADMIN_START = 'GET_DETAIL_ADMIN_START'
export const GET_DETAIL_ADMIN_SUCCESS = 'GET_DETAIL_ADMIN_SUCCESS'
export const GET_DETAIL_ADMIN_FAILED = 'GET_DETAIL_ADMIN_FAILED'

export const UPDATE_ADMIN_START = 'UPDATE_ADMIN_START'
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS'
export const UPDATE_ADMIN_FAILED = 'UPDATE_ADMIN_FAILED'

export const DELETE_ADMIN_START = 'DELETE_ADMIN_START'
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS'
export const DELETE_ADMIN_FAILED = 'DELETE_ADMIN_FAILED'

//-------------------------user------------------------//
/**
 * [1]
 * get list user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListAdmin(payload, onSuccess, onError) {
  return {
    type: GET_LIST_ADMIN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list user success action
 * @param {*} payload
 * @returns {object}
 */
export function getListAdminSuccess(payload) {
  return {
    type: GET_LIST_ADMIN_SUCCESS,
    payload: payload,
  }
}

/**
 * get list user failed action
 * @returns {object}
 */
export function getListAdminFailed() {
  return {
    type: GET_LIST_ADMIN_FAILED,
  }
}

/**
 * [2]
 * create user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createAdmin(payload, onSuccess, onError) {
  return {
    type: CREATE_ADMIN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * create user success action
 * @param {*} payload
 * @returns {object}
 */
export function createAdminSuccess(payload) {
  return {
    type: CREATE_ADMIN_SUCCESS,
    payload: payload,
  }
}

/**
 * create user failed action
 * @returns {object}
 */
export function createAdminFailed() {
  return {
    type: CREATE_ADMIN_FAILED,
  }
}

/**
 * [3]
 * get detail user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDetailAdmin(payload, onSuccess, onError) {
  return {
    type: GET_DETAIL_ADMIN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get detail user success action
 * @param {*} payload
 * @returns {object}
 */
export function getDetailAdminSuccess(payload) {
  return {
    type: GET_DETAIL_ADMIN_SUCCESS,
    payload: payload,
  }
}

/**
 * get detail user failed action
 * @returns {object}
 */
export function getDetailAdminFailed() {
  return {
    type: GET_DETAIL_ADMIN_FAILED,
  }
}

/**
 * [4]
 * update user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateAdmin(payload, onSuccess, onError) {
  return {
    type: UPDATE_ADMIN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update user success action
 * @param {*} payload
 * @returns {object}
 */
export function updateAdminSuccess(payload) {
  return {
    type: UPDATE_ADMIN_SUCCESS,
    payload: payload,
  }
}

/**
 * update user failed action
 * @returns {object}
 */
export function updateAdminFailed() {
  return {
    type: UPDATE_ADMIN_FAILED,
  }
}

/**
 * [5]
 * delete user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteAdmin(payload, onSuccess, onError) {
  return {
    type: DELETE_ADMIN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * delete user success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteAdminSuccess(payload) {
  return {
    type: DELETE_ADMIN_SUCCESS,
    payload: payload,
  }
}

/**
 * delete user failed action
 * @returns {object}
 */
export function deleteAdminFailed() {
  return {
    type: DELETE_ADMIN_FAILED,
  }
}

export default {
  getListAdmin,
  getListAdminSuccess,
  getListAdminFailed,

  createAdmin,
  createAdminSuccess,
  createAdminFailed,

  getDetailAdmin,
  getDetailAdminSuccess,
  getDetailAdminFailed,

  updateAdmin,
  updateAdminSuccess,
  updateAdminFailed,

  deleteAdmin,
  deleteAdminSuccess,
  deleteAdminFailed,
}
