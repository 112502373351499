import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getDashBoardItemSuccess,
  getDashBoardItemFailed,
  GET_DASHBOARD_ITEM_START,
} from '../../actions/dashboard'

/**
 * get dashBoard bit api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getDashBoardItemApi = (props) => {
  var uri = `/admin/dashboard/shop`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetDashBoardItem(action) {
  try {
    const response = yield call(getDashBoardItemApi, action?.payload)
    if (response?.statusCode !== 400) {
      yield put(getDashBoardItemSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
    } else {
      throw response?.message
    }
  } catch (error) {
    yield put(getDashBoardItemFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get dashBoard bit
 */
export default function* watchGetDashBoardItem() {
  yield takeLatest(GET_DASHBOARD_ITEM_START, doGetDashBoardItem)
}
