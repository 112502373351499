import {
  GET_LIST_COLLABORATION_START,
  GET_LIST_COLLABORATION_SUCCESS,
  GET_LIST_COLLABORATION_FAILED,
  CREATE_COLLABORATION_START,
  CREATE_COLLABORATION_SUCCESS,
  CREATE_COLLABORATION_FAILED,
  GET_DETAIL_COLLABORATION_START,
  GET_DETAIL_COLLABORATION_SUCCESS,
  GET_DETAIL_COLLABORATION_FAILED,
  UPDATE_COLLABORATION_START,
  UPDATE_COLLABORATION_SUCCESS,
  UPDATE_COLLABORATION_FAILED,
  DELETE_COLLABORATION_START,
  DELETE_COLLABORATION_SUCCESS,
  DELETE_COLLABORATION_FAILED,
} from '../actions/collaboration'
import { addKeyToList } from '~/helpers/transform-data'

const initialState = {
  isLoading: false,
  listCollaboration: [],
  collaborationDetail: {},
  total: null,
}

/**
 * collaboration reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function collaborationManagement(state = initialState, action) {
  switch (action.type) {
    // START
    case GET_LIST_COLLABORATION_START:
    case CREATE_COLLABORATION_START:
    case GET_DETAIL_COLLABORATION_START:
    case UPDATE_COLLABORATION_START:
    case DELETE_COLLABORATION_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LIST_COLLABORATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listCollaboration: addKeyToList(
          action.payload?.data,
          action.payload?.currentPage,
          action.payload?.pageSize,
        ),
        total: action.payload.total,
      }
    case CREATE_COLLABORATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case GET_DETAIL_COLLABORATION_SUCCESS:
      return {
        ...state,
        collaborationDetail: { ...action.payload },
        isLoading: false,
      }
    case UPDATE_COLLABORATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_COLLABORATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    // FAILED
    case GET_LIST_COLLABORATION_FAILED:
    case CREATE_COLLABORATION_FAILED:
    case GET_DETAIL_COLLABORATION_FAILED:
    case UPDATE_COLLABORATION_FAILED:
    case DELETE_COLLABORATION_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}
