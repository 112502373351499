import {
  GET_HISTORY_TRADE_START,
  GET_HISTORY_TRADE_SUCCESS,
  GET_HISTORY_TRADE_FAILED,
} from '../actions/history-trade'

const initialState = {
  isLoading: false,
  allHistory: [],
  total: 0
}

/**
 * History trade reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function historyTradeManagement(state = initialState, action) {
  switch (action.type) {
    case GET_HISTORY_TRADE_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_HISTORY_TRADE_SUCCESS:
      return {
        ...state,
        allHistory: action?.payload?.data,
        total: action?.payload?.total,
        isLoading: false,
      }
    case GET_HISTORY_TRADE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
