export const GET_DASHBOARD_MKP_START = 'GET_DASHBOARD_MKP_START'
export const GET_DASHBOARD_MKP_SUCCESS = 'GET_DASHBOARD_MKP_SUCCESS'
export const GET_DASHBOARD_MKP_FAILED = 'GET_DASHBOARD_MKP_FAILED'

export const GET_DASHBOARD_SHOP_START = 'GET_DASHBOARD_SHOP_START'
export const GET_DASHBOARD_SHOP_SUCCESS = 'GET_DASHBOARD_SHOP_SUCCESS'
export const GET_DASHBOARD_SHOP_FAILED = 'GET_DASHBOARD_SHOP_FAILED'

export const GET_DASHBOARD_BIT_START = 'GET_DASHBOARD_BIT_START'
export const GET_DASHBOARD_BIT_SUCCESS = 'GET_DASHBOARD_BIT_SUCCESS'
export const GET_DASHBOARD_BIT_FAILED = 'GET_DASHBOARD_BIT_FAILED'

export const GET_DASHBOARD_TRADE_START = 'GET_DASHBOARD_TRADE_START'
export const GET_DASHBOARD_TRADE_SUCCESS = 'GET_DASHBOARD_TRADE_SUCCESS'
export const GET_DASHBOARD_TRADE_FAILED = 'GET_DASHBOARD_TRADE_FAILED'

export const GET_DASHBOARD_ITEM_START = 'GET_DASHBOARD_ITEM_START'
export const GET_DASHBOARD_ITEM_SUCCESS = 'GET_DASHBOARD_ITEM_SUCCESS'
export const GET_DASHBOARD_ITEM_FAILED = 'GET_DASHBOARD_ITEM_FAILED'

export const GET_DASHBOARD_GAME_START = 'GET_DASHBOARD_GAME_START'
export const GET_DASHBOARD_GAME_SUCCESS = 'GET_DASHBOARD_GAME_SUCCESS'
export const GET_DASHBOARD_GAME_FAILED = 'GET_DASHBOARD_GAME_FAILED'

//-------------------------Common------------------------//
/**
 * [1]
 * get dashBoard mkp start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDashBoardMkp(payload, onSuccess, onError) {
  return {
    type: GET_DASHBOARD_MKP_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get dashBoard mkp success action
 * @param {*} payload
 * @returns {object}
 */
export function getDashBoardMkpSuccess(payload) {
  return {
    type: GET_DASHBOARD_MKP_SUCCESS,
    payload: payload,
  }
}

/**
 * get dashBoard mkp failed action
 * @returns {object}
 */
export function getDashBoardMkpFailed() {
  return {
    type: GET_DASHBOARD_MKP_FAILED,
  }
}

/**
 * [2]
 * get dashBoard shop start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDashBoardShop(payload, onSuccess, onError) {
  return {
    type: GET_DASHBOARD_SHOP_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get dashBoard shop success action
 * @param {*} payload
 * @returns {object}
 */
export function getDashBoardShopSuccess(payload) {
  return {
    type: GET_DASHBOARD_SHOP_SUCCESS,
    payload: payload,
  }
}

/**
 * get dashBoard shop failed action
 * @returns {object}
 */
export function getDashBoardShopFailed() {
  return {
    type: GET_DASHBOARD_SHOP_FAILED,
  }
}

/**
 * [3]
 * get dashBoard bit start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDashBoardBit(payload, onSuccess, onError) {
  return {
    type: GET_DASHBOARD_BIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get dashBoard bit success action
 * @param {*} payload
 * @returns {object}
 */
export function getDashBoardBitSuccess(payload) {
  return {
    type: GET_DASHBOARD_BIT_SUCCESS,
    payload: payload,
  }
}

/**
 * get dashBoard bit failed action
 * @returns {object}
 */
export function getDashBoardBitFailed() {
  return {
    type: GET_DASHBOARD_BIT_FAILED,
  }
}

/**
 * [5]
 * get dashBoard trade start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDashBoardTrade(payload, onSuccess, onError) {
  return {
    type: GET_DASHBOARD_TRADE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get dashBoard trade success action
 * @param {*} payload
 * @returns {object}
 */
export function getDashBoardTradeSuccess(payload) {
  return {
    type: GET_DASHBOARD_TRADE_SUCCESS,
    payload: payload,
  }
}

/**
 * get dashBoard trade failed action
 * @returns {object}
 */
export function getDashBoardTradeFailed() {
  return {
    type: GET_DASHBOARD_TRADE_FAILED,
  }
}

/**
 * [6]
 * get dashBoard item start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDashBoardItem(payload, onSuccess, onError) {
  return {
    type: GET_DASHBOARD_ITEM_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get dashBoard item success action
 * @param {*} payload
 * @returns {object}
 */
export function getDashBoardItemSuccess(payload) {
  return {
    type: GET_DASHBOARD_ITEM_SUCCESS,
    payload: payload,
  }
}

/**
 * get dashBoard item failed action
 * @returns {object}
 */
export function getDashBoardItemFailed() {
  return {
    type: GET_DASHBOARD_ITEM_FAILED,
  }
}

/**
 * [7]
 * get dashBoard game start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDashBoardGame(payload, onSuccess, onError) {
  return {
    type: GET_DASHBOARD_GAME_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get dashBoard game success action
 * @param {*} payload
 * @returns {object}
 */
export function getDashBoardGameSuccess(payload) {
  return {
    type: GET_DASHBOARD_GAME_SUCCESS,
    payload: payload,
  }
}

/**
 * get dashBoard game failed action
 * @returns {object}
 */
export function getDashBoardGameFailed() {
  return {
    type: GET_DASHBOARD_GAME_FAILED,
  }
}
export default {
  getDashBoardMkp,
  getDashBoardMkpSuccess,
  getDashBoardMkpFailed,

  getDashBoardShop,
  getDashBoardShopSuccess,
  getDashBoardShopFailed,

  getDashBoardBit,
  getDashBoardBitSuccess,
  getDashBoardBitFailed,

  getDashBoardTrade,
  getDashBoardTradeSuccess,
  getDashBoardTradeFailed,

  getDashBoardItem,
  getDashBoardItemSuccess,
  getDashBoardItemFailed,

  getDashBoardGame,
  getDashBoardGameSuccess,
  getDashBoardGameFailed,
}
