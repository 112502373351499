import {
  GET_PROFILE_INFO_START,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_FAILED,
  UPDATE_EMAIL_START,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILED,
  UPDATE_AVATAR_START,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAILED,
  UPDATE_PHONE_START,
  UPDATE_PHONE_SUCCESS,
  UPDATE_PHONE_FAILED,
  SEND_VERIFY_CODE_START,
  SEND_VERIFY_CODE_SUCCESS,
  SEND_VERIFY_CODE_FAILED,
  UPDATE_NAME_START,
  UPDATE_NAME_SUCCESS,
  UPDATE_NAME_FAILED,
} from '../actions/profile'

const initialState = {
  isLoading: false,
  userInfo: {},
  isLoadingEmail: false,
  isLoadingAvatar: false,
  isLoadingPhone: false,
  isLoadingName: false,
}

/**
 * shop reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function profileManagement(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_INFO_START:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_EMAIL_START:
    case SEND_VERIFY_CODE_START:
      return {
        ...state,
        isLoadingEmail: true,
      }
    case UPDATE_AVATAR_START:
      return {
        ...state,
        isLoadingAvatar: true,
      }
    case UPDATE_PHONE_START:
      return {
        ...state,
        isLoadingPhone: true,
      }
    case UPDATE_NAME_START:
      return {
        ...state,
        isLoadingName: true,
      }
    case GET_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload?.user,
        isLoading: false,
      }
    case UPDATE_EMAIL_SUCCESS:
    case SEND_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        isLoadingEmail: false,
      }
    case UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        isLoadingAvatar: false,
      }
    case UPDATE_PHONE_SUCCESS:
      return {
        ...state,
        isLoadingPhone: false,
      }
    case UPDATE_NAME_SUCCESS:
      return {
        ...state,
        isLoadingName: false,
      }
    case GET_PROFILE_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_EMAIL_FAILED:
    case SEND_VERIFY_CODE_FAILED:
      return {
        ...state,
        isLoadingEmail: false,
      }
    case UPDATE_AVATAR_FAILED:
      return {
        ...state,
        isLoadingAvatar: false,
      }
    case UPDATE_PHONE_FAILED:
      return {
        ...state,
        isLoadingPhone: false,
      }
      case UPDATE_NAME_FAILED:
        return {
          ...state,
          isLoadingName: false,
        }
    default:
      return state
  }
}
