import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getListUserSuccess,
  getListUserFailed,
  GET_LIST_USER_START,
} from '~/views/app/redux/actions/user'

/**
 * get list user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListUserApi = (params) => {
  const uri = `/admin/manager-tes-users`
  return api.get(uri, params)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListUser(action) {
  try {
    const response = yield call(getListUserApi, action?.payload)
    yield put(getListUserSuccess(response))

    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getListUserFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list user
 */
export default function* watchGetListUser() {
  yield takeLatest(GET_LIST_USER_START, doGetListUser)
}
