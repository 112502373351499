import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getDetailUserSuccess,
  getDetailUserFailed,
  GET_DETAIL_USER_START,
} from '~/views/app/redux/actions/user'

/**
 * get detail user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getDetailUserApi = (params) => {
  const uri = `/admin/manager-tes-users/${params}`
  return api.get(uri)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */

function* doGetDetailUser(action) {
  try {
    const response = yield call(getDetailUserApi, action?.payload)
    yield put(getDetailUserSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getDetailUserFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}
/**
 * [Watcher]
 * Watch get detail user
 */
export default function* watchGetDetailUser() {
  yield takeLatest(GET_DETAIL_USER_START, doGetDetailUser)
}
