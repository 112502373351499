import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
// import { ThemeSwitcherProvider } from 'react-css-theme-switcher'

import store from './store'
import Routes from './routes'

function App() {
  return (
    <div className="App">
      <Provider store={store}>

          <Suspense fallback={<h3>Loading...</h3>}>
            <Router>
              <Routes />
            </Router>
          </Suspense>
      </Provider>
    </div>
  )
}

export default App
