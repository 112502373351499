import {
    GET_DEPOSIT_POOLS_START,
    GET_DEPOSIT_POOLS_SUCCESS,
    GET_DEPOSIT_POOLS_FAILED,

    GET_WITHDRAW_POOLS_START,
    GET_WITHDRAW_POOLS_SUCCESS,
    GET_WITHDRAW_POOLS_FAILED,

    UPDATE_DEPOSIT_POOLS_START,
    UPDATE_DEPOSIT_POOLS_SUCCESS,
    UPDATE_DEPOSIT_POOLS_FAILED,

    UPDATE_WITHDRAW_POOLS_START,
    UPDATE_WITHDRAW_POOLS_SUCCESS,
    UPDATE_WITHDRAW_POOLS_FAILED,
} from '../actions/pools'

const initialState = {
    isLoadingDeposit: false,
    isLoadingWithdraw: false,
    deposit: 0,
    withdraw: 0,
    isLoading: false
}

/**
 * Pools reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function poolsManagement(state = initialState, action) {
    switch (action.type) {
        case GET_DEPOSIT_POOLS_START:
            return {
                ...state,
                isLoading: true,
            }
        case UPDATE_DEPOSIT_POOLS_START:
            return {
                ...state,
                isLoadingDeposit: true,
            }

        case GET_WITHDRAW_POOLS_START:
        case UPDATE_WITHDRAW_POOLS_START:
            return {
                ...state,
                isLoadingWithdraw: true,
            }
        //
        case GET_DEPOSIT_POOLS_SUCCESS:
            return {
                ...state,
                deposit: action?.payload?.txid?.ranking_pools,
                withdraw: action?.payload?.txid?.hashchip_pools,
                isLoading: false,
            }
        case UPDATE_DEPOSIT_POOLS_SUCCESS:
            return {
                ...state,
                isLoadingDeposit: false,
            }
        case GET_WITHDRAW_POOLS_SUCCESS:
            return {
                ...state,
                withdraw: 0,
                isLoadingWithdraw: false,
            }
        case UPDATE_WITHDRAW_POOLS_SUCCESS:
            return {
                ...state,
                isLoadingWithdraw: false,
            }
        //
        case GET_DEPOSIT_POOLS_FAILED:
            return {
                ...state,
                isLoading: true,
            }
        case UPDATE_DEPOSIT_POOLS_FAILED:
            return {
                ...state,
                isLoadingDeposit: false,
            }

        case GET_WITHDRAW_POOLS_FAILED:
        case UPDATE_WITHDRAW_POOLS_FAILED:
            return {
                ...state,
                isLoadingDeposit: false,
            }
        default:
            return state
    }
}
