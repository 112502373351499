import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from '~/common/constants/ThemeConstant'

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: sessionStorage.getItem('lang') === 'jp' ? 'jp' : 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  direction: DIR_LTR,
  currentTheme: 'light',
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null,
}
