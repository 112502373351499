import { Button, Col, Row } from 'antd'
import React from 'react'
import { useDashboard } from '~/views/app/redux/hooks/useDashboard'
import GameChart from '../components/GameChart'

const GameDashboard = () => {
  const { actions } = useDashboard()

  const handleExport = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/admin/dashboard/campaign-data-csv`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      if (response.ok) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'Closed Beta Campaign.xlsx' // Set the filename here
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } else {
        console.error('Failed to export data')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <React.Fragment>
      <Row gutter={10}>
        <Col span={24}>
          <div>
            <GameChart />
          </div>
        </Col>
        <Button style={{ background: '#1890FF' }} onClick={handleExport}>
          Export Campaign Data
        </Button>
      </Row>
    </React.Fragment>
  )
}

export default GameDashboard
