export const GET_PROFILE_INFO_START = 'GET_PROFILE_INFO_START'
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS'
export const GET_PROFILE_INFO_FAILED = 'GET_PROFILE_INFO_FAILED'

export const UPDATE_EMAIL_START = 'UPDATE_EMAIL_START'
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'
export const UPDATE_EMAIL_FAILED = 'UPDATE_EMAIL_FAILED'

export const UPDATE_AVATAR_START = 'UPDATE_AVATAR_START'
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS'
export const UPDATE_AVATAR_FAILED = 'UPDATE_AVATAR_FAILED'

export const UPDATE_PHONE_START = 'UPDATE_PHONE_START'
export const UPDATE_PHONE_SUCCESS = 'UPDATE_PHONE_SUCCESS'
export const UPDATE_PHONE_FAILED = 'UPDATE_PHONE_FAILED'

export const SEND_VERIFY_CODE_START = 'SEND_VERIFY_CODE_START'
export const SEND_VERIFY_CODE_SUCCESS = 'SEND_VERIFY_CODE_SUCCESS'
export const SEND_VERIFY_CODE_FAILED = 'SEND_VERIFY_CODE_FAILED'

export const UPDATE_NAME_START = 'UPDATE_NAME_START'
export const UPDATE_NAME_SUCCESS = 'UPDATE_NAME_SUCCESS'
export const UPDATE_NAME_FAILED = 'UPDATE_NAME_FAILED'
//-------------------------profile------------------------//
/**
 * [1]
 * get Profile Info start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getProfileInfo(payload, onSuccess, onError) {
    return {
        type: GET_PROFILE_INFO_START,
        payload: payload,
        onSuccess: onSuccess,
        onError: onError,
    }
}

/**
 * get Profile Info success action
 * @param {*} payload
 * @returns {object}
 */
export function getProfileInfoSuccess(payload) {
    return {
        type: GET_PROFILE_INFO_SUCCESS,
        payload: payload,
    }
}

/**
 * get Profile Info failed action
 * @returns {object}
 */
export function getProfileInfoFailed() {
    return {
        type: GET_PROFILE_INFO_FAILED,
    }
}

/**
 * [2]
 * update email start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateEmail(payload, onSuccess, onError) {
    return {
        type: UPDATE_EMAIL_START,
        payload: payload,
        onSuccess: onSuccess,
        onError: onError,
    }
}

/**
 * update email success action
 * @param {*} payload
 * @returns {object}
 */
export function updateEmailSuccess(payload) {
    return {
        type: UPDATE_EMAIL_SUCCESS,
        payload: payload,
    }
}

/**
 * update email failed action
 * @returns {object}
 */
export function updateEmailFailed() {
    return {
        type: UPDATE_EMAIL_FAILED,
    }
}

/**
 * [3]
 * update avatar start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateAvatar(payload, onSuccess, onError) {
    return {
        type: UPDATE_AVATAR_START,
        payload: payload,
        onSuccess: onSuccess,
        onError: onError,
    }
}

/**
 * update avatar success action
 * @param {*} payload
 * @returns {object}
 */
export function updateAvatarSuccess(payload) {
    return {
        type: UPDATE_AVATAR_SUCCESS,
        payload: payload,
    }
}

/**
 * update avatar failed action
 * @returns {object}
 */
export function updateAvatarFailed() {
    return {
        type: UPDATE_AVATAR_FAILED,
    }
}

/**
 * [4]
 * update phone start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updatePhone(payload, onSuccess, onError) {
    return {
        type: UPDATE_PHONE_START,
        payload: payload,
        onSuccess: onSuccess,
        onError: onError,
    }
}

/**
 * update phone success action
 * @param {*} payload
 * @returns {object}
 */
export function updatePhoneSuccess(payload) {
    return {
        type: UPDATE_PHONE_SUCCESS,
        payload: payload,
    }
}

/**
 * update phone failed action
 * @returns {object}
 */
export function updatePhoneFailed() {
    return {
        type: UPDATE_PHONE_FAILED,
    }
}

/**
 * [5]
 * send verify code start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function sendVerifyCode(payload, onSuccess, onError) {
    return {
        type: SEND_VERIFY_CODE_START,
        payload: payload,
        onSuccess: onSuccess,
        onError: onError,
    }
}

/**
 * send verify code success action
 * @param {*} payload
 * @returns {object}
 */
export function sendVerifyCodeSuccess(payload) {
    return {
        type: SEND_VERIFY_CODE_SUCCESS,
        payload: payload,
    }
}

/**
 * send verify code failed action
 * @returns {object}
 */
export function sendVerifyCodeFailed() {
    return {
        type: SEND_VERIFY_CODE_FAILED,
    }
}

/**
 * [6]
 * update Name start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateName(payload, onSuccess, onError) {
    return {
        type: UPDATE_NAME_START,
        payload: payload,
        onSuccess: onSuccess,
        onError: onError,
    }
}

/**
 * update Name success action
 * @param {*} payload
 * @returns {object}
 */
export function updateNameSuccess(payload) {
    return {
        type: UPDATE_NAME_SUCCESS,
        payload: payload,
    }
}

/**
 * update Name failed action
 * @returns {object}
 */
export function updateNameFailed() {
    return {
        type: UPDATE_NAME_FAILED,
    }
}

export default {
    getProfileInfo,
    getProfileInfoSuccess,
    getProfileInfoFailed,

    updateEmail,
    updateEmailSuccess,
    updateEmailFailed,

    updateAvatar,
    updateAvatarSuccess,
    updateAvatarFailed,

    updatePhone,
    updatePhoneSuccess,
    updatePhoneFailed,

    sendVerifyCode,
    sendVerifyCodeSuccess,
    sendVerifyCodeFailed,

    updateName,
    updateNameSuccess,
    updateNameFailed,
}
