import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  sendVerifyCodeSuccess,
  sendVerifyCodeFailed,
  SEND_VERIFY_CODE_START,
} from '../../actions/profile'

/**
 * send verify code api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const sendVerifyCodeApi = (props) => {
  var uri = `/admin/auth/email/verify-email`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doSendVerifyCode(action) {
  try {
    const response = yield call(sendVerifyCodeApi, action?.payload)
      yield put(sendVerifyCodeSuccess(response?.data))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response?.data)
      }
  } catch (error) {
    yield put(sendVerifyCodeFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch send verify code
 */
export default function* watchSendVerifyCode() {
  yield takeLatest(SEND_VERIFY_CODE_START, doSendVerifyCode)
}
