import React from 'react'
import { Modal, Row, Col } from 'antd'
export function ModalVerifySuccess(props) {
  const { action, t } = props
  return (
    <>
      <Modal
        width={572}
        centered
        title={ 
          <h3 className="flex justify-center text-[20px] font-normal text-[#fff]">
            {t('profile.accountSetting.modal.verifySuccess.title')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[]}
      >
        <div className="flex justify-center gap-5 pt-10 flex flex-wrap gap-2 w-full mb-2">
          <div>
            <Row>
              <Col span={24}>
                <div className="flex items-center justify-center mt-[-32px] mb-[32px]">
                  <div>
                    <img
                      className="w-[120px] h-[120px]"
                      src="../svgs/iconSuccess/success.svg"
                      alt=""
                    />
                  </div>
                  <div style={{ position: 'absolute' }}>
                    <img
                      className="w-[51px] h-[51px]"
                      src="../svgs/iconSuccess/success2.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center font__M__plus text-[16px] font-bold">
                  {t('profile.accountSetting.modal.verifySuccess.message')}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  )
}
