import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  updateCollaborationSuccess,
  updateCollaborationFailed,
  UPDATE_COLLABORATION_START,
} from '~/views/app/redux/actions/collaboration'

/**
 * update Collaboration api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateCollaborationApi = (props) => {
  const uri = `/admin/collaboration-nft/${props?.id}`
  return api.put(uri, props?.data)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdateCollaboration(action) {
  try {
    const response = yield call(updateCollaborationApi, action?.payload)

    if (response?.statusCode !== 400) {
      yield put(updateCollaborationSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      } else {
        throw response?.message
      }
    }

  } catch (error) {
    yield put(updateCollaborationFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch update Collaboration
 */
export default function* watchUpdateCollaboration() {
  yield takeLatest(UPDATE_COLLABORATION_START, doUpdateCollaboration)
}
