import React from 'react'
import { Layout, Grid } from 'antd'
import Utils from '~/utils'
import {
  DIR_LTR,
  DIR_RTL,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from '~/common/constants'
import navigationConfig from '~/helpers/navigation-config'
import SideNav from '~/components/layout-components/SideNav'

import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { HeaderNav } from '~/components/layout-components/HeaderNav'
import TopNav from '~/components/layout-components/TopNav'
import MobileNav from '~/components/layout-components/MobileNav'
import EffectShowLeftToRight from '~/components/shared-components/EffectShowLeftToRight'
import { isAuth } from '~/helpers/auth'
import { Redirect } from 'react-router-dom'

const { Content } = Layout

const { useBreakpoint } = Grid

export const AppLayout = ({ direction, location, children }) => {
  const {
    data: { navCollapsed, navType },
  } = useTheme()

  const currentRouteInfo = Utils.getRouteInfo(
    navigationConfig,
    location.pathname,
  )
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP

  const screens = Utils.getBreakPoint(useBreakpoint())
  const isMobile = !screens.includes('lg')

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }

  if (!isAuth()) {
    return <Redirect to="/login" />
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}

      <Layout className="app-container">
        {isNavSide && !isMobile ? (
          <SideNav routeInfo={currentRouteInfo} />
        ) : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content`} style={{ marginTop: 125 }}>
            <Content
              style={{
                minHeight: 280,
              }}
            >
              <EffectShowLeftToRight>{children}</EffectShowLeftToRight>
            </Content>
          </div>
        </Layout>
      </Layout>

      {isMobile && <MobileNav />}
    </Layout>
  )
}

export default React.memo(AppLayout)
