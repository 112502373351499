import { Card, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { useTranslation } from '~/common/hooks/useTranslation'
import { useDashboard } from '~/views/app/redux/hooks/useDashboard'
import moment from 'moment'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const { RangePicker } = DatePicker

const BuySellChart = () => {
  const { actions } = useDashboard()

  // const [date, setDate] = useState(null)
  const [date, setDate] = useState(['', ''])
  const [dataChart, setDataChart] = useState([])

  const [buy_total, setBuyTotal] = useState([])
  const [sell_total, setSellTotal] = useState([])

  // eslint-disable-next-line
  const { t } = useTranslation()
  const labels = dataChart?.map((item) => {
    const date = new Date((item?.timestamp * 1000) / 1000)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    const formattedDate = `${year}/${month}/${day}`
    return formattedDate
  })

  useEffect(() => {
    setBuyTotal(dataChart?.map((item) => item?.params?.buy_total))
    setSellTotal(dataChart?.map((item) => item?.params?.sell_total))
  }, [dataChart])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true,
        labels: {
          color: '#fff',
          usePointStyle: true,
        },
      },
      borderDash: {
        xAxis: [0, 888],
      },
    },

    scales: {
      min: 0,
      y: {
        border: {
          color: '#fff',
        }, // for the grid lines
        grid: {
          display: true,
          color: 'rgba(255, 255, 255, 0.1)',
          tickBorderDash: [1, 10],
          tickColor: 'transparent',
          tickWidth: 2,
          offset: true,
          drawTicks: true,
          drawOnChartArea: true,
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 200,
          beginAtZero: true,
        },
      },
      x: {
        border: {
          color: '#fff',
        },
        grid: {
          display: true,
          borderDash: [5, 5],
          color: 'rgba(255, 255, 255, 0)',
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Buy',
        data: buy_total,
        borderColor: 'rgba(146, 84, 222, 1)',
        backgroundColor: 'rgba(146, 84, 222, 1)',
        tension: 0.4,
      },
      {
        label: 'Sell',
        data: sell_total,
        borderColor: 'rgba(115, 209, 61, 1)',
        backgroundColor: 'rgba(115, 209, 61, 1)',
        tension: 0.4,
      },
    ],
  }

  useEffect(() => {
    const params = {
      type: 'TotalTransaction',
    }
    if (date[0] !== '') {
      let temp = date[0].split('-')
      let temp1 = date[1].split('-')
      params.fromTime = Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0) / 1000
      params.toTime =
        Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59) / 1000
    }
    actions.getDashBoardTrade(params, (res) => {
      setDataChart(res.histories)
    })
  }, [date])

  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }

  const onChangeDate = (values) => {
    setDate(values)
  }
  return (
    <Card
      className=""
      title="Buy and Sell"
      extra={
        <div className="flex gap-4 items-center">
          <RangePicker
            className="h-full max-w-[256px]"
            // onChange={(e) => setDate(e)}
            onChange={(values, dateString) => onChangeDate(dateString)}
            disabledDate={disabledDate}
          />
        </div>
      }
    >
      <div>
        <div className="text-[18px] font-medium">
          {' '}
          {buy_total
            ?.concat?.(sell_total)
            ?.reduce(function (accumulator, currentValue) {
              return accumulator + currentValue
            }, 0)}
        </div>
        <div className="text-[14px] text-[#BFBFBF]">
          {t('common.totalTransaction')}
        </div>
      </div>
      <Line options={options} data={data} />
    </Card>
  )
}

export default BuySellChart
