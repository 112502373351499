import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  deleteAdminSuccess,
  deleteAdminFailed,
  DELETE_ADMIN_START,
} from '~/views/app/redux/actions/admin'

/**
 * delete user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteAdminApi = (props) => {
  const uri = `/admin/manager-user`
  return api.delete(uri, { currentId: props?.currentId, ids: props?.ids })
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doDeleteAdmin(action) {
  try {
    const response = yield call(deleteAdminApi, action?.payload)
    if (response?.status_code !== 400) {
      yield put(deleteAdminSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      throw response?.content?.message
    }
  } catch (error) {
    yield put(deleteAdminFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete user
 */
export default function* watchDeleteAdmin() {
  yield takeLatest(DELETE_ADMIN_START, doDeleteAdmin)
}
