import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import { Form, Modal, Input, Button, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { ICON_BIT, ICON_DOLLAR } from '~/assets/svgs'
import { useShop } from '~/views/app/redux/hooks/useShop'
import { getTypeSale } from '~/helpers/shop'
import { NOTIFICATION_DURATION } from '~/common/constants'
import { convertPrice, formatNumber } from '~/helpers/common'
function ShopForm(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, shop, refreshData, tema, listShops } = props
  const [form] = Form.useForm()
  const filteredListShops = listShops.filter(
    (item) => item.category === shop?.category,
  )
  const [price, setPrice] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [initPriceItem, setInitPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(0)
  const {
    actions,
    data: { isLoading },
  } = useShop()
  const handleChange = (e) => {
    setPrice(Number(e.target.value))
  }
  const handleChangeQuantity = (e) => {
    setQuantity(Number(e.target.value))
  }
  const handleInput = (e) => {
    const { value } = e.target
    if (value > 9999999999) {
      e.target.value = quantity
    }
  }
  const ValidationMessage = ({ messages }) => (
    <span dangerouslySetInnerHTML={{ __html: messages.join('<br />') }} />
  )
  const rules = {
    price: [
      {
        required: true,
        message: t('validate.required', {
          0: t('shopSettings.form.price'),
        }),
      },
      () => ({
        validator(rule, value) {
          const numberPattern = /^-?\d+(\.\d+)?$/
          if (value && value <= 0) {
            return Promise.reject(t('common.schema.priceNumericSell'))
          }

          if (value === 0) {
            return Promise.reject(t('common.schema.priceNumericSell'))
          }
          if (value && !numberPattern.test(value)) {
            return Promise.reject(t('common.schema.mustBeNumericDecimal'))
          }
          return Promise.resolve()
        },
      }),
    ],
    available: [
      {
        required: true,
        message: t('validate.required', {
          0: t('shopSettings.form.quantity'),
        }),
      },
      () => ({
        validator(rule, value) {
          const numberPattern = /^-?\d+(\.\d+)?$/
          const circulate = shop?.total - shop?.remaining
          if (value && quantity < circulate) {
            return Promise.reject(
              <ValidationMessage
                messages={[
                  t('common.schema.checkQuantity', { 0: circulate }),
                  t('common.schema.checkQuantity1', { 0: circulate }),
                ]}
              />,
            )
          }
          if (value && value <= 0) {
            return Promise.reject(t('common.schema.priceNumericSell'))
          }

          if (value === 0) {
            return Promise.reject(t('common.schema.priceNumericSell'))
          }
          if (value && !numberPattern.test(value)) {
            return Promise.reject(t('common.schema.mustBeNumericDecimal'))
          }
          return Promise.resolve()
        },
      }),
    ],
    initPrice: [
      {
        required: true,
        message: t('validate.required', {
          0: t('shopSettings.form.initPrice'),
        }),
      },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (!value) {
              resolve()
            } else if (value <= 0) {
              reject(t('common.schema.priceNumericSell'))
            } else if (isNaN(value) || !Number.isInteger(parseFloat(value))) {
              reject(t('validate.validIntPrice'))
            } else {
              resolve()
            }
          })
        },
      },
    ],
    maxPrice: [
      {
        required: true,
        message: t('validate.required', {
          0: t('shopSettings.form.maxPrice'),
        }),
      },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (!value) {
              resolve()
            } else if (value <= 0) {
              reject(t('common.schema.priceNumericSell'))
            } else if (isNaN(value) || !Number.isInteger(parseFloat(value))) {
              reject(t('validate.validIntPrice'))
            } else if (maxPrice <= initPriceItem) {
              reject(t('common.schema.maxpriceNumericSell'))
            } else {
              resolve()
            }
          })
        },
      },
    ],
    limit: [
      {
        required: true,
        message: t('validate.required', {
          0: t('shopSettings.form.limit'),
        }),
      },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (!value) {
              resolve()
            } else if (value <= 0) {
              reject(t('common.schema.limitNumericSell'))
            } else if (isNaN(value) || !Number.isInteger(parseFloat(value))) {
              reject(t('validate.validIntPrice'))
            } else {
              resolve()
            }
          })
        },
      },
    ],
  }

  const handleSubmit = (values) => {
    // eslint-disable-next-line
    const formData = {
      category: getTypeSale(shop?.category),
      id:
        shop?.category === 'Training Item'
          ? shop?.id
          : shop?.category === 'BIT'
          ? 0
          : undefined,
      initPrice: String(values?.price),
      // otherPrice: values?.otherPrice ? String(values?.otherPrice) : undefined,

      otherPrice: shop?.category === 'BIT' ? '10000' : undefined,
      limit: values?.limit ? Number(values?.limit) : undefined,
      totalLimit: values?.available ? Number(values?.available) : undefined,
    }

    actions?.updatePriceShop(formData, onSuccess)
  }

  const onSuccess = () => {
    notification.success({
      message: t('common.message.updateSuccessfully'),
      duration: NOTIFICATION_DURATION,
    })
    onClose()
    setTimeout(() => {
      refreshData()
    }, 2000)
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }
  // useEffect(() => {
  //   if (shop?.category === 'Training Item') {
  //     form.resetFields(['otherPrice'])
  //   }
  // }, [initPriceItem])

  useEffect(() => {
    if (shop) {
      const formDetailData = {
        price: shop.price,
        limit: shop.total,
        initPrice: shop.initPrice,
        // available: shop.total,
        // otherPrice: Number(10000),
        available: shop?.total,
      }
      setInitPrice(shop.price)
      setMaxPrice(shop.maxPrice)
      setPrice(shop.price)
      setQuantity(shop.total)
      form.setFieldsValue(formDetailData)
    }
  }, [shop, isShowModal])
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }

  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
    background: 'transparent',
    border: 'none',
  }

  return (
    <Modal
      title={t('common.btn.edit')}
      onCancel={handleClose}
      open={isShowModal}
      okText={t('common.btn.edit')}
      width={572}
      centered
      destroyOnClose
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        {shop?.category === 'General Box' ||
        shop?.category === 'Genesis Box' ? (
          <div className="flex flex-wrap justify-center items-center gap-3 mb-6">
            {filteredListShops.map((item, index) => (
              <div key={index} className="flex items-center justify-center">
                <div className="card__monster w-[165px] h-[165px]">
                  <div className="relative flex items-center justify-center h-full">
                    <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
                      <div
                        className="h-full flex flex-col p-[10px] text-[18px]"
                        style={{
                          background:
                            'linear-gradient(180deg, #0D1426 0%, #375877 100%)',
                        }}
                      >
                        <div className="flex flex-col">
                          <div className="title__shop_card text-[14px] font__Rexliafree">
                            {item?.category}
                          </div>
                          <div className="text-[12px] font__Rexliafree">
                            {item?.name}
                          </div>
                        </div>

                        <div className="h-full flex items-center justify-center">
                          {item?.category === 'Genesis Box' && (
                            <img
                              alt=""
                              className="card__monster_img w-[100px] h-[100px] object-contain ease-in-out duration-300"
                              src={
                                item?.img?.length < 5
                                  ? `${process.env.PUBLIC_URL}/imgs/shops/genesis/${item?.img}.png`
                                  : item?.img
                              }
                            />
                          )}
                          {item?.category === 'General Box' && (
                            <img
                              alt=""
                              className="card__monster_img w-[100px] h-[100px] object-contain ease-in-out duration-300"
                              src={
                                item?.img?.length < 5
                                  ? `${process.env.PUBLIC_URL}/imgs/shops/general/${item?.img}.png`
                                  : item?.img
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full flex items-center justify-center">
            <div className="card__monster w-[250px] h-[250px]">
              <div className="relative flex items-center justify-center h-full">
                <div className="relative flex flex-col w-[88%] h-[95%] justify-between">
                  <div
                    className="h-full flex flex-col p-[10px] text-[18px]"
                    style={{
                      background:
                        'linear-gradient(180deg, #0D1426 0%, #375877 100%)',
                    }}
                  >
                    <div className="flex flex-col">
                      <div className="title__shop_card text-[14px] font__Rexliafree">
                        {shop?.category}
                      </div>
                      <div className="text-[12px] font__Rexliafree">
                        {shop?.name === 'Farm' ? '' : shop?.name}
                      </div>
                    </div>

                    <div className="h-full flex items-center justify-center">
                      <img
                        alt=""
                        className="card__monster_img w-[160px] h-[160px] object-contain ease-in-out duration-300"
                        src={
                          shop?.img?.length < 5
                            ? `${process.env.PUBLIC_URL}/imgs/shops/${shop?.img}.png`
                            : shop?.img
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {shop?.category === 'Training Item' && (
          <>
            <Form.Item
              label={t('shopSettings.form.initPrice')}
              name="price"
              rules={rules.initPrice}
              labelAlign="left"
            >
              <Input
                maxLength={10}
                onChange={(e) => {
                  setInitPrice(Number(e.target.value))
                  form.resetFields(['otherPrice'])
                }}
                prefix={<ICON_BIT />}
                placeholder={t('shopSettings.form.initPrice')}
              />
            </Form.Item>

            {/* <Form.Item
              label={t('shopSettings.form.maxPrice')}
              name="otherPrice"
              rules={rules.maxPrice}
              labelAlign="left"
            >
              <Input
                onChange={(e) => setMaxPrice(Number(e.target.value))}
                prefix={<ICON_BIT />}
                placeholder={t('shopSettings.form.maxPrice')}
              />
            </Form.Item> */}

            <Form.Item
              label={t('shopSettings.form.limit')}
              name="limit"
              rules={rules.limit}
              labelAlign="left"
            >
              <Input
                maxLength={10}
                placeholder={t('shopSettings.form.limit')}
                suffix={
                  <div className="flex text-[#000] items-center">
                    <div>{t('shopSettings.form.items')}</div>
                  </div>
                }
              />
            </Form.Item>
          </>
        )}
        {shop?.category === 'BIT' && (
          <>
            <Form.Item
              label={t('shopSettings.form.price')}
              name="price"
              rules={rules.price}
              labelAlign="left"
            >
              <Input
                onChange={handleChange}
                maxLength={10}
                prefix={<ICON_DOLLAR />}
                placeholder={t('shopSettings.form.price')}
                suffix={
                  <div className="flex text-[#000] items-center">
                    <div>~ {formatNumber(convertPrice(price, tema))}</div>
                    <img
                      className="w-[16px] ml-[5px]"
                      src={`${process.env.PUBLIC_URL}/imgs/coins/oas.png`}
                      alt=""
                    />
                  </div>
                }
              />
            </Form.Item>

            <Form.Item
              label={t('shopSettings.form.bitAmount')}
              name="otherPrice"
              labelAlign="left"
            >
              <Input
                readOnly
                maxLength={10}
                prefix={<ICON_BIT />}
                placeholder={t('shopSettings.form.bitAmount')}
                defaultValue={'10,000'}
              />
            </Form.Item>
          </>
        )}
        {shop?.category !== 'BIT' && shop?.category !== 'Training Item' && (
          <>
            <Form.Item
              label={
                shop?.category === 'Farm'
                  ? t('shopSettings.form.editFarmPrice')
                  : t('shopSettings.form.editPrice')
              }
              name="price"
              rules={rules.price}
              labelAlign="left"
            >
              <Input
                onChange={handleChange}
                maxLength={10}
                prefix={<ICON_DOLLAR />}
                placeholder={t('shopSettings.form.price')}
                suffix={
                  <div className="flex text-[#000] items-center">
                    <div>~ {formatNumber(convertPrice(price, tema))}</div>
                    <img
                      className="w-[16px] ml-[5px]"
                      src={`${process.env.PUBLIC_URL}/imgs/coins/oas.png`}
                      alt=""
                    />
                  </div>
                }
              />
            </Form.Item>
            {shop?.category === 'Farm' && (
              <Form.Item
                label={t('shopSettings.form.quantity')}
                name="available"
                rules={rules.available}
                labelAlign="left"
              >
                <Input
                  type="number"
                  onChange={handleChangeQuantity}
                  maxLength={10}
                  value={quantity}
                  onInput={handleInput}
                  placeholder={t('shopSettings.form.quantity')}
                  onKeyPress={(event) => {
                    const invalidChars = [
                      '.',
                      '-',
                      ',',
                      'e',
                      'E',
                      '+',
                      '*',
                      '&',
                      '^',
                      '%',
                      '$',
                      '#',
                      '@',
                      '!',
                      '(',
                      ')',
                      '_',
                      '=',
                      '<',
                      '>',
                      '/',
                      '\\',
                      '|',
                      '?',
                      '`',
                      '~',
                    ]
                    if (invalidChars.includes(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </Form.Item>
            )}
          </>
        )}

        <div key="buttons" className="flex justify-center">
          <Button key="cancel" onClick={handleClose} style={cancelButtonStyle}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            htmlType="submit"
            className="border-hidden"
            key="ok"
            type="primary"
            style={okButtonStyle}
            loading={isLoading}
          >
            {t('common.btn.save')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

ShopForm.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default ShopForm
