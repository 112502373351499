import React, { useEffect } from 'react'
import { Button, Col, Form, Row, Select } from 'antd'
import lang from '~/assets/data/language.data.json'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { CaretDownOutlined, CheckOutlined } from '@ant-design/icons'
import { useProfile } from '~/views/app/redux/hooks/useProfile'

export default function ChangeLanguage(props) {
  const { t, userInfo } = props
  const [form] = Form.useForm()
  const {
    data: { locale },
    actions: { onLocaleChange, actionTheme },
  } = useTheme()
  const {
    actions,
    // data: { userInfo },
  } = useProfile()

  const handleSubmit = (values) => {
    onLocaleChange(values?.lang)
    const formData = {
      id: userInfo?._id,
      params: {
        user_name: userInfo?.user_name,
        lang: values?.lang,
      },
    }

    localStorage.setItem('selectedLanguage', values?.lang)

    actions.updateName(formData, (res) => {
      actionTheme.updateInfoUser(res)
    })
  }

  useEffect(() => {
    const storedLang = localStorage.getItem('selectedLanguage')

    if (storedLang) {
      onLocaleChange(storedLang)
      form.setFieldsValue({ lang: storedLang })
    } else if (userInfo?.lang) {
      onLocaleChange(userInfo?.lang)
      form.setFieldsValue({ lang: userInfo?.lang })
    }
  }, [userInfo])

  return (
    <Form
      id="ChangeLanguage"
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      initialValues={{ lang: locale }}
      translate="yes"
      autoComplete="off"
      size="middle"
      validateTrigger={['onBlur', 'onChange']}
      requiredMark={false}
    >
      <Row className="mb-3 md:mb-0" gutter={10}>
        <Col xl={16} md={16} sm={24} xs={24}>
          <Form.Item
            label={<span className="font__M__plus font-bold">Language</span>}
            name="lang"
            labelAlign="left"
          >
            <Select
              className="h-[44px] font-bold "
              placeholder={t(
                'profile.accountSetting.personalInformation.email',
              )}
              suffixIcon={<CaretDownOutlined />}
              options={lang.map((item) => ({
                label: (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <img
                        alt=""
                        className="w-[24px] h-[24px] mr-2"
                        src={`${process.env.PUBLIC_URL}/imgs/flags/24x24/${item?.icon}.png`}
                      />
                      <span>{item?.langName}</span>
                    </div>

                    {locale === item.langId ? (
                      <CheckOutlined className="text-[#52c41b]" />
                    ) : null}
                  </div>
                ),
                value: item?.langId,
              }))}
            />
          </Form.Item>
        </Col>
        <Col
          xl={8}
          md={8}
          sm={24}
          xs={24}
          className="flex items-center justify-center w-full"
          style={{ minWidth: '100px' }}
        >
          <Button
            htmlType="submit"
            className="bg-[#1890FF] w-full h-[42px]"
            type="primary"
          >
            <span className="ml-[-6px]">{t('common.btn.change')}</span>
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
