export const GET_HISTORY_TRADE_START = 'GET_HISTORY_TRADE_START'
export const GET_HISTORY_TRADE_SUCCESS = 'GET_HISTORY_TRADE_SUCCESS'
export const GET_HISTORY_TRADE_FAILED = 'GET_HISTORY_TRADE_FAILED'

//-------------------------History trade------------------------//
/**
 * [1]
 * get get history trade start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getHistoryTrade(payload, onSuccess, onError) {
  return {
    type: GET_HISTORY_TRADE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get get history trade success action
 * @param {*} payload
 * @returns {object}
 */
export function getHistoryTradeSuccess(payload) {
  return {
    type: GET_HISTORY_TRADE_SUCCESS,
    payload: payload,
  }
}

/**
 * get get history trade failed action
 * @returns {object}
 */
export function getHistoryTradeFailed() {
  return {
    type: GET_HISTORY_TRADE_FAILED,
  }
}

export default {
  getHistoryTrade,
  getHistoryTradeSuccess,
  getHistoryTradeFailed,
}
