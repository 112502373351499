import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  updateEmailSuccess,
  updateEmailFailed,
  UPDATE_EMAIL_START,
} from '../../actions/profile'

/**
 * update email api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateEmailApi = (props) => {
  var uri = `/admin/auth/verify-code`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdateEmail(action) {
  try {
    const response = yield call(updateEmailApi, action?.payload)
      yield put(updateEmailSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
  } catch (error) {
    yield put(updateEmailFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch update email
 */
export default function* watchUpdateEmail() {
  yield takeLatest(UPDATE_EMAIL_START, doUpdateEmail)
}
