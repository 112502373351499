import React, { useMemo, useEffect } from 'react'
import { Table, Pagination } from 'antd'
import PropTypes from 'prop-types'

function Datatable(props) {
  const {
    showPagination,
    columns,
    onRow,
    dataSource,
    rowSelection,
    metadata,
    loading = false,
    scroll,
    columnAlign,
    breadcrumb,
    rowClassName
  } = props

  const tableColumns = useMemo(() => {
    let tColumns = columns
    if (columnAlign) {
      tColumns = tColumns.map((item) => ({ ...item, align: columnAlign }))
    }
    return tColumns
  }, [columnAlign, columns])

  useEffect(() => {
    if (dataSource?.length === 0 && metadata.page > 1) {
      metadata.setPage(metadata.page - 1)
    }
  }, [dataSource])
  const onChangePageSize = (current, size) => {
    metadata?.setPerPage(size)
  }
  return (
    <>
      <section className="">
        <div>
          {breadcrumb && <>{breadcrumb}</>}
          <Table
          className="scrollbar_container"
            onRow={onRow}
            rowSelection={rowSelection}
            columns={tableColumns}
            dataSource={dataSource}
            pagination={false}
            scroll={scroll}
            loading={loading}
            rowClassName={rowClassName ?? 'default-row'}
          />
        </div>
        {showPagination && (
          <div className="pagination__common scrollbar_container">
            <Pagination
              className="mt-5"
              size="small"
              showTitle
              locale={{ jump_to: 'Go to', page: '' }}
              showSizeChanger
              onShowSizeChange={onChangePageSize}
              defaultCurrent={1}
              current={metadata.page}
              total={metadata.total}
              onChange={metadata.setPage}
              showQuickJumper
              defaultPageSize={metadata.perPage}
              pageSize={metadata.perPage}
              hideOnSinglePage={false}
            />
          </div>
        )}
      </section>
    </>
  )
}

Datatable.propTypes = {
  showPagination: PropTypes.bool,
  onRow: PropTypes.func,
  rowSelection: PropTypes.shape({
    selectedRowKeys: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  dataSource: PropTypes.array.isRequired,
  metadata: PropTypes.shape({
    total: PropTypes.number,
    page: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    perPage: PropTypes.number.isRequired,
    setPerPage: PropTypes.func.isRequired,
    keyword: PropTypes.string,
    setKeyword: PropTypes.func,
  }).isRequired,
  loading: PropTypes.bool,
  scroll: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    scrollToFirstRowOnChange: PropTypes.bool,
  }),
  hideSearch: PropTypes.bool,
  columnAlign: PropTypes.oneOf(['center', 'left', 'right']),
  formFilter: PropTypes.any,
  breadcrumb: PropTypes.element,
}

export default Datatable
