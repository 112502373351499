import { addKeyToList } from '~/helpers/transform-data'
import {
  GET_LIST_GIFTS_START,
  GET_LIST_GIFTS_SUCCESS,
  GET_LIST_GIFTS_FAILED,
  SEND_GIFT_START,
  SEND_GIFT_SUCCESS,
  SEND_GIFT_FAILED,
} from '../actions/gift'

const initialState = {
  isLoading: false,
  listGifts: [],
  total: 0
}

/**
 * shop reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function giftManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_GIFTS_START:
    case SEND_GIFT_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LIST_GIFTS_SUCCESS:
      return {
        ...state,
        listGifts: addKeyToList(
          action.payload?.data,
          action.payload?.page,
          action.payload?.pageSize,
        ),
        total: action?.payload?.total,
        isLoading: false,
      }

    case SEND_GIFT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case GET_LIST_GIFTS_FAILED:
    case SEND_GIFT_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
