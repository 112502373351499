import React from 'react';

function CardLogin(props) {
    const { title, type, handleLogin } = props
    return (
        <div onClick={() => handleLogin()} className='cursor-pointer flex gap-3 items-center h-[108px]  border-2 border-sky-500' style={{ padding: 16, gap: 20, borderRadius: 16 }}>
            <div className=''>
                <img src={`${process.env.PUBLIC_URL}/imgs/Login/${type}.png`} alt='' />
            </div>

            <div className='flex flex-col' >
                <div className='text-[20px] font-bold'>{title}</div>
                {type === "OAS" &&
                    <div>
                        <img alt='' src={`${process.env.PUBLIC_URL}/imgs/Login/OASdes.png`} />
                    </div>
                }
            </div>
        </div>
    );
}

export default CardLogin;