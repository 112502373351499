export const filterOption = (input, option) => {
  const key = (option?.key).toLowerCase()
  const inputChars = Array.from(input.toLowerCase())
  const mergeInput = inputChars?.join('')
  const keyHasAllChars = [mergeInput].reduce((prevResult, char) => {
    if (prevResult === undefined) {
      return true
    }
    return prevResult && key.includes(Number(char))
  }, true)
  return keyHasAllChars
}
