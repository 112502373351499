import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  createAdminSuccess,
  createAdminFailed,
  CREATE_ADMIN_START,
} from '~/views/app/redux/actions/admin'

/**
 * create user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createAdminApi = (params) => {
  const uri = `/admin/manager-user`
  return api.post(uri, params)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doCreateAdmin(action) {
  try {
    const response = yield call(createAdminApi, action?.payload)
    if (response?.statusCode !== 400) {
      yield put(createAdminSuccess(response?.data))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response?.data)
      }
    } else {
      throw response?.message
    }
  } catch (error) {
    yield put(createAdminFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch create user
 */
export default function* watchCreateAdmin() {
  yield takeLatest(CREATE_ADMIN_START, doCreateAdmin)
}
