/**
 * Environment
 */
const getEnv = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'development':
      return 'development';
    case 'production':
      return 'production';
    default:
      return 'development';
  }
};
const env = getEnv();

export default env;