import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  updatePriceShopSuccess,
  updatePriceShopFailed,
  UPDATE_PRICE_SHOP_START,
} from '../../actions/shop'

/**
 * update price shop api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updatePriceShopApi = (props) => {
  var uri = `/admin/shops/set-price`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdatePriceShop(action) {
  try {
    const response = yield call(updatePriceShopApi, action?.payload)
      yield put(updatePriceShopSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
  } catch (error) {
    yield put(updatePriceShopFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch update price shop
 */
export default function* watchUpdatePriceShop() {
  yield takeLatest(UPDATE_PRICE_SHOP_START, doUpdatePriceShop)
}
