import React from 'react'
import { Button, Modal, notification } from 'antd'
import { useAdmin } from '~/views/app/redux/hooks/useAdmin'
import { NOTIFICATION_DURATION, ROWS_PER_PAGE_OPTIONS } from '../constants'
export default function DeleteCommon(props) {
  const {
    t = {},
    title = null,
    action = {},
    user,
    idArray,
    setIdSelected,
    setSelectedRowKeys,
    refreshData,
    setPage,
    setPageSize,
    setIsDeleting,
  } = props

  const {
    actions,
    data: { isLoading },
  } = useAdmin()

  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
    backgroundColor: 'transparent',
    border: 'none',
  }

  const handleSubmit = () => {
    actions.deleteAdmin(
      { ids: idArray, currentId: user?.address_wallet },
      () => {
        notification.success({
          message: t('common.message.deleteSuccessfully'),
          placement: 'topRight',
          duration: NOTIFICATION_DURATION,
        })

        action?.handleCancel()
        setIdSelected([])
        setSelectedRowKeys([])
        refreshData()
        setPage(1)
        setPageSize(ROWS_PER_PAGE_OPTIONS[0])
        setIsDeleting(false)
      },
      () => {
        setIsDeleting(false)
      },
    )
  }
  return (
    <>
      <Modal
        centered
        title={
          <h3 className="flex justify-center text-xl font-normal text-[#fff]">
            {title ?? t('common.titleConfirmDeleteModal')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          <div key="buttons" className="flex justify-center">
            <Button
              key="cancel"
              onClick={action?.handleCancel}
              style={cancelButtonStyle}
            >
              {t('cancel_sell.cancel')}
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={() => handleSubmit()}
              style={okButtonStyle}
              loading={isLoading}
            >
              {t('cancel_sell.ok')}
            </Button>
          </div>,
        ]}
      >
        <div className="text-center text-[16px]">
          {t('common.messConfirmDeleteModal')}
        </div>
      </Modal>
    </>
  )
}
