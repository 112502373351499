import { Col, Grid, Row } from 'antd'
import React from 'react'
import Utils from '~/utils'
import EffectShowLeftToRight from '~/components/shared-components/EffectShowLeftToRight'
const { useBreakpoint } = Grid

export const AuthLayout = ({ children }) => {
  const screens = Utils.getBreakPoint(useBreakpoint())
  const isMobile = !screens.includes('lg')
  return (
    <div className="auth-container">
      <Row className="h-full">
        {!isMobile && (
          <Col
            className="flex items-center justify-center"
            xl={12}
            md={24}
            sm={24}
            xs={24}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/Login/Background.png)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <img src={`${process.env.PUBLIC_URL}/imgs/Login/logo.png`} alt="" />
          </Col>
        )}

        <Col
          xl={12}
          md={24}
          sm={24}
          xs={24}
          style={{ background: 'rgba(0, 9, 38, 1)' }}
        >
          <div className="h-full w-100 flex items-center justify-center">
            <div className="w-[90%] sm:w-[65%]">
              <EffectShowLeftToRight>{children}</EffectShowLeftToRight>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AuthLayout
