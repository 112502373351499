import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getDepositPoolsSuccess,
  getDepositPoolsFailed,
  GET_DEPOSIT_POOLS_START,
} from '../../actions/pools'

/**
 * get deposit pools api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getDepositPoolsApi = (props) => {
  var uri = `/admin/treasury/pools`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetDepositPools(action) {
  try {
    const response = yield call(getDepositPoolsApi, action?.payload)
      yield put(getDepositPoolsSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
  } catch (error) {
    yield put(getDepositPoolsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get deposit pools
 */
export default function* watchGetDepositPools() {
  yield takeLatest(GET_DEPOSIT_POOLS_START, doGetDepositPools)
}
