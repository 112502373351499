import { Card, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { useTranslation } from '~/common/hooks/useTranslation'
import moment from 'moment'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const { RangePicker } = DatePicker

const TradeChart = (props) => {
  const { dashboardMkpDatas, setDate } = props
  // eslint-disable-next-line
  const labels = dashboardMkpDatas?.Farm?.map((item) => {
    const date = new Date((item?.timestamp * 1000) / 1000)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    const formattedDate = `${year}/${month}/${day}`
    return formattedDate
  })

  const [monster, setMonster] = useState([])
  const [farm, setFarm] = useState([])
  const [item, setItem] = useState([])
  const [hash, setHash] = useState([])

  const { t } = useTranslation()
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true,
        labels: {
          color: '#fff',
          usePointStyle: true,
        },
      },
      borderDash: {
        xAxis: [0, 888],
      },
    },

    scales: {
      y: {
        min: 0,
        border: {
          color: '#fff',
        }, // for the grid lines
        grid: {
          display: true,
          color: 'rgba(255, 255, 255, 0.1)',
          tickBorderDash: [1, 10],
          tickColor: 'transparent',
          tickWidth: 2,
          offset: true,
          drawTicks: true,
          drawOnChartArea: true,
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 200,
          beginAtZero: true,
        },
      },
      x: {
        border: {
          color: '#fff',
        },
        grid: {
          display: true,
          borderDash: [5, 5],
          color: 'rgba(255, 255, 255, 0)',
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  useEffect(() => {
    setMonster(dashboardMkpDatas?.Monster?.map((item) => item?.trading_volume))
    setFarm(dashboardMkpDatas?.Farm?.map((item) => item?.trading_volume))
    setItem(dashboardMkpDatas?.Item?.map((item) => item?.trading_volume))
    setHash(dashboardMkpDatas?.Hash?.map((item) => item?.trading_volume))
  }, [dashboardMkpDatas])
  const data = {
    labels,
    datasets: [
      {
        label: 'Monster',
        data: monster,
        borderColor: 'rgba(92, 89, 232, 1)',
        backgroundColor: 'rgba(92, 89, 232, 1)',
        tension: 0.4,
      },
      {
        label: 'Farm',
        data: farm,
        borderColor: 'rgba(228, 106, 17, 1)',
        backgroundColor: 'rgba(228, 106, 17, 1)',
        tension: 0.4,
      },
      {
        label: 'Item',
        data: item,
        borderColor: 'rgba(19, 178, 228, 1)',
        backgroundColor: 'rgba(19, 178, 228, 1)',
        tension: 0.4,
      },
      {
        label: 'Hash NFT',
        data: hash,
        borderColor: 'rgba(255, 236, 61, 1)',
        backgroundColor: 'rgba(255, 236, 61, 1)',
        tension: 0.4,
      },
    ],
  }
  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }
  const onChangeDate = (values) => {
    setDate(values)
  }
  return (
    <Card
      className=""
      title="Trading volume"
      extra={
        <div className="flex gap-4 items-center">
          <RangePicker
            className="h-full max-w-[256px]"
            // onChange={(e) => setDate(e)}
            onChange={(values, dateString) => onChangeDate(dateString)}
            disabledDate={disabledDate}
          />
        </div>
      }
    >
      <div>
        <div className="text-[18px] font-medium">
          {monster
            ?.concat(farm, item, hash)
            ?.reduce(function (accumulator, currentValue) {
              return Number(accumulator) + Number(currentValue)
            }, 0)}{' '}
          OAS
        </div>
        <div className="text-[14px] text-[#BFBFBF]">
          {t('common.totalVolume')}
        </div>
      </div>

      <Line
        height={111}
        options={options}
        data={data}
        style={{ marginBottom: '-13px' }}
      />
    </Card>
  )
}

export default TradeChart
