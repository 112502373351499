export const GET_LIST_SHOP_START = 'GET_LIST_SHOP_START'
export const GET_LIST_SHOP_SUCCESS = 'GET_LIST_SHOP_SUCCESS'
export const GET_LIST_SHOP_FAILED = 'GET_LIST_SHOP_FAILED'

export const UPDATE_STATUS_SHOP_START = 'UPDATE_STATUS_SHOP_START'
export const UPDATE_STATUS_SHOP_SUCCESS = 'UPDATE_STATUS_SHOP_SUCCESS'
export const UPDATE_STATUS_SHOP_FAILED = 'UPDATE_STATUS_SHOP_FAILED'

export const UPDATE_PRICE_SHOP_START = 'UPDATE_PRICE_SHOP_START'
export const UPDATE_PRICE_SHOP_SUCCESS = 'UPDATE_PRICE_SHOP_SUCCESS'
export const UPDATE_PRICE_SHOP_FAILED = 'UPDATE_PRICE_SHOP_FAILED'

//------------------------- Shop ------------------------//
/**
 * [1]
 * get list shop start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListShop(payload, onSuccess, onError) {
  return {
    type: GET_LIST_SHOP_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list shop success action
 * @param {*} payload
 * @returns {object}
 */
export function getListShopSuccess(payload) {
  return {
    type: GET_LIST_SHOP_SUCCESS,
    payload: payload,
  }
}

/**
 * get list shop failed action
 * @returns {object}
 */
export function getListShopFailed() {
  return {
    type: GET_LIST_SHOP_FAILED,
  }
}

/**
 * [2]
 * update status shop start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateStatusShop(payload, onSuccess, onError) {
  return {
    type: UPDATE_STATUS_SHOP_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update status shop success action
 * @param {*} payload
 * @returns {object}
 */
export function updateStatusShopSuccess(payload) {
  return {
    type: UPDATE_STATUS_SHOP_SUCCESS,
    payload: payload,
  }
}

/**
 * update status shop failed action
 * @returns {object}
 */
export function updateStatusShopFailed() {
  return {
    type: UPDATE_STATUS_SHOP_FAILED,
  }
}

/**
 * [3]
 * update price shop start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updatePriceShop(payload, onSuccess, onError) {
  return {
    type: UPDATE_PRICE_SHOP_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update price shop success action
 * @param {*} payload
 * @returns {object}
 */
export function updatePriceShopSuccess(payload) {
  return {
    type: UPDATE_PRICE_SHOP_SUCCESS,
    payload: payload,
  }
}

/**
 * update price shop failed action
 * @returns {object}
 */
export function updatePriceShopFailed() {
  return {
    type: UPDATE_PRICE_SHOP_FAILED,
  }
}


export default {
  getListShop,
  getListShopSuccess,
  getListShopFailed,

  updateStatusShop,
  updateStatusShopSuccess,
  updateStatusShopFailed,

  updatePriceShop,
  updatePriceShopSuccess,
  updatePriceShopFailed,
}
