import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
    getNonceSuccess,
    getNonceFailed,
    GET_NONCE_START,
} from '../../actions/auth'

/**
 * get nonce api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getNonceApi = (props) => {
    var uri = `/admin/auth/nonce`
    return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetNonce(action) {
    try {
        const response = yield call(getNonceApi, action?.payload)
        yield put(getNonceSuccess(response?.data))

        // Call callback action if provided
        if (action.onSuccess) {
            yield action.onSuccess(response?.data)
        }
    } catch (error) {
        yield put(getNonceFailed())
        // Call callback action if provided

        if (action.onError) {
            yield action.onError(error)
        }
    }
}

/**
 * [Watcher]
 * Watch get nonce
 */
export default function* watchGetNonce() {
    yield takeLatest(GET_NONCE_START, doGetNonce)
}
