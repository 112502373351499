import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getListGiftsSuccess,
  getListGiftsFailed,
  GET_LIST_GIFTS_START,
} from '../../actions/gift'

/**
 * get list gift pools api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListGiftsApi = (props) => {
  var uri = `/admin/send-gift`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListGifts(action) {
  try {
    const response = yield call(getListGiftsApi, action?.payload)
      yield put(getListGiftsSuccess(response?.histories))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
  } catch (error) {
    yield put(getListGiftsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list gift pools
 */
export default function* watchGetListGifts() {
  yield takeLatest(GET_LIST_GIFTS_START, doGetListGifts)
}
