import { all } from 'redux-saga/effects'
//Auth
import watchLogin from './auth/login'
import watchLogout from './auth/logout'
import watchGetNonce from './auth/get-nonce'

/**
 * Root saga
 */

export default function* sagas() {
  yield all([
    //Auth
    watchLogin(),
    watchLogout(),
    watchGetNonce()
  ])
}
