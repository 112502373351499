import {
  GET_LIST_USER_START,
  GET_LIST_USER_SUCCESS,
  GET_LIST_USER_FAILED,
  GET_DETAIL_USER_START,
  GET_DETAIL_USER_SUCCESS,
  GET_DETAIL_USER_FAILED,
} from '../actions/user'
import { addKeyToList } from '~/helpers/transform-data'

const initialState = {
  isLoading: false,
  listUser: [],
  userDetail: {},
  total: null,
}
/**
 * user reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function userManagement(state = initialState, action) {
  switch (action.type) {
    // START
    case GET_LIST_USER_START:
    case GET_DETAIL_USER_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LIST_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listUser: addKeyToList(
          action.payload?.data,
          action.payload?.currentPage,
          action.payload?.pageSize,
        ),
        total: action.payload.total,
      }

    case GET_DETAIL_USER_SUCCESS:
      return {
        ...state,
        userDetail: action?.payload,
        isLoading: false,
      }

    // FAILED
    case GET_LIST_USER_FAILED:
    case GET_DETAIL_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}
