import { ROUTE } from "./config"

import Login from "~/views/auth/features/login"
import ForgotPassword from "~/views/auth/features/ForgotPassword"
import Resetpassword from "~/views/auth/features/Resetpassword"

const routes = [
  {
    name: ROUTE.LOGIN.TITLE,
    path: ROUTE.LOGIN.PATH,
    component: Login,
  },
  {
    name: ROUTE.FORGOT_PASSWORD.TITLE,
    path: ROUTE.FORGOT_PASSWORD.PATH,
    component: ForgotPassword,
  },
  {
    name: ROUTE.RESET_PASSWORD.TITLE,
    path: ROUTE.RESET_PASSWORD.PATH,
    component: Resetpassword,
  },
]

export default routes
