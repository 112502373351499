import React, { useEffect, useReducer, useState } from 'react'
import { getAreas } from './sources'

const initialState = {
  areas: [],
}

function configReducer(state, action) {
  const { type, payload } = action

  switch (type) {
    case 'SET_AREAS': {
      return {
        ...state,
        areas: payload,
      }
    }
    default:
      return state
  }
}

export const configContext = React.createContext(initialState)
const { Provider } = configContext

const defaultAreaFilter = () => true
const defaultAreaMapper = (area) => area
const defaultAreaSorter = (a, b) => {
  if (a.name && b.name) {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }
  return 0
}

export const ConfigProviderPhone = ({
  children,
  locale = {},
  areaFilter = defaultAreaFilter,
  areaMapper = defaultAreaMapper,
  areaSorter = defaultAreaSorter,
}) => {
  const [state, dispatch] = useReducer(configReducer, initialState)
  const [areas, setAreas] = useState([])

  useEffect(() => {
    setAreas(getAreas(locale))
  }, [locale])

  useEffect(() => {
    let payload = areas
      .filter((area, index, array) => areaFilter(area, index, array))
      .map((area, index, array) => areaMapper(area, index, array))
    if (areaSorter) {
      payload = payload.sort(areaSorter)
    }

    dispatch({ type: 'SET_AREAS', payload })
  }, [areas, areaFilter, areaMapper, areaSorter])

  return <Provider value={state}>{children}</Provider>
}
