import { motion } from 'framer-motion'

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 100 : -100,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      XSLTProcessor: direction < 0 ? 100 : -100,
      opacity: 0,
    }
  },
}

export default function EffectShowLeftToRight({ children, className }) {
  return (
    <motion.div
      className={className ?? ''}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      {children}
    </motion.div>
  )
}
