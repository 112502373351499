import { Row } from 'antd'
import React, { useEffect } from 'react'
import styles from './index.module.css'
import { useHistory } from 'react-router-dom'


export const PageNotFound = () => {
  let history = useHistory()
  useEffect(() => {
    setTimeout(() => {
      history.push('/')

    }, 1500)
  }, [])

  return (
    <Row align={'middle'} justify="center">
      <h1 className={styles.title}>Coming Soon!</h1>
    </Row>
  )
}

export default PageNotFound
