import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import { Form, Modal, Input, Button, notification, Select } from 'antd'
import React, { useEffect } from 'react'
import {
  NOTIFICATION_DURATION,
  cancelButtonStyle,
  okButtonStyle,
} from '~/common/constants'
import { useAdmin } from '~/views/app/redux/hooks/useAdmin'
import { REGEX } from '~/helpers/regex'
import { isAddress } from 'ethers/lib/utils'
function UserForm(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, user } = props
  const [form] = Form.useForm()
  const rules = {
    name: [
      () => ({
        validator(rule, value) {
          const regex = new RegExp(REGEX.PROFILE_NAME)
          if (value && value?.charAt(0) === '_') {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          if (value && value?.indexOf('-') !== -1) {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          if (value && !regex.test(value)) {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          return Promise.resolve()
        },
      }),

      {
        required: true,
        message: t('validate.required', {
          0: t('profile.accountSetting.personalInformation.playerName'),
        }),
      },
    ],
    walletAddress: [
      () => ({
        validator(rule, value) {
          if (value && !isAddress(value)) {
            return Promise.reject(t('validate.walletAddress'))
          }

          return Promise.resolve()
        },
      }),
      {
        required: true,
        message: t('validate.walletAddress'),
      },
      {
        pattern: REGEX.ADDRESS,
        message: t('validate.walletAddress'),
      },
      {
        max: 255,
        message: t('validate.max256'),
      },
    ],
    role: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementUsers.form.role'),
        }),
      },
    ],
  }
  const {
    actions,
    data: { isLoading },
  } = useAdmin()

  const handleSubmit = (values) => {
    const formValue = {
      user_id: values?.address_wallet,
      user_name: values?.user_name,
      address_wallet: values?.address_wallet,
      role: values?.role,
    }

    if (id) {
      actions?.updateAdmin({ data: { ...formValue }, id: id }, (res) => {
        if (res?.statusCode !== 400) {
          onSuccess()
          refreshData()
        }
      })
    } else {
      actions?.createAdmin(formValue, (res) => {
        if (res?.statusCode !== 400) {
          onSuccess()
          refreshData()
        }
      })
    }
  }

  const onSuccess = () => {
    notification.success({
      message: id
        ? t('common.message.updateSuccessfully')
        : t('common.message.createSuccessfully'),
      duration: NOTIFICATION_DURATION,
    })
    onClose()
    refreshData()
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (id) {
      const formDetailData = {
        user_id: user?.address_wallet,
        user_name: user?.user_name,
        address_wallet: user?.address_wallet,
        role: user?.role,
      }
      form.setFieldsValue(formDetailData)
    }
  }, [id])
  return (
    <Modal
      title={
        id
          ? t('managementUsers.modal.editTitle')
          : t('managementUsers.modal.addTitle')
      }
      onCancel={handleClose}
      open={isShowModal}
      width={600}
      centered
      destroyOnClose
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        <Form.Item
          label={t('managementUsers.form.walletAddress')}
          name="address_wallet"
          rules={rules.walletAddress}
          labelAlign="left"
        >
          <Input placeholder={t('managementUsers.form.walletAddress')} />
        </Form.Item>

        <Form.Item
          label={t('managementUsers.form.role')}
          name="role"
          rules={rules.role}
          labelAlign="left"
        >
          <Select
            placeholder={t('managementUsers.form.role')}
            options={[
              {
                value: 'admin',
                label: t(`managementUsers.role.admin`),
              },
              {
                value: 'user',
                label: t(`managementUsers.role.user`),
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label={t('managementUsers.form.name')}
          rules={rules.name}
          name="user_name"
          labelAlign="left"
        >
          <Input placeholder={t('managementUsers.form.name')} />
        </Form.Item>

        <div key="buttons" className="flex justify-center">
          <Button key="cancel" onClick={handleClose} style={cancelButtonStyle}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            className="border-hidden"
            key="ok"
            htmlType="submit"
            type="primary"
            style={okButtonStyle}
            disabled={isLoading}
            loading={isLoading}
          >
            {id ? t('common.btn.update') : t('common.btn.add')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

UserForm.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default UserForm
