import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  createCollaborationSuccess,
  createCollaborationFailed,
  CREATE_COLLABORATION_START,
} from '~/views/app/redux/actions/collaboration'

/**
 * create Collaboration api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createCollaborationApi = (params) => {
  const uri = `/admin/collaboration-nft`
  return api.post(uri, params)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doCreateCollaboration(action) {
  try {
    const response = yield call(createCollaborationApi, action?.payload)
    if (response?.statusCode !== 400) {
      yield put(createCollaborationSuccess(response?.data))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response?.data)
      }
    }else{
      throw response?.message
    }
     
  } catch (error) {
    yield put(createCollaborationFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch create Collaboration
 */
export default function* watchCreateCollaboration() {
  yield takeLatest(CREATE_COLLABORATION_START, doCreateCollaboration)
}
