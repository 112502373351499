import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  updatePhoneSuccess,
  updatePhoneFailed,
  UPDATE_PHONE_START,
} from '../../actions/profile'

/**
 * update phone api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updatePhoneApi = (props) => {
  // var uri = `/admin/auth/info`
  var uri = `/admin/profile/${props?.id}/phone`
  return api.put(uri, props?.params)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdatePhone(action) {
  try {
    const response = yield call(updatePhoneApi, action?.payload)
    yield put(updatePhoneSuccess(response?.data))

    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(updatePhoneFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch update phone
 */
export default function* watchUpdatePhone() {
  yield takeLatest(UPDATE_PHONE_START, doUpdatePhone)
}
