import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ||
    'AIzaSyCUYjtUSz2YbcGhNNY968G-pXyZ_X5VaHo',
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
    'remonster-dev.firebaseapp.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'remonster-dev',
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
    'remonster-dev.appspot.com',
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '943482638131',
  appId:
    process.env.REACT_APP_FIREBASE_APP_ID ||
    '1:943482638131:web:45d10237c6ba8cec76b846',
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || 'G-JLWM9K2SST',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export default app
