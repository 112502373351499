import {
  GET_DASHBOARD_MKP_START,
  GET_DASHBOARD_MKP_SUCCESS,
  GET_DASHBOARD_MKP_FAILED,
  GET_DASHBOARD_SHOP_START,
  GET_DASHBOARD_SHOP_SUCCESS,
  GET_DASHBOARD_SHOP_FAILED,
  GET_DASHBOARD_BIT_START,
  GET_DASHBOARD_BIT_SUCCESS,
  GET_DASHBOARD_BIT_FAILED,
  GET_DASHBOARD_TRADE_START,
  GET_DASHBOARD_TRADE_SUCCESS,
  GET_DASHBOARD_TRADE_FAILED,
  GET_DASHBOARD_ITEM_START,
  GET_DASHBOARD_ITEM_SUCCESS,
  GET_DASHBOARD_ITEM_FAILED,
  GET_DASHBOARD_GAME_START,
  GET_DASHBOARD_GAME_SUCCESS,
  GET_DASHBOARD_GAME_FAILED,
} from '../actions/dashboard'

const initialState = {
  isLoading: false,
  dashboardMkpDatas: [],
  settings: [],
}

/**
 * dashboard reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function dashboardManagement(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_MKP_START:
    case GET_DASHBOARD_SHOP_START:
    case GET_DASHBOARD_BIT_START:
    case GET_DASHBOARD_TRADE_START:
    case GET_DASHBOARD_ITEM_START:
    case GET_DASHBOARD_GAME_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DASHBOARD_MKP_SUCCESS:
      return {
        ...state,
        dashboardMkpDatas: action?.payload?.histories,
        isLoading: false,
      }
    case GET_DASHBOARD_SHOP_SUCCESS:
    case GET_DASHBOARD_BIT_SUCCESS:
    case GET_DASHBOARD_TRADE_SUCCESS:
    case GET_DASHBOARD_ITEM_SUCCESS:
    case GET_DASHBOARD_GAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case GET_DASHBOARD_MKP_FAILED:
    case GET_DASHBOARD_SHOP_FAILED:
    case GET_DASHBOARD_BIT_FAILED:
    case GET_DASHBOARD_TRADE_FAILED:
    case GET_DASHBOARD_ITEM_FAILED:
    case GET_DASHBOARD_GAME_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
