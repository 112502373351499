import {
  GET_LIST_ADMIN_START,
  GET_LIST_ADMIN_SUCCESS,
  GET_LIST_ADMIN_FAILED,
  CREATE_ADMIN_START,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILED,
  GET_DETAIL_ADMIN_START,
  GET_DETAIL_ADMIN_SUCCESS,
  GET_DETAIL_ADMIN_FAILED,
  UPDATE_ADMIN_START,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAILED,
  DELETE_ADMIN_START,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILED,
} from '../actions/admin'
import { addKeyToList } from '~/helpers/transform-data'

const initialState = {
  isLoading: false,
  listAdmin: [],
  userAdminDetail: {},
  total: null,
}

/**
 * user reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function adminManagement(state = initialState, action) {
  switch (action.type) {
    // START
    case GET_LIST_ADMIN_START:
    case CREATE_ADMIN_START:
    case GET_DETAIL_ADMIN_START:
    case UPDATE_ADMIN_START:
    case DELETE_ADMIN_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LIST_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listAdmin: addKeyToList(
          action.payload?.data,
          action.payload?.currentPage,
          action.payload?.pageSize,
        ),
        total: action.payload.total,
      }
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case GET_DETAIL_ADMIN_SUCCESS:
      return {
        ...state,
        userAdminDetail: { ...action.payload },
        isLoading: false,
      }
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    // FAILED
    case GET_LIST_ADMIN_FAILED:
    case CREATE_ADMIN_FAILED:
    case GET_DETAIL_ADMIN_FAILED:
    case UPDATE_ADMIN_FAILED:
    case DELETE_ADMIN_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}
