import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getDashBoardMkpSuccess,
  getDashBoardMkpFailed,
  GET_DASHBOARD_MKP_START,
} from '../../actions/dashboard'

/**
 * get dashBoard mkp api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getDashBoardMkpApi = (props) => {
  var uri = `/admin/dashboard/market`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetDashBoardMkp(action) {
  try {
    const response = yield call(getDashBoardMkpApi, action?.payload)
    if (response?.statusCode !== 400) {
      yield put(getDashBoardMkpSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
    } else {
      throw response?.message
    }
  } catch (error) {
    yield put(getDashBoardMkpFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get dashBoard mkp
 */
export default function* watchGetDashBoardMkp() {
  yield takeLatest(GET_DASHBOARD_MKP_START, doGetDashBoardMkp)
}
