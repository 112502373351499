import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  deleteCollaborationSuccess,
  deleteCollaborationFailed,
  DELETE_COLLABORATION_START,
} from '~/views/app/redux/actions/collaboration'

/**
 * delete Collaboration api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteCollaborationApi = (props) => {
  const uri = `/admin/collaboration-nft`
  return api.delete(uri, { ids: props })
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doDeleteCollaboration(action) {
  try {
    const response = yield call(deleteCollaborationApi, action?.payload)
    if (response?.status_code !== 400) {
      yield put(deleteCollaborationSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      throw response?.content?.message
    }
  } catch (error) {
    yield put(deleteCollaborationFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete Collaboration
 */
export default function* watchDeleteCollaboration() {
  yield takeLatest(DELETE_COLLABORATION_START, doDeleteCollaboration)
}
