export const GET_DEPOSIT_POOLS_START = 'GET_DEPOSIT_POOLS_START'
export const GET_DEPOSIT_POOLS_SUCCESS = 'GET_DEPOSIT_POOLS_SUCCESS'
export const GET_DEPOSIT_POOLS_FAILED = 'GET_DEPOSIT_POOLS_FAILED'

export const GET_WITHDRAW_POOLS_START = 'GET_WITHDRAW_POOLS_START'
export const GET_WITHDRAW_POOLS_SUCCESS = 'GET_WITHDRAW_POOLS_SUCCESS'
export const GET_WITHDRAW_POOLS_FAILED = 'GET_WITHDRAW_POOLS_FAILED'

export const UPDATE_DEPOSIT_POOLS_START = 'UPDATE_DEPOSIT_POOLS_START'
export const UPDATE_DEPOSIT_POOLS_SUCCESS = 'UPDATE_DEPOSIT_POOLS_SUCCESS'
export const UPDATE_DEPOSIT_POOLS_FAILED = 'UPDATE_DEPOSIT_POOLS_FAILED'

export const UPDATE_WITHDRAW_POOLS_START = 'UPDATE_WITHDRAW_POOLS_START'
export const UPDATE_WITHDRAW_POOLS_SUCCESS = 'UPDATE_WITHDRAW_POOLS_SUCCESS'
export const UPDATE_WITHDRAW_POOLS_FAILED = 'UPDATE_WITHDRAW_POOLS_FAILED'
//-------------------------Pools------------------------//
/**
 * [1]
 * get deposit pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDepositPools(payload, onSuccess, onError) {
  return {
    type: GET_DEPOSIT_POOLS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get deposit pools success action
 * @param {*} payload
 * @returns {object}
 */
export function getDepositPoolsSuccess(payload) {
  return {
    type: GET_DEPOSIT_POOLS_SUCCESS,
    payload: payload,
  }
}

/**
 * get deposit pools failed action
 * @returns {object}
 */
export function getDepositPoolsFailed() {
  return {
    type: GET_DEPOSIT_POOLS_FAILED,
  }
}

/**
 * [2]
 * get withdraw pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getWithdrawPools(payload, onSuccess, onError) {
  return {
    type: GET_WITHDRAW_POOLS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get withdraw pools success action
 * @param {*} payload
 * @returns {object}
 */
export function getWithdrawPoolsSuccess(payload) {
  return {
    type: GET_WITHDRAW_POOLS_SUCCESS,
    payload: payload,
  }
}

/**
 * get withdraw pools failed action
 * @returns {object}
 */
export function getWithdrawPoolsFailed() {
  return {
    type: GET_WITHDRAW_POOLS_FAILED,
  }
}

/**
 * [3]
 * update deposit pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateDepositPools(payload, onSuccess, onError) {
  return {
    type: UPDATE_DEPOSIT_POOLS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update deposit pools success action
 * @param {*} payload
 * @returns {object}
 */
export function updateDepositPoolsSuccess(payload) {
  return {
    type: UPDATE_DEPOSIT_POOLS_SUCCESS,
    payload: payload,
  }
}

/**
 * update deposit pools failed action
 * @returns {object}
 */
export function updateDepositPoolsFailed() {
  return {
    type: UPDATE_DEPOSIT_POOLS_FAILED,
  }
}

/**
 * [4]
 * update withdraw pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateWithdrawPools(payload, onSuccess, onError) {
  return {
    type: UPDATE_WITHDRAW_POOLS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update withdraw pools success action
 * @param {*} payload
 * @returns {object}
 */
export function updateWithdrawPoolsSuccess(payload) {
  return {
    type: UPDATE_WITHDRAW_POOLS_SUCCESS,
    payload: payload,
  }
}

/**
 * update withdraw pools failed action
 * @returns {object}
 */
export function updateWithdrawPoolsFailed() {
  return {
    type: UPDATE_WITHDRAW_POOLS_FAILED,
  }
}
export default {
  getDepositPools,
  getDepositPoolsSuccess,
  getDepositPoolsFailed,

  getWithdrawPools,
  getWithdrawPoolsSuccess,
  getWithdrawPoolsFailed,

  updateDepositPools,
  updateDepositPoolsSuccess,
  updateDepositPoolsFailed,

  updateWithdrawPools,
  updateWithdrawPoolsSuccess,
  updateWithdrawPoolsFailed,
}
