import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_DARK,
  NAV_TYPE_SIDE,
} from '~/common/constants/ThemeConstant'
import MenuContent from './MenuContent'
import { LogoutOutlined } from '@ant-design/icons';
import { useAuth } from '~/views/auth/redux/hooks/useAuth'
import { useHistory } from 'react-router-dom'
import { useSDK } from '@metamask/sdk-react'

const { Sider } = Layout

export const SideNav = ({
  navCollapsed,
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  localization = true,
}) => {
  const props = {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    navCollapsed
  }
  const { actions } = useAuth()
  const history = useHistory()
  const { sdk } = useSDK()

  return (
    <Sider
      className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''
        }`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
      style={{ top: 120 }}
    >
      <MenuContent type={NAV_TYPE_SIDE} {...props} />
      <div className={`logout absolute bottom-[60px] w-full ${!navCollapsed && 'pl-[24px]'} border-t-2 border-[#243c5a]`}>
        <div className={`flex items-center ${navCollapsed && 'justify-center'} pt-[10px] cursor-pointer`} onClick={() => {
          if (localStorage.getItem('wallet') === 'MW') {
            sdk?.disconnect()
            sdk?.terminate()
          }
          actions.logout({}, () => {
            history.push('/')
          })
        }}>
          <LogoutOutlined style={{ color: "#FF4D4F" }} />
          {!navCollapsed && <div className={`text-[#FF4D4F] ml-[5px]`} >Log out</div>
          }
        </div>
      </div>
    </Sider>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } = theme
  return { navCollapsed, sideNavTheme }
}

export default connect(mapStateToProps)(SideNav)
