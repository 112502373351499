import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  notification,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { HASHCHIP_TYPE_SELECT, NOTIFICATION_DURATION } from '~/common/constants'
import { useQueryState } from '~/common/hooks/useQueryState'
import { useTranslation } from '~/common/hooks/useTranslation'
import Datatable from '~/components/shared-components/Datatable'
import configs from '~/configs'
import { formatAddress, formatTransaction } from '~/helpers/common'
import TimeHelper from '~/helpers/time-helper'
import { usePools } from '~/views/app/redux/hooks/usePools'
const { RangePicker } = DatePicker

const onSuccess = (message) => {
  notification.success({
    message: message,
    duration: NOTIFICATION_DURATION,
  })
}

const RankingRewards = () => {
  const { t } = useTranslation()
  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()
  const [formDeposit] = Form.useForm()
  const [formWithdraw] = Form.useForm()

  const {
    actions,
    data: { isLoadingDeposit, isLoadingWithdraw, deposit, withdraw },
  } = usePools()
  const [defaultTable, setDefaultTable] = useState(HASHCHIP_TYPE_SELECT[0])

  const columns = [
    {
      title: t('managementPools.rankingRewards.form.rewardType'),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementPools.rankingRewards.form.tokenID'),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementPools.rankingRewards.form.time'),
      dataIndex: 'time',
      key: 'time',
      width: 100,
      render: (time) => {
        return TimeHelper(time)
      },
    },
    {
      title: t('managementPools.rankingRewards.form.to'),
      dataIndex: 'to',
      key: 'to',
      width: 100,
      render: (to) => {
        let link = `${configs.NETWORK.scan}address/${to}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={to}
            style={{ color: '#40A9FF' }}
          >
            {formatAddress(to)}
          </a>
        )
      },
    },
    {
      title: t('managementPools.rankingRewards.form.amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
      render: (data) => {
        return data + 'OAS'
      },
    },
    {
      title: t('managementPools.rankingRewards.form.status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementPools.rankingRewards.form.txHash'),
      dataIndex: 'transactionHash',
      key: 'transactionHash',
      width: 100,
      render: (transactionHash) => {
        const link = `${configs.NETWORK.scan}tx/${transactionHash}`
        return (
          <a
            style={{ color: '#40A9FF' }}
            href={link}
            title={transactionHash}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatTransaction(transactionHash)}
          </a>
        )
      },
    },
  ]

  const columns2 = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementPools.hashchipRedeemed.form.claimedCount'),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementPools.hashchipRedeemed.form.claimedOas'),
      dataIndex: 'time',
      key: 'time',
      width: 100,
      render: (time) => {
        return time
      },
    },
  ]

  const rules = {
    deposit: [
      {
        required: true,
        message: t('validate.required', {
          0: t('common.btn.deposit'),
        }),
      },
    ],
    withdraw: [
      {
        required: true,
        message: t('validate.required', {
          0: t('common.btn.withdraw'),
        }),
      },
    ],
  }

  useEffect(() => {
    actions?.getDepositPools()
  }, [])

  const handleSubmitDeposit = (values) => {
    actions.updateDepositPools({ amount: values.deposit }, (res) => {
      onSuccess(res?.message)
    })
  }

  const handleSubmitWithdraw = (values) => {
    actions.updateWithdrawPools({ amount: values.withdraw }, (res) => {
      onSuccess(res?.message)
    })
  }

  const onChangeType = (value) => {
    setDefaultTable(value)
  }
  return (
    <Row gutter={10}>
      <Col span={24} className="h-full">
        <div
          className="relative h-full p-[20px]"
          style={{
            background:
              'linear-gradient(90deg, rgba(24, 144, 255, 0.4) 0%, rgba(24, 144, 255, 0) 100%)',
            borderRadius: 10,
            marginBottom: 10,
          }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-5 h-full min-h-[164px]">
            <div className="h-full flex flex-col justify-center items-center">
              <img
                src={`${process.env.PUBLIC_URL}/imgs/common/gift.png`}
                width={209}
                height={166}
                alt=""
              />
            </div>
            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalPools')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">
                {deposit} OAS
              </div>
            </div>
            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalRequestedAmount')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">
                {withdraw} OAS
              </div>
            </div>
            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalPaidAmount')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">
                500.000 OAS
              </div>
            </div>
            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalAmountNeeded')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">
                1.000.000 OAS
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col span={24} className="h-full box__filter mb-3">
        <div className="flex items-center">
          <div className="mr-2 font__Rexliafree">
            {t('historyTrade.form.assetType')}
          </div>
          <Select
            className="min-w-[120px] h-full "
            placeholder={t('historyTrade.form.assetType')}
            optionFilterProp="children"
            // onChange={onChangeType}
            // options={assetTypeData}
          />
        </div>
      </Col>
      <Col xl={14} md={14} sm={24} xs={24}>
        <Card className="h-[143px]">
          <div className="grid grid-cols-1 sm:grid-cols-4 h-full">
            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalSectorPools')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">
                1.000.000 OAS
              </div>
            </div>

            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalRequestedAmount')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">
                1.000.000 OAS
              </div>
            </div>

            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalPaidAmount')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">
                1.000.000 OAS
              </div>
            </div>

            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalOverdueAmount')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">
                1.000.000 OAS
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col xl={10} md={10} sm={24} xs={24}>
        <Card>
          <Row gutter={10}>
            <Col xl={12} md={12} sm={24} xs={24}>
              <Form
                layout="vertical"
                onFinish={handleSubmitDeposit}
                form={formDeposit}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
              >
                <Row gutter={10}>
                  <Col xl={15} md={15} sm={24} xs={24}>
                    <Form.Item
                      label={t('common.btn.deposit')}
                      name="deposit"
                      rules={rules.deposit}
                      labelAlign="left"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xl={9} md={9} sm={24} xs={24}>
                    <div className="flex flex-col">
                      <div className="h-[30px] hidden sm:block"></div>
                      <Button
                        disabled={isLoadingDeposit}
                        loading={isLoadingDeposit}
                        htmlType="submit"
                        type="primary"
                      >
                        {t('common.btn.deposit')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xl={12} md={12} sm={24} xs={24}>
              <Form
                layout="vertical"
                onFinish={handleSubmitWithdraw}
                form={formWithdraw}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
              >
                <Row gutter={10}>
                  <Col xl={15} md={15} sm={24} xs={24}>
                    <Form.Item
                      label={t('common.btn.withdraw')}
                      name="withdraw"
                      rules={rules.withdraw}
                      labelAlign="left"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xl={9} md={9} sm={24} xs={24}>
                    <div className="flex flex-col">
                      <div className="h-[30px] hidden sm:block"></div>
                      <Button
                        disabled={isLoadingWithdraw}
                        loading={isLoadingWithdraw}
                        htmlType="submit"
                        type="primary"
                      >
                        {t('common.btn.withdraw')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={12} className="h-full box__filter mb-3">
        <div className="flex items-center">
          <div className="mr-2 font__Rexliafree">
            {t('managementPools.hashchipRedeemed.view')}
          </div>
          <Select
            defaultValue={defaultTable}
            className="min-w-[120px] h-full "
            placeholder={t('managementPools.hashchipRedeemed.view')}
            optionFilterProp="children"
            onChange={onChangeType}
            options={HASHCHIP_TYPE_SELECT?.map((data, i) => {
              const typeSelect = {
                key: i?.toString(),
                value: data,
                label: t(`managementPools.hashchipRedeemed.${data}`),
              }
              return typeSelect
            })}
          />
        </div>
      </Col>

      <Col span={12} className="h-full box__filter mb-3">
        <div className="flex items-center justify-end">
          <div className="mr-2 font__Rexliafree">
            {t('historyTrade.form.date')}
          </div>
          <RangePicker
            // onChange={(values, dateString) => onChangeDate(dateString)}
            className="h-full max-w-[256px]"
          />
        </div>
      </Col>

      <Col span={24} className="h-full">
        {defaultTable === HASHCHIP_TYPE_SELECT[0] && (
          <Datatable
            scroll={{ x: 1300 }}
            columns={columns}
            dataSource={[]}
            showPagination
            showHeader
            loading={false}
            metadata={{
              perPage: pageSize,
              setPerPage: setPageSize,
              page,
              setPage,
              total: 10,
              keyword,
              setKeyword,
            }}
          />
        )}
        {defaultTable === HASHCHIP_TYPE_SELECT[1] && (
          <Datatable
            scroll={{ x: 1300 }}
            columns={columns2}
            dataSource={[]}
            showPagination
            showHeader
            loading={false}
            metadata={{
              perPage: pageSize,
              setPerPage: setPageSize,
              page,
              setPage,
              total: 10,
              keyword,
              setKeyword,
            }}
          />
        )}
      </Col>
    </Row>
  )
}

export default RankingRewards
