import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  updateDepositPoolsSuccess,
  updateDepositPoolsFailed,
  UPDATE_DEPOSIT_POOLS_START,
} from '../../actions/pools'

/**
 * update deposit api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateDepositPoolsApi = (props) => {
  var uri = `/admin/treasury/deposit`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdateDepositPools(action) {
  try {
    const response = yield call(updateDepositPoolsApi, action?.payload)
      yield put(updateDepositPoolsSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
  } catch (error) {
    yield put(updateDepositPoolsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch update deposit
 */
export default function* watchUpdateDepositPools() {
  yield takeLatest(UPDATE_DEPOSIT_POOLS_START, doUpdateDepositPools)
}
