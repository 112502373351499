import PropTypes from 'prop-types'
// import { useTranslation } from '~/common/hooks/useTranslation'
import { Form, Modal, Button, Row, Col, Tooltip } from 'antd'
import React, { useEffect } from 'react'
import { useUser } from '~/views/app/redux/hooks/useUser'
import { converUserRankUser } from '~/common/constants'
import { formatCreateAtProfile, formatPhoneNumber } from '~/helpers/time-helper'
import { useTranslation } from '~/common/hooks/useTranslation'
function Profile(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, id } = props
  const {
    actions,
    data: { userDetail },
  } = useUser()

  const [form] = Form.useForm()

  const handleClose = () => {
    form.resetFields()
    onClose()
  }
  //Refresh data
  const refreshData = () => {
    actions.getDetailUser(id)
  }
  useEffect(() => {
    refreshData()
  }, [isShowModal, id])
  return (
    <Modal
      title={<>{t('managementUsers.form.profile')}</>}
      onCancel={handleClose}
      open={isShowModal}
      width={850}
      centered
      destroyOnClose
      footer={null}
    >
      <div className="font-normal">
        <Row gutter={24} style={{ fontFamily: 'Rexliafree' }}>
          <Col span={12} className="flex">
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/imgs/Login/Subtract.png`}
                alt=""
              />
            </div>
            <div className="flex flex-col justify-center ml-2">
              <Tooltip title={userDetail?.user?.user_name}>
                <div className="mb-4 text-2xl ">
                  {userDetail?.user?.user_name &&
                  userDetail?.user?.user_name.length > 9
                    ? userDetail?.user?.user_name.substring(0, 9) + '...'
                    : userDetail?.user?.user_name}
                </div>
              </Tooltip>
              <div>
                {converUserRankUser(
                  userDetail?.playerGameProgress?.player_rank,
                )}
              </div>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <div className="mb-[10px]">Account Creation Date</div>
            <div className="text-[20px]">
              {formatCreateAtProfile(userDetail?.user?.crt_dt)}
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <div className="text-[20px]" style={{ fontFamily: 'Rexliafree' }}>
              Bio
            </div>
            <div className="text-justify">{userDetail?.user?.player_bio}</div>
            <div className="flex mt-[10px]">
              {userDetail?.user?.player_twitter ? (
                <Button
                  style={{
                    padding: '10px 24px',
                    gap: '4',
                  }}
                >
                  <img
                    className="w-[16px] h-[16px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/twitter.svg`}
                    alt=""
                  />
                  @{userDetail?.user?.player_twitter}
                </Button>
              ) : (
                ''
              )}

              <div style={{ margin: '0 5px' }}></div>
              {userDetail?.user?.player_phone ? (
                <Button style={{ background: '#1890FF' }}>
                  {formatPhoneNumber(userDetail?.user?.player_phone)}
                </Button>
              ) : (
                ''
              )}
            </div>
            <div>
              <Row style={{ fontFamily: 'Rexliafree' }}>
                <Col span={18}>
                  <div className="text-[16px]" style={{ margin: '16px 0' }}>
                    Score Rank (Cycle)
                  </div>
                  <div className="text-[16px]">Cycle Score</div>
                  <div>Rank match</div>
                  <div>Exploration</div>
                  <div
                    className="text-[16px] "
                    style={{ margin: '16px 0 0 0' }}
                  >
                    Total Score
                  </div>
                  <div>Rank match</div>
                  <div>Exploration</div>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <div className="text-[16px]" style={{ margin: '16px 0' }}>
                    1,234
                  </div>
                  <div className="text-[16px]">1,234</div>
                  <div>500</div>
                  <div>500</div>
                  <div className="text-[16px]" style={{ margin: '16px 0 0 0' }}>
                    2,354
                  </div>
                  <div>500</div>
                  <div>500</div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            {/* Guild Name */}
            <div
              style={{
                background: '#000F38',
                border: '1px solid #1890FF',
                borderRadius: '5px',
              }}
            >
              <Row style={{ fontFamily: 'Rexliafree' }}>
                <Col
                  span={3}
                  style={{
                    background: 'rgba(24, 144, 255, 0.50)',
                    border: '1px solid #1890FF ',
                    borderRadius: '5px 0 0 5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/dashicons_shield.svg`}
                    alt=""
                  />
                </Col>
                <Col span={21} style={{ padding: '16px' }}>
                  <Row>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        className="w-[40px] h-[40px]"
                        src={`${process.env.PUBLIC_URL}/imgs/Login/Subtract.png`}
                        alt=""
                      />
                      <div className="text-[20px] ml-4"> Guild Name</div>
                    </Col>
                  </Row>
                  <Row style={{ margin: '13px 0' }}>
                    <Col span={10} className="text-[20px]">
                      <div>Role</div>
                    </Col>
                    <Col
                      span={14}
                      className="text-[16px]"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div>Guild Master</div>
                    </Col>
                  </Row>
                  <Row style={{ margin: '13px 0' }}>
                    <Col span={10} className="text-[20px]">
                      <div>Rank</div>
                    </Col>
                    <Col
                      span={14}
                      className="text-[16px]"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div>12/150</div>
                    </Col>
                  </Row>
                  <Row style={{ margin: '13px 0 0 0' }}>
                    <Col span={10} className="text-[20px]">
                      <div>Score</div>
                    </Col>
                    <Col
                      span={14}
                      className="text-[16px]"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div>30.123</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* Echo-Leaves */}
            <div
              style={{
                background: '#000F38',
                border: '1px solid #52C41A',
                borderRadius: '5px',
                marginTop: '4%',
              }}
            >
              <Row style={{ fontFamily: 'Rexliafree' }}>
                <Col
                  span={3}
                  style={{
                    background: 'rgba(82, 196, 26, 0.50)',
                    border: '1px solid #1890FF ',
                    borderRadius: '5px 0 0 5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/fluent-mdl2_quarter-circle.svg`}
                    alt=""
                  />
                </Col>
                <Col span={21} style={{ padding: '16px' }}>
                  <Row>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        className="w-[40px] h-[40px]"
                        src={`${process.env.PUBLIC_URL}/imgs/Login/Subtract.png`}
                        alt=""
                      />
                      <div className="text-[20px] ml-4">Echo-Leaves</div>
                    </Col>
                  </Row>
                  <Row style={{ margin: '13px 0' }}>
                    <Col span={10} className="text-[20px]">
                      <div>Rank</div>
                    </Col>
                    <Col
                      span={14}
                      className="text-[16px]"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div>12/150</div>
                    </Col>
                  </Row>
                  <Row style={{ margin: '13px 0 0 0' }}>
                    <Col span={10} className="text-[20px]">
                      <div>Score</div>
                    </Col>
                    <Col
                      span={14}
                      className="text-[16px]"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div>30.123</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

Profile.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default Profile
