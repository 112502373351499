import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import RevenueChart from '../components/RevenueChart'
import BuySellChart from '../components/BuySellChart'
import ItemChart from '../components/ItemChart'
import { useDashboard } from '~/views/app/redux/hooks/useDashboard'

const ShopDashboard = () => {
  const { actions } = useDashboard()
  //Date
  // const [dateOasRevenue, setDateOasRevenue] = useState(null)
  const [dateOasRevenue, setDateOasRevenue] = useState(['', ''])
  // const [dateBitRevenue, setDateBitRevenue] = useState(null)
  const [dateBitRevenue, setDateBitRevenue] = useState(['', ''])

  //Data
  const [dataOasRevenue, setDataOasRevenue] = useState([])
  const [dataBitRevenue, setDataBitRevenue] = useState([])

  useEffect(() => {
    const params = {
      type: 'OasRevenue',
    }
    if (dateOasRevenue[0] !== '') {
      let temp = dateOasRevenue[0].split('-')
      let temp1 = dateOasRevenue[1].split('-')
      params.fromTime = Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0) / 1000
      params.toTime =
        Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59) / 1000
    }
    actions.getDashBoardShop(params, (res) => {
      setDataOasRevenue(res.histories)
    })
  }, [dateOasRevenue])

  useEffect(() => {
    const params = {
      type: 'BitRevenue',
    }
    if (dateBitRevenue[0] !== '') {
      let temp = dateBitRevenue[0].split('-')
      let temp1 = dateBitRevenue[1].split('-')
      params.fromTime = Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0) / 1000
      params.toTime =
        Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59) / 1000
    }
    actions.getDashBoardBit(params, (res) => {
      setDataBitRevenue(res.histories)
    })
  }, [dateBitRevenue])
  return (
    <React.Fragment>
      <Row gutter={10}>
        <Col xl={12} md={12} sm={24} xs={24}>
          <RevenueChart
            type={'OasRevenue'}
            title={'OAS Revenue'}
            color={{
              borderColor: 'rgba(19, 178, 228, 1)',
              backgroundColor: 'rgba(19, 178, 228, 1)',
            }}
            setDate={setDateOasRevenue}
            dataChart={dataOasRevenue}
          />
        </Col>
        <Col xl={12} md={12} sm={24} xs={24}>
          <RevenueChart
            type={'BitRevenue'}
            title={'BIT Revenue'}
            color={{
              borderColor: 'rgba(255, 169, 64, 1)',
              backgroundColor: 'rgba(255, 169, 64, 1)',
            }}
            setDate={setDateBitRevenue}
            dataChart={dataBitRevenue}
          />
        </Col>
        <Col xl={12} md={12} sm={24} xs={24}>
          <BuySellChart />
        </Col>
        <Col xl={12} md={12} sm={24} xs={24}>
          <ItemChart />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ShopDashboard
