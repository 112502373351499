import { Card, DatePicker } from 'antd'
import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { useTranslation } from '~/common/hooks/useTranslation'
import moment from 'moment'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const { RangePicker } = DatePicker

const RevenueChart = (props) => {
  const { title, color, setDate, dataChart, type } = props

  const { t } = useTranslation()
  // eslint-disable-next-line
  const labels = dataChart?.map((item) => {
    const date = new Date((item?.timestamp * 1000) / 1000)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    const formattedDate = `${year}/${month}/${day}`
    return formattedDate
  })

  const datas =
    type === 'OasRevenue'
      ? dataChart?.map((item) => item?.params?.oasRevenue)
      : dataChart?.map((item) => item?.params?.bitRevenue)

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
        labels: {
          color: '#fff',
          usePointStyle: true,
        },
      },
      borderDash: {
        xAxis: [0, 888],
      },
    },

    scales: {
      y: {
        border: {
          color: '#fff',
        }, // for the grid lines
        grid: {
          display: true,
          color: 'rgba(255, 255, 255, 0.1)',
          tickBorderDash: [1, 10],
          tickColor: 'transparent',
          tickWidth: 2,
          offset: true,
          drawTicks: true,
          drawOnChartArea: true,
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 200,
          beginAtZero: true,
        },
      },
      x: {
        border: {
          color: '#fff',
        },
        grid: {
          display: true,
          borderDash: [5, 5],
          color: 'rgba(255, 255, 255, 0)',
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Monster',
        data: datas,
        borderColor: color?.borderColor ?? 'rgba(92, 89, 232, 1)',
        backgroundColor: color?.backgroundColor ?? 'rgba(92, 89, 232, 1)',
        tension: 0.4,
      },
    ],
  }
  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }
  const onChangeDate = (values) => {
    setDate(values)
  }
  return (
    <Card
      title={title ?? 'Title'}
      extra={
        <div className="flex gap-4 items-center">
          <RangePicker
            className="h-full max-w-[256px]"
            // onChange={(e) => setDate(e)}
            onChange={(values, dateString) => onChangeDate(dateString)}
            disabledDate={disabledDate}
          />
        </div>
      }
    >
      <div>
        <div className="text-[18px] font-medium">
          {datas?.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue
          }, 0) %
            1 ===
          0
            ? datas
                ?.reduce(function (accumulator, currentValue) {
                  return accumulator + currentValue
                }, 0)
                .toLocaleString('en-US')
            : datas
                ?.reduce(function (accumulator, currentValue) {
                  return accumulator + currentValue
                }, 0)
                .toFixed(6)}{' '}
          {type === 'OasRevenue' ? 'OAS' : 'BIT'}
        </div>

        <div className="text-[14px] text-[#BFBFBF]">
          {t('common.totalRevenue')}
        </div>
      </div>
      <Line style={{ height: 600 }} options={options} data={data} />
    </Card>
  )
}

export default RevenueChart
