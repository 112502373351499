export const GET_LIST_COLLABORATION_START = 'GET_LIST_COLLABORATION_START'
export const GET_LIST_COLLABORATION_SUCCESS = 'GET_LIST_COLLABORATION_SUCCESS'
export const GET_LIST_COLLABORATION_FAILED = 'GET_LIST_COLLABORATION_FAILED'

export const CREATE_COLLABORATION_START = 'CREATE_COLLABORATION_START'
export const CREATE_COLLABORATION_SUCCESS = 'CREATE_COLLABORATION_SUCCESS'
export const CREATE_COLLABORATION_FAILED = 'CREATE_COLLABORATION_FAILED'

export const GET_DETAIL_COLLABORATION_START = 'GET_DETAIL_COLLABORATION_START'
export const GET_DETAIL_COLLABORATION_SUCCESS = 'GET_DETAIL_COLLABORATION_SUCCESS'
export const GET_DETAIL_COLLABORATION_FAILED = 'GET_DETAIL_COLLABORATION_FAILED'

export const UPDATE_COLLABORATION_START = 'UPDATE_COLLABORATION_START'
export const UPDATE_COLLABORATION_SUCCESS = 'UPDATE_COLLABORATION_SUCCESS'
export const UPDATE_COLLABORATION_FAILED = 'UPDATE_COLLABORATION_FAILED'

export const DELETE_COLLABORATION_START = 'DELETE_COLLABORATION_START'
export const DELETE_COLLABORATION_SUCCESS = 'DELETE_COLLABORATION_SUCCESS'
export const DELETE_COLLABORATION_FAILED = 'DELETE_COLLABORATION_FAILED'

//-------------------------Collaboration------------------------//
/**
 * [1]
 * get list Collaboration start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListCollaboration(payload, onSuccess, onError) {
  return {
    type: GET_LIST_COLLABORATION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list Collaboration success action
 * @param {*} payload
 * @returns {object}
 */
export function getListCollaborationSuccess(payload) {
  return {
    type: GET_LIST_COLLABORATION_SUCCESS,
    payload: payload,
  }
}

/**
 * get list Collaboration failed action
 * @returns {object}
 */
export function getListCollaborationFailed() {
  return {
    type: GET_LIST_COLLABORATION_FAILED,
  }
}

/**
 * [2]
 * create Collaboration start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createCollaboration(payload, onSuccess, onError) {
  return {
    type: CREATE_COLLABORATION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * create Collaboration success action
 * @param {*} payload
 * @returns {object}
 */
export function createCollaborationSuccess(payload) {
  return {
    type: CREATE_COLLABORATION_SUCCESS,
    payload: payload,
  }
}

/**
 * create Collaboration failed action
 * @returns {object}
 */
export function createCollaborationFailed() {
  return {
    type: CREATE_COLLABORATION_FAILED,
  }
}

/**
 * [3]
 * get detail Collaboration start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDetailCollaboration(payload, onSuccess, onError) {
  return {
    type: GET_DETAIL_COLLABORATION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get detail Collaboration success action
 * @param {*} payload
 * @returns {object}
 */
export function getDetailCollaborationSuccess(payload) {
  return {
    type: GET_DETAIL_COLLABORATION_SUCCESS,
    payload: payload,
  }
}

/**
 * get detail Collaboration failed action
 * @returns {object}
 */
export function getDetailCollaborationFailed() {
  return {
    type: GET_DETAIL_COLLABORATION_FAILED,
  }
}

/**
 * [4]
 * update Collaboration start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateCollaboration(payload, onSuccess, onError) {
  return {
    type: UPDATE_COLLABORATION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update Collaboration success action
 * @param {*} payload
 * @returns {object}
 */
export function updateCollaborationSuccess(payload) {
  return {
    type: UPDATE_COLLABORATION_SUCCESS,
    payload: payload,
  }
}

/**
 * update Collaboration failed action
 * @returns {object}
 */
export function updateCollaborationFailed() {
  return {
    type: UPDATE_COLLABORATION_FAILED,
  }
}

/**
 * [5]
 * delete Collaboration start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteCollaboration(payload, onSuccess, onError) {
  return {
    type: DELETE_COLLABORATION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * delete Collaboration success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteCollaborationSuccess(payload) {
  return {
    type: DELETE_COLLABORATION_SUCCESS,
    payload: payload,
  }
}

/**
 * delete Collaboration failed action
 * @returns {object}
 */
export function deleteCollaborationFailed() {
  return {
    type: DELETE_COLLABORATION_FAILED,
  }
}

export default {
  getListCollaboration,
  getListCollaborationSuccess,
  getListCollaborationFailed,

  createCollaboration,
  createCollaborationSuccess,
  createCollaborationFailed,

  getDetailCollaboration,
  getDetailCollaborationSuccess,
  getDetailCollaborationFailed,

  updateCollaboration,
  updateCollaborationSuccess,
  updateCollaborationFailed,
  
  deleteCollaboration,
  deleteCollaborationSuccess,
  deleteCollaborationFailed,
}
