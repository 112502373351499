import React from 'react'
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from '~/common/constants/ThemeConstant'
import { connect } from 'react-redux'
import utils from '~/utils'
import { Grid } from 'antd'
import { Link } from 'react-router-dom'
import { ROUTE as ROUTES_APP } from '~/views/app/routes/config'
import logo_sm from '~/assets/imgs/logo/rm_sm.png'
import logo from '~/assets/imgs/logo/rm.png'

const { useBreakpoint } = Grid

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props
  const isNavTop = navType === NAV_TYPE_TOP
  if (isMobile && !props.mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const getLogo = (props) => {
  const { navCollapsed, logoType } = props
  if (logoType === 'light') {
    if (navCollapsed) {
      return logo_sm
    }
    return logo
  }

  if (navCollapsed) {
    return logo_sm
  }
  return logo
}

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return `logo h-full flex items-center justify-center ${
      isMobile ? 'flex-grow-1' : ''
    }`
  }
}

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  return (
    <div
      className={`${getLogoDisplay(isMobile, props.mobileLogo)}`}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <Link to={ROUTES_APP.MARKETPLACE_DASHBOARD.PATH}>
        <img
          style={{ objectFit: 'contain', width: '100%' }}
          src={getLogo(props)}
          alt="RM"
        />
      </Link>
    </div>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme
  return { navCollapsed, navType }
}

export default connect(mapStateToProps)(Logo)
