import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  updateWithdrawPoolsSuccess,
  updateWithdrawPoolsFailed,
  UPDATE_WITHDRAW_POOLS_START,
} from '../../actions/pools'

/**
 * update withdraw pools api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateWithdrawPoolsApi = (props) => {
  var uri = `/admin/treasury/withdraw`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdateWithdrawPools(action) {
  try {
    const response = yield call(updateWithdrawPoolsApi, action?.payload)
      yield put(updateWithdrawPoolsSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
  } catch (error) {
    yield put(updateWithdrawPoolsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch update withdraw pools
 */
export default function* watchUpdateWithdrawPools() {
  yield takeLatest(UPDATE_WITHDRAW_POOLS_START, doUpdateWithdrawPools)
}
