import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getListAdminSuccess,
  getListAdminFailed,
  GET_LIST_ADMIN_START,
} from '~/views/app/redux/actions/admin'

/**
 * get list user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListAdminApi = (params) => {
  const uri = `/admin/manager-user`
  return api.get(uri, params)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListAdmin(action) {
  try {
    const response = yield call(getListAdminApi, action?.payload)
    yield put(getListAdminSuccess(response))

    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getListAdminFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list user
 */
export default function* watchGetListAdmin() {
  yield takeLatest(GET_LIST_ADMIN_START, doGetListAdmin)
}
