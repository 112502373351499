class Utils {
  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree
    }
    let route
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
        route = this.getRouteInfo(navTree[p], path)
        if (route) {
          return route
        }
      }
    }
    return route
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = []
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key]
        if (element) {
          breakpoints.push(key)
        }
      }
    }
    return breakpoints
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    if (!hex) {
      return 'dark'
    }
    const threshold = 130
    const hRed = hexToR(hex)
    const hGreen = hexToG(hex)
    const hBlue = hexToB(hex)
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16)
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16)
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16)
    }
    function cutHex(h) {
      return h.charAt(0) === '#' ? h.substring(1, 7) : h
    }
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000
    if (cBrightness > threshold) {
      return 'dark'
    } else {
      return 'light'
    }
  }
}

export default Utils
