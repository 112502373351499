import { Button, Card, DatePicker, Popover, Row, Col, Switch, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  DEFAULT_ACTIVE_GAME_USER,
  itemsGameDashboard,
  levelKeys,
} from '~/common/constants'
import { getRandomColor } from '~/helpers/item-chart'
import { useDashboard } from '~/views/app/redux/hooks/useDashboard'
import moment from 'moment'
const { RangePicker } = DatePicker

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const GameChart = () => {
  const { t } = useTranslation()
  const { actions } = useDashboard()

  const [checkedList, setCheckedList] = useState(DEFAULT_ACTIVE_GAME_USER)
  const [date, setDate] = useState(['', ''])
  const [dataChart, setDataChart] = useState([])
  const labels = dataChart?.map((item) => {
    const formattedDate = item._id
    return formattedDate
  })
  // const [activeMenuChild, setActiveMenuChild] = useState('')
  // const [activeMenu, setActiveMenu] = useState('')
  const [stateOpenKeys, setStateOpenKeys] = useState(['user'])
  // useEffect(() => {
  //   if (activeMenuChild.key === 'user' || activeMenu.key === 'user') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_USER)
  //   } else if (
  //     activeMenuChild.key === 'revenue' ||
  //     activeMenuChild.key === 'monster_mint' ||
  //     activeMenuChild.key === 'nft_repair' ||
  //     activeMenuChild.key === 'genesis_box' ||
  //     activeMenuChild.key === 'general_box' ||
  //     activeMenuChild.key === 'bit' ||
  //     activeMenuChild.key === 'farm'
  //   ) {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_MONETIZATION_REVENUE)
  //   } else if (
  //     activeMenuChild.key === 'guild' ||
  //     activeMenuChild.key === 'change_guild'
  //   ) {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_MONETIZATION_GUILD)
  //   } else if (
  //     activeMenuChild.key === 'number_of_login_days' ||
  //     activeMenuChild.key === 'hp' ||
  //     activeMenuChild.key === 'str' ||
  //     activeMenuChild.key === 'int' ||
  //     activeMenuChild.key === 'dex' ||
  //     activeMenuChild.key === 'agi' ||
  //     activeMenuChild.key === 'vit' ||
  //     activeMenuChild.key === 'dustblade' ||
  //     activeMenuChild.key === 'whispwood' ||
  //     activeMenuChild.key === 'mystic_forest' ||
  //     activeMenuChild.key === 'skypeak' ||
  //     activeMenuChild.key === 'windland' ||
  //     activeMenuChild.key === 'greenfield' ||
  //     activeMenuChild.key === 'ash_valley' ||
  //     activeMenuChild.key === 'silver_lake' ||
  //     activeMenuChild.key === 'mirage_cove' ||
  //     activeMenuChild.key === 'lostland' ||
  //     activeMenuChild.key === 'a' ||
  //     activeMenuChild.key === 'b' ||
  //     activeMenuChild.key === 'c' ||
  //     activeMenuChild.key === 'd' ||
  //     activeMenuChild.key === 'e' ||
  //     activeMenuChild.key === 'f' ||
  //     activeMenuChild.key === 'rest_count' ||
  //     activeMenuChild.key === 'hospital_count' ||
  //     activeMenuChild.key === 'bit_earned' ||
  //     activeMenuChild.key === 'bit_used' ||
  //     activeMenuChild.key === 'total_monster' ||
  //     activeMenuChild.key === 'current_monster' ||
  //     activeMenuChild.key === 'current_memory' ||
  //     activeMenuChild.key === 'crystalization_count' ||
  //     activeMenuChild.key === 'coach_count' ||
  //     activeMenuChild.key === 'coach_used' ||
  //     activeMenuChild.key === 'used_crystalize' ||
  //     activeMenuChild.key === 'number_of_farm' ||
  //     activeMenuChild.key === 'training_count_hp' ||
  //     activeMenuChild.key === 'training_count_str' ||
  //     activeMenuChild.key === 'training_count_int' ||
  //     activeMenuChild.key === 'training_count_dex' ||
  //     activeMenuChild.key === 'training_count_agi' ||
  //     activeMenuChild.key === 'training_count_vit' ||
  //     activeMenuChild.key === 'intensive_training_count_dustblade' ||
  //     activeMenuChild.key === 'intensive_training_count_whispwood' ||
  //     activeMenuChild.key === 'intensive_training_count_mystic_forest' ||
  //     activeMenuChild.key === 'intensive_training_count_skypeak' ||
  //     activeMenuChild.key === 'intensive_training_count_windland' ||
  //     activeMenuChild.key === 'intensive_training_count_greenfield' ||
  //     activeMenuChild.key === 'intensive_training_count_ash_valley' ||
  //     activeMenuChild.key === 'intensive_training_count_silver_lake' ||
  //     activeMenuChild.key === 'intensive_training_count_mirage_cove' ||
  //     activeMenuChild.key === 'intensive_training_count_lostland' ||
  //     activeMenuChild.key === 'tournament_battle_count_a' ||
  //     activeMenuChild.key === 'tournament_battle_count_b' ||
  //     activeMenuChild.key === 'tournament_battle_count_c' ||
  //     activeMenuChild.key === 'tournament_battle_count_d' ||
  //     activeMenuChild.key === 'tournament_battle_count_e' ||
  //     activeMenuChild.key === 'tournament_battle_count_f' ||
  //     activeMenuChild.key === 'tournament_battle_count_s' ||
  //     activeMenuChild.key === 'time_passed_in_game'
  //   ) {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_TOTAL_AVERAGE)
  //   } else if (activeMenuChild.key === 'monster_training_completed') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_MONSTER_TOTAL_TRAINING)
  //   } else if (activeMenu.key === 'monster') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_MONSTER)
  //   } else if (
  //     activeMenuChild.key === 'monster_rank' ||
  //     activeMenuChild.key === 'monster_rank_crystalized' ||
  //     activeMenuChild.key === 'monster_rank_coached'
  //   ) {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_MONSTER_RANK)
  //   } else if (
  //     activeMenuChild.key === 'average_basic_parameter' ||
  //     activeMenuChild.key === 'average_basic_crystalize_parameter' ||
  //     activeMenuChild.key === 'average_basic_coach_parameter' ||
  //     activeMenuChild.key === 'average_basic_coach_parameter_used' ||
  //     activeMenuChild.key === 'parameter_selected_coach' ||
  //     activeMenuChild.key === 'parameter_used_coach' ||
  //     activeMenuChild.key === 'average_basic_crystalize_parameter_used'
  //   ) {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_MONSTER_AVERAGE)
  //   } else if (activeMenuChild.key === 'personality') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_MONSTER_PERSONALITY)
  //   } else if (activeMenuChild.key === 'innate_traits') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_MONSTER_INNATE_TRAITS)
  //   } else if (activeMenuChild.key === 'accquired_traits') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_MONSTER_ACCQUIRED_TRAITS)
  //   } else if (
  //     activeMenuChild.key === 'farm_basic_enhance_value' ||
  //     activeMenuChild.key === 'farm_potential' ||
  //     activeMenuChild.key === 'farm_max_enhance_value' ||
  //     activeMenuChild.key === 'special_training_value'
  //   ) {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_FARM_BASIC)
  //   } else if (activeMenuChild.key === 'farm_average_parameter') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_FARM_AVERAGE_PARAMETER)
  //   } else if (activeMenu.key === 'item') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_ITEM)
  //   } else if (activeMenuChild.key === 'player_rank') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_GAMEPLAY_PLAYER_RANK)
  //   } else if (activeMenuChild.key === 'chosen_meal') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_GAMEPLAY_CHOSEN_MEAL)
  //   } else if (activeMenu.key === 'coach') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_COACH)
  //   } else if (activeMenuChild.key === 'farm_trait_id') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_FARM_TRAIT_ID)
  //   } else if (activeMenu.key === 'crystalize') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_CRYSTALIZE)
  //   } else if (activeMenu.key === 'farmNFT') {
  //     setCheckedList(DEFAULT_ACTIVE_GAME_FARM)
  //   }
  // }, [activeMenuChild, activeMenu])
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true,
        labels: {
          color: '#fff',
          usePointStyle: true,
        },
      },
      borderDash: {
        xAxis: [0, 888],
      },
    },
    scales: {
      min: 0,
      y: {
        border: {
          color: '#fff',
        }, // for the grid lines
        grid: {
          display: true,
          color: 'rgba(255, 255, 255, 0.1)',
          tickBorderDash: [1, 10],
          tickColor: 'transparent',
          tickWidth: 2,
          offset: true,
          drawTicks: true,
          drawOnChartArea: true,
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 200,
          beginAtZero: true,
        },
      },
      x: {
        border: {
          color: '#fff',
        },
        grid: {
          display: true,
          borderDash: [5, 5],
          color: 'rgba(255, 255, 255, 0)',
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }
  const getNestedValue = (obj, keys) => {
    return keys.reduce((acc, key) => acc && acc[key], obj)
  }

  const transformKeys = (keys) => {
    return keys.map((key) => (key === 'farmNFT' ? 'farm' : key))
  }

  const data = {
    labels,
    datasets: checkedList?.map((dataItem) => {
      const transformedKeys = transformKeys(stateOpenKeys)
      const color = getRandomColor() // Random màu cho dataset này

      const obj = {
        label: t(`item.${dataItem}`),
        data: dataChart?.map((game, index) => {
          const value = getNestedValue(game?.firstData, transformedKeys)
          return value && value[dataItem] != null ? value[dataItem] : 0
        }),
        borderColor: color,
        backgroundColor: color,
      }

      return obj
    }),
  }

  useEffect(() => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    const startDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000)
    const endDate = currentDate
    const params = {
      startDate: startDate.toISOString().slice(0, 10),
      endDate: endDate.toISOString().slice(0, 10),
    }

    if (date[0] !== '') {
      let temp = date[0].split('-')
      let temp1 = date[1].split('-')
      params.startDate = `${temp[0]}-${temp[1]}-${temp[2]}`
      params.endDate = `${temp1[0]}-${temp1[1]}-${temp1[2]}`
    }

    actions.getDashBoardGame(params, (res) => {
      setDataChart(res.data)
    })
  }, [date])

  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }
  const onChangeDate = (values) => {
    setDate(values)
  }
  const handleSwitchChange = (checked, item) => {
    if (checked) {
      if (checkedList.length < 5) {
        setCheckedList((prevList) => [...prevList, item.key])
      } else {
        setCheckedList((prevList) => {
          const newList = [...prevList]
          newList.shift()
          return [...newList, item.key]
        })
      }
    } else {
      setCheckedList((prevList) => prevList.filter((i) => i !== item.key))
    }
  }

  // const handleMenuClick = (item) => {
  //   setActiveMenu(item)
  // }
  // const handleMenuClickChild = (item) => {
  //   setActiveMenuChild(item)
  // }

  const renderSwitches = (children) => {
    return children.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.key}
            title={item.label}
            // onTitleClick={() => handleMenuClickChild(item)}
          >
            {renderSwitches(item.children)}
          </Menu.SubMenu>
        )
      } else {
        return (
          <Menu.Item
            key={item.key}
            style={{ marginBottom: 10 }}
            // onClick={() => handleMenuClickChild(item)}
          >
            <Row>
              <Col span={18}>
                <span>{item.label}</span>
              </Col>
              <Col span={4}>
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={checkedList.includes(item.key)}
                  onChange={(checked) => handleSwitchChange(checked, item)}
                />
              </Col>
            </Row>
          </Menu.Item>
        )
      }
    })
  }

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    )
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey])
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      )
    } else {
      setStateOpenKeys(openKeys)
    }
  }
  return (
    <>
      <div>
        <Row gutter={24} className="equal-height-row-GameDashboard">
          <Col span={18} className="equal-height-col-GameDashboard">
            <Card
              title=""
              extra={
                <div className="flex gap-4 items-center">
                  <Popover
                    className="w-full"
                    trigger="click"
                    placement="bottom"
                    arrow={false}
                  >
                    <Button className="!bg-[#00113D] border__grey hover:!border-[#40A9FF] h-[32.19px] !w-[210px]"></Button>
                  </Popover>
                  <RangePicker
                    className="h-full max-w-[256px]"
                    onChange={(values, dateString) => onChangeDate(dateString)}
                    disabledDate={disabledDate}
                  />
                </div>
              }
            >
              <div>{t(`gameDashboard.unit`)}</div>
              <Line options={options} data={data} />
            </Card>
          </Col>
          <Col span={6} className="equal-height-col-GameDashboard">
            <Card className="gameFilter" title="">
              <Row>
                <Col span={24}>
                  <div
                    className="gameMenu"
                    style={{
                      overflowY: 'auto',
                      minHeight: '700px',
                      maxHeight: '746px',
                    }}
                  >
                    <Menu mode="inline" onOpenChange={onOpenChange}>
                      {itemsGameDashboard.map((item) => (
                        <Menu.SubMenu key={item.key} title={item.label}>
                          {renderSwitches(item.children)}
                        </Menu.SubMenu>
                      ))}
                    </Menu>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default GameChart
