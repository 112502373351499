import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getListCollaborationSuccess,
  getListCollaborationFailed,
  GET_LIST_COLLABORATION_START,
} from '~/views/app/redux/actions/collaboration'

/**
 * get list Collaboration api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListCollaborationApi = (params) => {
  const uri = `/admin/collaboration-nft`
  return api.get(uri, params)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListCollaboration(action) {
  try {
    const response = yield call(getListCollaborationApi, action?.payload)
    yield put(getListCollaborationSuccess(response))

    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }

  } catch (error) {
    yield put(getListCollaborationFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list Collaboration
 */
export default function* watchGetListCollaboration() {
  yield takeLatest(GET_LIST_COLLABORATION_START, doGetListCollaboration)
}
