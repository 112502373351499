import PropTypes from 'prop-types'
import { Form, Modal, Button, Row, Col, Menu } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
function Achievement(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose } = props
  const [form] = Form.useForm()
  const [menuOpen, setMenuOpen] = useState(false)
  const [keyOpenTournament, setKeyOpenTournament] = useState([])
  const handleOpenChangeRanking = (keys) => {
    if (keys?.length > 0) {
      setMenuOpen(true)
    } else {
      setMenuOpen(false)
    }
  }
  const handleOpenChangeTournament = (keys) => {
    setKeyOpenTournament(keys)
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  const ranking = [
    {
      value: 'ranking',
      key: 'ranking',
      label: 'Ranking',
      children: [
        {
          value: 'total_score',
          key: 'total_score',
          label: 'Total Score',
          number: 123332223,
        },
        {
          value: 'ranker_match',
          key: 'ranker_match',
          label: 'Ranker Match',
          number: 123,
        },
        {
          value: 'ranker_match',
          key: 'ranker_match',
          label: 'Ranker Match',
          number: 123,
        },
        {
          value: 'win',
          key: 'win',
          label: 'Win',
          number: 123,
        },
        {
          value: 'exploration',
          key: 'exploration',
          label: 'Exploration',
          number: 123,
        },
        {
          value: 'lose',
          key: 'lose',
          label: 'Lose',
          number: 123,
        },
      ],
    },
  ]
  const tournament = [
    {
      value: 'tournament',
      key: 'tournament',
      label: 'Tournament',
      children: [
        {
          value: 'count',
          key: 'count',
          label: 'Count',
          number: 123332223,
        },
        {
          value: 's',
          key: 's',
          label: 'S',
          number: 2,
          children: [
            {
              value: 'masters_cup',
              key: 's_masters_cup',
              label: 'Masters Cup',
              number: 1,
            },
            {
              value: 'monsters_grand_prix',
              key: 's_monsters_grand_prix',
              label: 'Monsters Grand Prix',
              number: 0,
            },
            {
              value: 'official_competition',
              key: 's_official_competition',
              label: 'Official Competition',
              number: 1,
            },
            {
              value: 'crown_cup',
              key: 's_crown_cup',
              label: 'Crown Cup',
              number: 0,
            },
            {
              value: 'sanctuary_cup',
              key: 's_sanctuary_cup',
              label: 'Sanctuary Cup',
              number: 0,
            },
            {
              value: 'winners_cup',
              key: 's_winners_cup',
              label: 'Winners Cup',
              number: 0,
            },
          ],
        },
        {
          value: 'a',
          key: 'a',
          label: 'A',
          number: 2,
          children: [
            {
              value: 'fusion_cup',
              key: 'a_fusion_cup',
              label: 'Fusion Cup',
              number: 2,
            },
            {
              value: 'official_competition',
              key: 'a_official_competition',
              label: 'Official Competition',
              number: 123,
            },
            {
              value: 'leaders_cup',
              key: 'a_leaders_cup',
              label: 'Leaders Cup',
              number: 123,
            },
            {
              value: 'gold_cup',
              key: 'a_gold_cup',
              label: 'Gold Cup',
              number: 123,
            },
            {
              value: 'forces_cup',
              key: 'a_forces_cup',
              label: 'Forces Cup',
              number: 123,
            },
            {
              value: 'stamina_cup',
              key: 'a_stamina_cup',
              label: 'Stamina Cup',
              number: 123,
            },
            {
              value: 'secret_cup',
              key: 'a_secret_cup',
              label: 'Secret Cup',
              number: 123,
            },
          ],
        },
        {
          value: 'b',
          key: 'b',
          label: 'B',
          number: 123,
          children: [
            {
              value: 'strong_cup',
              key: 'b_strong_cup',
              label: 'Strong Cup',
              number: 123,
            },
            {
              value: 'moon_night_award',
              key: 'b_moon_night_award',
              label: 'Moon Night Award',
              number: 123,
            },
            {
              value: 'official_competition',
              key: 'b_official_competition',
              label: 'Official Competition',
              number: 123,
            },
            {
              value: 'sun_rise_cup',
              key: 'b_sun_rise_cup',
              label: 'Sun Rise Cup',
              number: 123,
            },
            {
              value: 'quick_cup',
              key: 'b_quick_cup',
              label: 'Quick Cup',
              number: 123,
            },
            {
              value: 'genius_cup',
              key: 'b_genius_cup',
              label: 'Genius Cup',
              number: 123,
            },
            {
              value: 'silver_cup',
              key: 'b_silver_cup',
              label: 'Silver Cup',
              number: 123,
            },
            {
              value: 'strudy_cup',
              key: 'b_strudy_cup',
              label: 'Strudy Cup',
              number: 123,
            },
          ],
        },
        {
          value: 'c',
          key: 'c',
          label: 'C',
          number: 123,
          children: [
            {
              value: 'energy_cup',
              key: 'c_energy_cup',
              label: 'Energy Cup',
              number: 123,
            },
            {
              value: 'fresh_cup',
              key: 'c_fresh_cup',
              label: 'Fresh Cup',
              number: 123,
            },
            {
              value: 'official_competition',
              key: 'c_official_competition',
              label: 'Official Competition',
              number: 123,
            },
            {
              value: 'fairy_cup',
              key: 'c_fairy_cup',
              label: 'Fairy Cup',
              number: 123,
            },
            {
              value: 'refresh_cup',
              key: 'c_refresh_cup',
              label: 'Refresh Cup',
              number: 123,
            },
            {
              value: 'cheer_cup',
              key: 'c_cheer_cup',
              label: 'Cheer Cup',
              number: 123,
            },
            {
              value: 'glory_cup',
              key: 'c_glory_cup',
              label: 'Glory Cup',
              number: 123,
            },
            {
              value: 'caramel_cup',
              key: 'c_caramel_cup',
              label: 'Caramel Cup',
              number: 123,
            },
            {
              value: 'fullness_cup',
              key: 'c_fullness_cup',
              label: 'Fullness Cup',
              number: 123,
            },
          ],
        },
        {
          value: 'd',
          key: 'd',
          label: 'D',
          number: 123,
          children: [
            {
              value: 'snowdrop_cup',
              key: 'd_snowdrop_cup',
              label: 'Snowdrop Cup',
              number: 123,
            },
            {
              value: 'sunny_cup',
              key: 'd_sunny_cup',
              label: 'Sunny Cup',
              number: 123,
            },
            {
              value: 'official_competition',
              key: 'd_official_competition',
              label: 'Official Competition',
              number: 123,
            },
            {
              value: 'silent_cup',
              key: 'd_silent_cup',
              label: 'Silent Cup',
              number: 123,
            },
            {
              value: 'iris_cup',
              key: 'd_iris_cup',
              label: 'Iris Cup',
              number: 123,
            },
            {
              value: 'salvia_cup',
              key: 'd_salvia_cup',
              label: 'Salvia Cup',
              number: 123,
            },
            {
              value: 'passion_cup',
              key: 'd_passion_cup',
              label: 'Passion Cup',
              number: 123,
            },
            {
              value: 'marigold_cup',
              key: 'd_marigold_cup',
              label: 'Marigold Cup',
              number: 123,
            },
            {
              value: 'rose_cup',
              key: 'd_rose_cup',
              label: 'Rose Cup',
              number: 123,
            },
            {
              value: 'maple_cup',
              key: 'd_maple_cup',
              label: 'Maple Cup',
              number: 123,
            },
          ],
        },
        {
          value: 'e',
          key: 'e',
          label: 'E',
          number: 123,
          children: [
            {
              value: 'narcissus_cup',
              key: 'e_narcissus_cup',
              label: 'Narcissus Cup',
              number: 123,
            },
            {
              value: 'spring_cup',
              key: 'e_spring_cup',
              label: 'Spring Cup',
              number: 123,
            },
            {
              value: 'official_competition',
              key: 'e_official_competition',
              label: 'Official Competition',
              number: 123,
            },
            {
              value: 'azalea_cup',
              key: 'e_azalea_cup',
              label: 'Azalea Cup',
              number: 123,
            },
            {
              value: 'morning_cup',
              key: 'e_morning_cup',
              label: 'Morning Cup',
              number: 123,
            },
            {
              value: 'sunflower_cup',
              key: 'e_sunflower_cup',
              label: 'Sunflower Cup',
              number: 123,
            },
            {
              value: 'summer_cup',
              key: 'e_summer_cup',
              label: 'Summer Cup',
              number: 123,
            },
            {
              value: 'dahlia_cup',
              key: 'e_dahlia_cup',
              label: 'Dahlia Cup',
              number: 123,
            },
            {
              value: 'autumn_cup',
              key: 'e_autumn_cup',
              label: 'Autumn Cup',
              number: 123,
            },
            {
              value: 'winter_cup',
              key: 'e_winter_cup',
              label: 'Winter Cup',
              number: 123,
            },
          ],
        },
        {
          value: 'f',
          key: 'f',
          label: 'F',
          number: 123,
          children: [
            {
              value: 'new_year_cup',
              key: 'f_new_year_cup',
              label: 'New Year Cup',
              number: 123,
            },
            {
              value: 'official_competition',
              key: 'f_official_competition',
              label: 'Official Competition',
              number: 123,
            },
            {
              value: 'beginners_cup',
              key: 'f_beginners_cup',
              label: 'Beginners Cup',
              number: 123,
            },
            {
              value: 'newcomer_cup',
              key: 'f_newcomer_cup',
              label: 'Newcomer Cup',
              number: 123,
            },
            {
              value: 'rookie_cup',
              key: 'f_rookie_cup',
              label: 'Rookie Cup',
              number: 123,
            },
            {
              value: 'novice_cup',
              key: 'f_novice_cup',
              label: 'Novice Cup',
              number: 123,
            },
            {
              value: 'trainee_cup',
              key: 'f_trainee_cup',
              label: 'Trainee Cup',
              number: 123,
            },
            {
              value: 'apprentice_cup',
              key: 'f_apprentice_cup',
              label: 'Apprentice Cup',
              number: 123,
            },
            {
              value: 'junior_cup',
              key: 'f_junior_cup',
              label: 'Junior Cup',
              number: 123,
            },
          ],
        },
      ],
    },
  ]
  const getColorRank = (value) => {
    switch (value) {
      case 's':
        return { color: '#F4AF3E' }
      case 'a':
        return { color: '#D064A5' }
      case 'b':
        return { color: '#722ED1' }
      case 'c':
        return { color: '#2F54EB' }
      case 'd':
        return { color: '#4AACD1' }
      case 'e':
        return { color: '#52C41A' }
      case 'f':
        return { color: '#8C8C8C' }
      default:
        return {}
    }
  }

  const renderMenus = (children) => {
    return children.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.key}
            title={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <div style={getColorRank(item.key)}>{item.label}</div>
                <img
                  className=" ml-[93%]"
                  src={`${process.env.PUBLIC_URL}/svgs/formProfile/${
                    keyOpenTournament.includes(item.key) ? 'up.svg' : 'down.svg'
                  }`}
                  alt=""
                />
              </span>
            }
          >
            {renderMenus(item.children)}
          </Menu.SubMenu>
        )
      } else {
        return (
          <Menu.Item key={item.key} style={{ marginBottom: 10 }}>
            <Row>
              <Col span={20}>
                <span>{item.label}</span>
              </Col>
              <Col span={4}>
                <span className="flex justify-end">{item.number}</span>
              </Col>
            </Row>
          </Menu.Item>
        )
      }
    })
  }
  return (
    <Modal
      title={<>{t('managementUsers.form.achievement')}</>}
      onCancel={handleClose}
      open={isShowModal}
      width={800}
      centered
      destroyOnClose
      footer={null}
    >
      <div
        className="font-normal achievement"
        style={{
          maxHeight: '600px',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Row gutter={24} style={{ fontFamily: 'Rexliafree' }}>
          <Col span={12}>
            {/* Player */}
            <div
              style={{
                background: '#0D2758',
                padding: '12px',
                borderRadius: '5px',
                lineHeight: '24px',
                marginBottom: '20px',
              }}
            >
              <Row className="mb-3">
                <Col
                  span={12}
                  className="flex"
                  style={{ alignItems: 'center' }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/mdi_user.svg`}
                    alt=""
                  />
                  <div className="text-[20px] ml-2">Player</div>
                </Col>
              </Row>
              <Row>
                <Col span={16}>Player Rank</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  Beginner
                </Col>
              </Row>
              <Row>
                <Col span={16}>Player Time</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  99:99
                </Col>
              </Row>
              <Row>
                <Col span={16}>Total BIT</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  55,000
                </Col>
              </Row>
              <Row>
                <Col span={16}>Total MA Point</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  1,654
                </Col>
              </Row>
              <Row>
                <Col span={16}>Complete Daily Quest</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  95
                </Col>
              </Row>
              <Row>
                <Col span={16}>Complete Weekly Quest</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  12
                </Col>
              </Row>
            </div>
            {/* Raise */}
            <div
              style={{
                background: '#0D2758',
                padding: '12px',
                borderRadius: '5px',
                lineHeight: '24px',
                marginBottom: '20px',
              }}
            >
              <Row className="mb-3">
                <Col
                  span={12}
                  className="flex"
                  style={{ alignItems: 'center' }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/raise.svg`}
                    alt=""
                  />
                  <div className="text-[20px] ml-2">Raise</div>
                </Col>
              </Row>
              <Row>
                <Col span={16}>Monster Boost</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  5
                </Col>
              </Row>
              <Row>
                <Col span={16}>Farm Boost</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  10
                </Col>
              </Row>
              <Row>
                <Col span={16}>Training</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  25
                </Col>
              </Row>
              <Row>
                <Col span={16}>Intensive Training</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  8
                </Col>
              </Row>
              <Row>
                <Col span={16}>Exploration </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  12
                </Col>
              </Row>
              <Row>
                <Col span={16}>Items found</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  35
                </Col>
              </Row>
            </div>
          </Col>
          {/* Monster */}
          <Col span={12}>
            <div
              style={{
                background: '#0D2758',
                padding: '12px',
                borderRadius: '5px',
                lineHeight: '24px',
                marginBottom: '20px',
                height: '96%',
              }}
            >
              <Row className="mb-3">
                <Col
                  span={12}
                  className="flex"
                  style={{ alignItems: 'center' }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/monster.svg`}
                    alt=""
                  />
                  <div className="text-[20px] ml-2">Monster</div>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Button
                    style={{
                      fontSize: '12px',
                      background: '#1890FF',
                      borderRadius: '10px',
                    }}
                  >
                    Database
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={16}>Max Monster Rank</Col>
                <Col
                  span={8}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <img
                    className="w-[25px] h-[25px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/A.svg`}
                    alt=""
                  />
                </Col>
              </Row>
              <Row>
                <Col span={16}>Regenerations</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  52
                </Col>
              </Row>
              <Row>
                <Col span={16}>Fusions</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  5
                </Col>
              </Row>
              <Row>
                <Col span={16}>Crystallization</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  8
                </Col>
              </Row>
              <Row>
                <Col span={16}>Coach</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  6
                </Col>
              </Row>
              <Row>
                <Col span={16}>Monster Seed</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  18/142
                </Col>
              </Row>
              <div className="text-[12px] mt-[16px]">
                Number of times each seed is obtained
              </div>

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7,40px)',
                  gridAutoRows: '40px',
                  gap: '10px',
                  marginTop: '20px',
                }}
              >
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    background: 'green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  4
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div
          className="rankingAchievement"
          style={{
            fontFamily: 'Rexliafree',
            background: '#0D2758',
            lineHeight: '24px',
            borderRadius: '5px',
            marginBottom: '20px',
          }}
        >
          <Menu mode="inline" onOpenChange={handleOpenChangeRanking}>
            {ranking.map((item) => (
              <Menu.SubMenu
                key={item.key}
                title={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className="w-[24px] h-[24px]"
                      src={`${process.env.PUBLIC_URL}/svgs/formProfile/ranking.svg`}
                      alt=""
                      style={{ marginRight: '8px' }}
                    />
                    <div className="text-[20px]">{item.label}</div>
                    <img
                      className="w-[32px] h-[32px] ml-[77%]"
                      src={`${process.env.PUBLIC_URL}/svgs/formProfile/${
                        menuOpen ? 'up.svg' : 'down.svg'
                      }`}
                      alt=""
                    />
                  </span>
                }
              >
                {renderMenus(item.children)}
              </Menu.SubMenu>
            ))}
          </Menu>
        </div>
        <div
          className="rankingAchievement"
          style={{
            fontFamily: 'Rexliafree',
            background: '#0D2758',
            lineHeight: '24px',
            borderRadius: '5px',
          }}
        >
          <Menu mode="inline" onOpenChange={handleOpenChangeTournament}>
            {tournament.map((item) => (
              <Menu.SubMenu
                key={item.key}
                title={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className="w-[24px] h-[24px]"
                      src={`${process.env.PUBLIC_URL}/svgs/formProfile/tournament.svg`}
                      alt=""
                      style={{ marginRight: '8px' }}
                    />
                    <div className="text-[20px]">{item.label}</div>
                    <img
                      className="w-[32px] h-[32px] ml-[71%]"
                      src={`${process.env.PUBLIC_URL}/svgs/formProfile/${
                        keyOpenTournament.includes(item.key)
                          ? 'up.svg'
                          : 'down.svg'
                      }`}
                      alt=""
                    />
                  </span>
                }
              >
                {renderMenus(item.children)}
              </Menu.SubMenu>
            ))}
          </Menu>
        </div>
      </div>
    </Modal>
  )
}

Achievement.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default Achievement
