import { useTranslation } from '~/common/hooks/useTranslation'
import LoginForm from './form/LoginForm'

export const Login = () => {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <LoginForm t={t} />
      </div>
    </>
  )
}
export default Login
