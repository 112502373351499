import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  updateStatusShopSuccess,
  updateStatusShopFailed,
  UPDATE_STATUS_SHOP_START,
} from '../../actions/shop'

/**
 * update status shop api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateStatusShopApi = (props) => {
  var uri = `/admin/shops/set-status`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdateStatusShop(action) {
  try {
    const response = yield call(updateStatusShopApi, action?.payload)
      yield put(updateStatusShopSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
  } catch (error) {
    yield put(updateStatusShopFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch update status shop
 */
export default function* watchUpdateStatusShop() {
  yield takeLatest(UPDATE_STATUS_SHOP_START, doUpdateStatusShop)
}
