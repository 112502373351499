import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Button, Input, Form, message } from 'antd'
import { useProfile } from '~/views/app/redux/hooks/useProfile'
export function ModalEmailVerify(props) {
  const { action, t, userInfo, checkNumberVerify } = props
  const [formEmail] = Form.useForm()
  const [inputValue, setInputValue] = useState('')
  const [minutes, setMinutes] = useState(4)
  const [seconds, setSeconds] = useState(59)
  const [checkVerify, setCheckVerify] = useState(0)
  // const [disableVerify, setDisableVerify] = useState(false)
  useEffect(() => {
    checkNumberVerify(checkVerify)
  }, [checkVerify])
  useEffect(() => {
    // setDisableVerify(false)
  }, [action?.isModalVerifyFailedOpen])
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`

  const rules = {
    code: [
      {
        required: true,
        message: t('validate.required', {
          0: 'Code',
        }),
      },
    ],
  }

  const handleVerify = (values) => {
    // setDisableVerify(true)
    actions?.updateEmail(
      {
        code: values?.code,
        email: action?.isModalOpen?.email,
        userId: userInfo?._id,
      },
      (res) => {
        if (res.status === true) {
          action?.handleCancel()
          action?.showModalVerifySuccess()
        } else {
          action?.showModalVerifyFailed()
          setCheckVerify((prevCheckVerify) => prevCheckVerify + 1)
        }
      },
    )
  }
  const { actions } = useProfile()

  useEffect(() => {
    if (action?.isModalOpen?.isOpen && action?.isModalOpen?.email !== null) {
      const intervalId = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          clearInterval(intervalId)
        } else if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1)
          setSeconds(59)
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1)
        }
      }, 1000)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [action?.isModalOpen, minutes, seconds])

  const handleResendCode = () => {
    const formData = {
      userId: userInfo?._id,
      email: action?.isModalOpen?.email,
    }

    actions?.sendVerifyCode(formData, (res) => {
      if (res?.success === false) {
        message.error('Send verify Failed')
        setMinutes(0)
        setSeconds(0)
      } else {
        setMinutes(4)
        setSeconds(59)
      }
    })
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    setMinutes(4)
    setSeconds(59)
  }, [action?.isModalOpen])

  const closeModalVerifyEmail = () => {
    formEmail.resetFields(['code'])
    action?.handleCancel()
  }
  useEffect(() => {
    if (!action?.isModalOpen?.isOpen) {
      formEmail.resetFields(['code'])
      setCheckVerify(0)
    }
  }, [action?.isModalOpen])
  return (
    <>
      <Modal
        width={572}
        centered
        title={
          <h3 className="flex justify-center text-[20px] font-normal text-[#fff]">
            {t('profile.accountSetting.modal.emailVerify.title')}
          </h3>
        }
        open={action?.isModalOpen?.isOpen}
        onCancel={closeModalVerifyEmail}
        footer={[]}
      >
        <Form
          layout="vertical"
          onFinish={handleVerify}
          form={formEmail}
          translate="yes"
          autoComplete="off"
          size="middle"
          validateTrigger={['onBlur', 'onChange']}
        >
          <div className="flex justify-center pt-10 flex-wrap gap-2 w-full mb-2 ">
            <div>
              <Row>
                <Col
                  span={20}
                  className="my-[-50px] mb-[30px] font__M__plus text-[16px] font-bold"
                >
                  {t('profile.accountSetting.modal.emailVerify.message', {
                    email: action?.isModalOpen?.email,
                  })}
                  {t('profile.accountSetting.modal.emailVerify.message1')}
                </Col>

                <Col span={24} className="font__M__plus text-[16px] font-bold">
                  <Form.Item
                    label={
                      <span className="font__M__plus font-bold text-[16px]">
                        {t('profile.accountSetting.modal.emailVerify.message2')}
                      </span>
                    }
                    name="code"
                    rules={rules.code}
                    labelAlign="left"
                  >
                    <Input
                      className="h-[44px] my-4 text-black bg-white font__M__plus"
                      value={inputValue}
                      onChange={handleInputChange}
                      placeholder={t(
                        'profile.accountSetting.modal.emailVerify.message2',
                      )}
                    />
                  </Form.Item>

                  {(minutes > 0 || seconds > 0) && (
                    <div className="flex items-center justify-center">
                      <span className="mr-4 text-gray-300">
                        {t(
                          'profile.accountSetting.modal.emailVerify.resendCode',
                        )}
                      </span>
                      <span>{formattedTime}</span>
                    </div>
                  )}

                  {minutes === 0 && seconds === 0 && (
                    <div className="flex items-center justify-center">
                      <Button
                        onClick={() => {
                          handleResendCode()
                        }}
                        className={`w-[200px] h-[44px] rounded-md shadow-md border-none text-[16px] bg-orange-400`}
                        style={{ color: '#fff' }}
                      >
                        {t(
                          'profile.accountSetting.modal.emailVerify.resendCode',
                        )}
                      </Button>
                    </div>
                  )}
                </Col>

                <Col className="flex items-center justify-center w-full my-8 mb-[-44px]  ">
                  <Button
                    style={{ color: '#FFFFFF' }}
                    className=" w-full h-[44px] border-none text-[16px]"
                    onClick={action?.handleCancel}
                  >
                    {t('profile.accountSetting.modal.emailVerify.cancel')}
                  </Button>
                  <Button
                    htmlType="submit"
                    // disabled={disableVerify}
                    className={` btnOTPPhone w-full h-[44px] mt-1 ml-4 rounded-md shadow-md border-none text-[16px] ${
                      inputValue.length > 0
                        ? '!bg-orange-400'
                        : '!bg-orange-400 !bg-opacity-50'
                    }`}
                    style={{ color: '#fff' }}
                  >
                    {t('profile.accountSetting.modal.emailVerify.verify')}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}
