import React from 'react'
import { Form, Input } from 'antd'
import AreaSelect from './area-select'
import { useTranslation } from '~/common/hooks/useTranslation'
import { REGEX } from '~/helpers/regex'
import { useProfile } from '~/views/app/redux/hooks/useProfile'
import { CheckOutlined } from '@ant-design/icons'

export const CountryPhoneInput = ({
  selectProps = {},
  className,
  disabled,
  checkReadonlyPhone,
  phoneValue,
  ...inputProps
}) => {
  const { onChangePhone } = inputProps
  const { t } = useTranslation()
  const commonProps = { disabled }
  const {
    data: { userInfo },
  } = useProfile()
  // Custom number validation rule using a regular expression
  const validateNumber = (_, value) => {
    const numberPattern = REGEX.PHONE_NUMBER

    if (value && value?.indexOf('-') !== -1) {
      return Promise.reject(
        'Phone number must be a string of maximum 11 digits.',
      )
    }

    if (value && value?.indexOf(' ') !== -1) {
      return Promise.reject(
        'Phone number must be a string of maximum 11 digits.',
      )
    }

    if (value && !numberPattern?.test(value)) {
      return Promise.reject(
        'Phone number must be a string of maximum 11 digits.',
      )
    }

    if (value && value.length > 11) {
      return Promise.reject(
        'Phone number must be a string of maximum 11 digits.',
      )
    }

    return Promise.resolve()
  }

  const rules = {
    phone: [
      // {
      //   required: true,
      //   message: t('validate.required', {
      //     0: t('profile.accountSetting.personalInformation.phone'),
      //   }),
      // },
      { validator: validateNumber },
    ],
  }

  return (
    <div className="w-full relative">
      <Form.Item
        rules={rules?.phone}
        label={
          <span className="font__M__plus font-bold">
            {t('profile.accountSetting.personalInformation.phone')}
          </span>
        }
        name="player_phone"
        labelAlign="left"
      >
        <div className={`${checkReadonlyPhone ? 'ReadOnly' : ''}`}>
          <Input
            onChange={onChangePhone}
            {...inputProps}
            className={
              'antd-country-phone-input pl-[120px]' +
              (className ? ` ${className}` : '')
            }
            value={phoneValue}
            readOnly={checkReadonlyPhone}
            suffix={
              <>
                {userInfo?.phone_verified && (
                  <CheckOutlined className="text-[#52c41b]" />
                )}
              </>
            }
          />
        </div>
      </Form.Item>
      <Form.Item
        name="country_code"
        className="absolute z-[100] h-[44px] top-[33px]"
        rules={rules?.code}
      >
        <AreaSelect
          onChange={onChangePhone}
          {...commonProps}
          {...selectProps}
        />
      </Form.Item>
    </div>
  )
}
