export const getTypeSale = (type) => {
    switch (type) {
        case "General Box":
            return "GENERAL_BOX"
        case "Genesis Box":
            return "GENESIS_BOX"
        case "Farm":
            return "FARM_NFT"
        case "BIT":
            return "BIT"
        case "Training Item":
            return "TRAINING_ITEM"
        default:
            return ""
    }
}

export const getGroup = (group) => {
    switch (group) {
        case "Group A":
            return "GROUP_A"
        case "Group B":
            return "GROUP_B"
        case "Group C":
            return "GROUP_C"
        case "Group D":
            return "GROUP_D"
        case "Group E":
            return "GROUP_E"
        default:
            return undefined
    }
}
export const getTypeItem = (type) => {
    switch (type) {
        case "Energy Banana":
            return "ENERGY_BANANA_SHOP"
        case "Refresh Herb":
            return "REFRESH_HERB_SHOP"
        case "Fresh Milk":
            return "GENERAL_BOX"
        case "Fairy Berry":
            return "FAIRY_BERRY_SHOP"
        case "Caramel Cake":
            return "CARAMEL_CAKE_SHOP"
        case "Chia Yogurt":
            return "CHIA_YOGURT_SHOP"
        case "Satiety Konjact":
            return "SATIETY_KONJACT_SHOP"
        case "Glorious Meat":
            return "GLORIOUS_MEAT_SHOP"
        case "Sunny Blossom":
            return "SUNNY_BLOSSOM_SHOP"
        case "Lunar Grass":
            return "LUNAR_GRASS_SHOP"
        default:
            return undefined
    }
}