import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTE as ROUTE_AUTH } from '~/views/auth/routes/config'
import SendForgotPassWordSuccess from './SendForgotPassWordSuccess'

export default function ForgotPasswordForm(props) {
  const { t } = props
  const [isSendSuccess, setIsSendSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const handleSendForgotPassword = (values) => {
    setEmail(values?.email)
    setIsSendSuccess(true)
  }

  const rules = {
    email: [
      {
        required: true,
        message: t('validate.required', {
          0: t('auth.login.form.email'),
        }),
      },
      {
        type: 'email',
        message: t('validate.isEmail', {
          0: t('auth.login.form.email'),
        }),
      },
    ],
  }

  return (
    <>
      {!isSendSuccess && (
        <Form
          layout="vertical"
          name="login-form"
          onFinish={handleSendForgotPassword}
        >
          <div className="text-[32px]">{t('auth.forgotPassword.title')}</div>

          <div className="text-[16px] mb-4 font__M__plus">
            {t('auth.forgotPassword.des')}
          </div>

          <Form.Item
            name="email"
            rules={rules.email}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Input
              placeholder={t('auth.login.form.email')}
              autoComplete="nope"
            />
          </Form.Item>

          <Button type="primary mb-4" htmlType="submit" block>
            {t('common.btn.send')}
          </Button>
        </Form>
      )}

      {isSendSuccess && (
        <>
          <SendForgotPassWordSuccess email={email} t={t} />
        </>
      )}
      <Link to={ROUTE_AUTH.LOGIN.PATH}>
        <Button block>{t('common.btn.backToLogin')}</Button>
      </Link>
    </>
  )
}
