import { USER_INFO } from '~/common/constants'
import {
  TOGGLE_COLLAPSED_NAV,
  CHANGE_LOCALE,
  SIDE_NAV_STYLE_CHANGE,
  NAV_TYPE_CHANGE,
  TOP_NAV_COLOR_CHANGE,
  HEADER_NAV_COLOR_CHANGE,
  TOGGLE_MOBILE_NAV,
  DIRECTION_CHANGE,
  UPDATE_INFO_USER,
} from '~/common/constants/ThemeConstant'
import { THEME_CONFIG } from '~/common/theme/config'

const initTheme = {
  ...THEME_CONFIG,
}

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      }
    case SIDE_NAV_STYLE_CHANGE:
      return {
        ...state,
        sideNavTheme: action.sideNavTheme,
      }
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      }
    case NAV_TYPE_CHANGE:
      return {
        ...state,
        navType: action.navType,
      }
    case TOP_NAV_COLOR_CHANGE:
      return {
        ...state,
        topNavColor: action.topNavColor,
      }
    case HEADER_NAV_COLOR_CHANGE:
      return {
        ...state,
        headerNavColor: action.headerNavColor,
      }
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav,
      }
    case DIRECTION_CHANGE:
      return {
        ...state,
        direction: action.direction,
      }
    case UPDATE_INFO_USER:
      localStorage.setItem(
        USER_INFO,
        JSON.stringify({
          ...action?.user,
          avatar: `${action?.user?.avatar}?time=${new Date()}`,

        }),
      )
      return {
        ...state,
        user: action.user,
      }
    default:
      return state
  }
}

export default theme
