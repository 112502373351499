import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTE as ROUTE_AUTH } from '~/views/auth/routes/config'

export default function ResetPasswordSuccess({ t }) {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <img
        className="mb-2"
        src={`${process.env.PUBLIC_URL}/imgs/icons/success.png`}
        alt=""
      />
      <div className="text-[20px] mb-6 font__M__plus">
        {t('auth.resetPassword.desSuccess')}
      </div>

      <Link to={ROUTE_AUTH.LOGIN.PATH} className="w-full">
        <Button type="primary" block>
          {t('common.btn.login')}
        </Button>
      </Link>
    </div>
  )
}
