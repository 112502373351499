export function getItemMenu(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}

export const formatHideMail = (email) => {
  const [name, domain] = email.split('@')
  const [beforeDot, afterDot] = domain.split('.')
  const { length: len } = name
  const maskedName = name[0] + '***' + name[len - 1]
  const maskedBeforeDot =
    beforeDot[0] + '***' + beforeDot[beforeDot?.length - 1]
  const maskedEmail = maskedName + '@' + maskedBeforeDot + '.' + afterDot
  return maskedEmail
}

export const formatAddress = (address) => {
  return address?.slice(0, 6) + '...' + address?.slice(-4)
}

export const formatAddressGiftDetail = (address) => {
  return address?.slice(0, 20) + '...' + address?.slice(-20)
}

export const formatTransaction = (txHash) => {
  return txHash.slice(0, 20) + '...'
}

export const formatLongText = (text) => {
  return text?.length > 10 ? text.slice(0, 10) + '...' : text
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatNumber = (number) => {
  if (!number) return 0

  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 3,
  }).format(number)

  const trimmedNumber = formattedNumber.replace(/\.0{3}$/, '')

  return trimmedNumber.replace('$', '')
}

export const getTypeCollection = (type) => {
  switch (type) {
    case 'Training_Item ':
      return 1
    case 'Enhance_Item':
      return 2
    case 'Fusion_Item ':
      return 3
    case 'Regeneration_Item':
      return 4
    default:
      return 1
  }
}
export const convertOasToUSD = (price, tema) => {
  if (!price || !tema) return undefined
  return (parseFloat(price) * parseFloat(tema)).toFixed(3)
}

export const convertPrice = (price, tema) => {
  if (!price || !tema) return undefined
  return (parseFloat(price) / parseFloat(tema)).toFixed(3)
}
