export const GET_FEE_START = 'GET_FEE_START'
export const GET_FEE_SUCCESS = 'GET_FEE_SUCCESS'
export const GET_FEE_FAILED = 'GET_FEE_FAILED'

export const UPDATE_FEE_START = 'UPDATE_FEE_START'
export const UPDATE_FEE_SUCCESS = 'UPDATE_FEE_SUCCESS'
export const UPDATE_FEE_FAILED = 'UPDATE_FEE_FAILED'

//-------------------------marketplace settings------------------------//
/**
 * [1]
 * get fee start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getFee(payload, onSuccess, onError) {
    return {
        type: GET_FEE_START,
        payload: payload,
        onSuccess: onSuccess,
        onError: onError,
    }
}

/**
 * get fee success action
 * @param {*} payload
 * @returns {object}
 */
export function getFeeSuccess(payload) {
    return {
        type: GET_FEE_SUCCESS,
        payload: payload,
    }
}

/**
 * get fee failed action
 * @returns {object}
 */
export function getFeeFailed() {
    return {
        type: GET_FEE_FAILED,
    }
}

/**
 * [2]
 * update fee start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateFee(payload, onSuccess, onError) {
    return {
        type: UPDATE_FEE_START,
        payload: payload,
        onSuccess: onSuccess,
        onError: onError,
    }
}

/**
 * update fee success action
 * @param {*} payload
 * @returns {object}
 */
export function updateFeeSuccess(payload) {
    return {
        type: UPDATE_FEE_SUCCESS,
        payload: payload,
    }
}

/**
 * update fee failed action
 * @returns {object}
 */
export function updateFeeFailed() {
    return {
        type: UPDATE_FEE_FAILED,
    }
}

export default {
    getFee,
    getFeeSuccess,
    getFeeFailed,

    updateFee,
    updateFeeSuccess,
    updateFeeFailed,
}
