import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from '~/common/hooks/useTranslation'
import { REGEX } from '~/helpers/regex'
import { ROUTE as ROUTE_AUTH } from '~/views/auth/routes/config'
import ResetPasswordSuccess from './ResetPasswordSuccess'

export default function Resetpassword() {
  const { t } = useTranslation()
  const [isResetSuccess, setIsResetSuccess] = useState(false)
  const handleResetpassword = () => {
    setIsResetSuccess(true)
  }

  const rules = {
    password: [
      {
        required: true,
        message: t('validate.required', {
          0: t('auth.resetPassword.form.password'),
        }),
      },
      {
        pattern: REGEX.PASSWORD,
        message: t('validate.required', {
          0: t('auth.resetPassword.form.password'),
        }),
      },
    ],
    passwordConfirm: [
      {
        required: true,
        message: t('validate.required', {
          0: t('auth.resetPassword.form.password'),
        }),
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (value && getFieldValue('password') !== value) {
            return Promise.reject(t('validate.passwordNotSame'))
          }
          return Promise.resolve();

        },
      }),
    ],
  }

  return (
    <>
      {!isResetSuccess && (
        <Form
          layout="vertical"
          name="login-form"
          onFinish={handleResetpassword}
        >
          <div className="text-[32px]">{t('auth.resetPassword.title')}</div>

          <div className="text-[16px] mb-4 font__M__plus">
            {t('auth.resetPassword.des')}
          </div>

          <Form.Item
            name="password"
            rules={rules.password}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Input.Password
              className="password"
              placeholder={t('auth.resetPassword.form.password')}
              autoComplete="nope"
            />
          </Form.Item>

          <Form.Item
            name="passwordConfirm"
            dependencies={['password']}
            rules={rules.passwordConfirm}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Input.Password
              className="passwordConfirm"
              placeholder={t('auth.resetPassword.form.passwordConfirm')}
              autoComplete="nope"
            />
          </Form.Item>

          <Button type="primary mb-4" htmlType="submit" block>
            {t('common.btn.resetPassword')}
          </Button>

          <Link to={ROUTE_AUTH.LOGIN.PATH}>
            <Button block>{t('common.btn.backToLogin')}</Button>
          </Link>
        </Form>
      )}

      {isResetSuccess && <ResetPasswordSuccess t={t} />}
    </>
  )
}
