import {
  Button,
  Dropdown,
  Form,
  Input,
  Space,
  // Switch,
  // notification,
} from 'antd'
import { useQueryState } from '~/common/hooks/useQueryState'
import { useTranslation } from '~/common/hooks/useTranslation'
import Datatable from '~/components/shared-components/Datatable'
import { EditFilled } from '@ant-design/icons'
import ShopForm from './form'
import { useEffect, useState } from 'react'
import { useShop } from '../../redux/hooks/useShop'
// import { getGroup, getTypeItem, getTypeSale } from '~/helpers/shop'
// import { NOTIFICATION_DURATION } from '~/common/constants'
import { useCommon } from '../../redux/hooks/useCommon'
import { convertPrice, formatNumber } from '~/helpers/common'
import { ICON_DOLLAR } from '~/assets/svgs'

export const Shop = () => {
  const [formSearch] = Form.useForm()
  const { t } = useTranslation()
  const {
    actions: actionsCommon,
    data: { allTEMAs },
  } = useCommon()
  const SHOP_CATEGORY = [
    {
      key: '0',
      value: '-',
      label: '--',
    },
    {
      key: '1',
      value: 'Genesis Box',
      label: t(`shopSettings.category.genesisBox`),
    },
    {
      key: '2',
      value: 'General Box',
      label: t(`shopSettings.category.generalBox`),
    },
    {
      key: '3',
      value: 'Training Item',
      label: t(`shopSettings.category.trainingItem`),
    },
    {
      key: '4',
      value: 'Farm',
      label: t(`shopSettings.category.farm`),
    },
    {
      key: '5',
      value: 'BIT',
      label: t(`shopSettings.category.BIT`),
    },
  ]
  const [category, setCategory] = useState(SHOP_CATEGORY[0])
  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  const {
    actions,
    data: { listShops, isLoading, total },
  } = useShop()
  useEffect(() => {
    actionsCommon.getTema()
  }, [])
  const columns = [
    {
      title: t('common.no'),
      dataIndex: 'key',
      key: 'key',
      render: (data) => {
        return data
      },
    },
    {
      title: t('shopSettings.form.image'),
      dataIndex: 'img',
      key: 'img',
      render: (img, record) => {
        if (img?.length < 5) {
          return record.category === 'General Box' ||
            record.category === 'Genesis Box' ? (
            // <img
            //   className="w-[40px] h-[40px]"
            //   src={`${process.env.PUBLIC_URL}/imgs/shops/${
            //     record.category === 'General Box' ? 'GeneralBox' : 'GenesisBox'
            //   }.png`}
            //   alt=""
            // />

            <>
              {record?.category === 'Genesis Box' && (
                <img
                  alt=""
                  className="w-[40px] h-[40px]"
                  src={
                    record?.img?.length < 5
                      ? `${process.env.PUBLIC_URL}/imgs/shops/genesis/${record?.img}.png`
                      : record?.img
                  }
                />
              )}
              {record?.category === 'General Box' && (
                <img
                  alt=""
                  className="w-[40px] h-[40px]"
                  src={
                    record?.img?.length < 5
                      ? `${process.env.PUBLIC_URL}/imgs/shops/general/${record?.img}.png`
                      : record?.img
                  }
                />
              )}
            </>
          ) : (
            <img
              className="w-[40px] h-[40px]"
              src={`${process.env.PUBLIC_URL}/imgs/shops/${img}.png`}
              alt=""
            />
          )
        } else {
          return <img className="w-[40px] h-[40px]" src={`${img}`} alt="" />
        }
      },
    },

    {
      title: t('shopSettings.form.category'),
      dataIndex: 'category',
      key: 'category',
      render: (category) => {
        return category
      },
    },
    {
      title: t('shopSettings.form.productName'),
      dataIndex: 'name',
      key: 'name',
      render: (name) => {
        return name
      },
    },
    {
      title: t('shopSettings.form.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price, record) => {
        return (
          <div className="flex items-center">
            {record.category === 'Training Item' ? (
              <>
                <img
                  className="w-[14px] h-[14px] mr-[5px]"
                  src={`${process.env.PUBLIC_URL}/imgs/coins/${
                    record.category === 'Training Item' ? 'bit.png' : 'oas.png'
                  }`}
                  alt=""
                />
                {record.category === 'Training Item'
                  ? price
                  : formatNumber(convertPrice(price, allTEMAs?.tema))}
              </>
            ) : (
              <>
                <img
                  className="w-[14px] h-[14px] mr-[5px]"
                  src={`${process.env.PUBLIC_URL}/svgs/dollar.svg`}
                  alt=""
                />
                {price} (
                <img
                  className="w-[14px] h-[14px] mr-[5px]"
                  src={`${process.env.PUBLIC_URL}/imgs/coins/${
                    record.category === 'Training Item' ? 'bit.png' : 'oas.png'
                  }`}
                  alt=""
                />
                {formatNumber(convertPrice(price, allTEMAs?.tema))})
              </>
            )}
          </div>
        )
      },
    },
    {
      title: t('shopSettings.form.quantity'),
      dataIndex: 'total',
      key: 'total',
      render: (total) => {
        return total
      },
    },
    {
      title: t('shopSettings.form.available'),
      dataIndex: 'remaining',
      key: 'remaining',
      render: (remaining) => {
        return remaining
      },
    },
    // {
    //   title: t('common.status'),
    //   key: 'paused',
    //   render: (_, data) => {
    //     return (
    //       <Switch
    //         checkedChildren={t('common.on')}
    //         unCheckedChildren={t('common.off')}
    //         onChange={(value) => {
    //           actions?.updateStatusShop(
    //             {
    //               sale_type: getTypeSale(data?.category),
    //               group: getGroup(data?.name),
    //               package: data?.category === 'BIT' ? 'Package_0' : undefined,
    //               type_item: getTypeItem(data?.name),
    //               status: !value,
    //             },
    //             (res) => {
    //               if (res?.message === 'Set status successfully!') {
    //                 notification.success({
    //                   message: t('common.message.updateSuccessfully'),
    //                   duration: NOTIFICATION_DURATION,
    //                 })
    //               }
    //             },
    //           )
    //         }}
    //         className="w-[53px]"
    //         defaultChecked={!data?.paused}
    //       />
    //     )
    //   },
    // },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<EditFilled style={{ color: '#36CFC9' }} />}
              onClick={() => onShowModalForm(data?._id, data)}
            />
          </Space>
        )
      },
    },
  ]

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    shop: {},
  })

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }

  // Handle open modal (create-edit-delete)
  const onShowModalForm = (id, shop) => {
    setModalForm({
      id,
      isOpenModal: true,
      shop: shop,
    })
  }

  // Handle close modal (create-edit-delete)
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      shop: {},
    })
  }

  //Refresh data
  const refreshData = () => {
    const params = {
      search: keyword,
      pageSize: pageSize,
      category: category?.value !== '-' ? category?.value : undefined,
      page,
    }
    actions.getListShop(params)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword, category])

  const handleChangeSort = (e) => {
    setCategory(SHOP_CATEGORY[e.key])
  }
  return (
    <div>
      <div className="flex justify-end gap-0 sm:gap-3">
        <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0">
          <Form
            onFinish={handleSearch}
            form={formSearch}
            translate="yes"
            autoComplete="off"
            size="middle"
            validateTrigger={['onBlur', 'onChange']}
            requiredMark={false}
          >
            <Form.Item
              className="w-full"
              label={
                <span className="font__M__plus font-bold">
                  {t('common.search')}
                </span>
              }
              name="keyword"
              labelAlign="left"
            >
              <Input
                className="w-full"
                placeholder={t('common.placeholderSearch')}
              />
            </Form.Item>
          </Form>

          <div className="flex items-baseline">
            <div className="mr-2 font__Rexliafree">
              {t('shopSettings.form.category')}
            </div>
            <Dropdown
              className="flex items-center cursor-pointer ml-3 sort__value"
              menu={{
                items: SHOP_CATEGORY,
                selectable: true,
                onClick: handleChangeSort,
              }}
              placement="bottomRight"
              trigger={['click']}
            >
              <Button className="flex !bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[160px]">
                <div className="title">{category?.label}</div>
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>

      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listShops}
        showPagination
        showHeader
        loading={isLoading}
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />
      <ShopForm
        tema={allTEMAs?.tema}
        isShowModal={modalForm.isOpenModal}
        onClose={onCloseModalForm}
        refreshData={refreshData}
        id={modalForm?.id}
        shop={modalForm?.shop}
        listShops={listShops}
      />
    </div>
  )
}
