import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Button, Input, Form, notification } from 'antd'
import { NOTIFICATION_DURATION } from '~/common/constants'
import { signInWithPhoneNumber } from 'firebase/auth'
import { auth } from '~/firebase'
import { useProfile } from '~/views/app/redux/hooks/useProfile'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
export function ModalPhoneVerify(props) {
  const { action, t, resultPhone, userInfo, checkNumberVerify } = props
  const [inputValue, setInputValue] = useState('')
  const [minutes, setMinutes] = useState(4)
  const [seconds, setSeconds] = useState(59)
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`

  const [verifyNew, setVerifyNew] = useState(null)
  const [checkVerify, setCheckVerify] = useState(0)
  const [disableVerify, setDisableVerify] = useState(false)
  useEffect(() => {
    checkNumberVerify(checkVerify)
  }, [checkVerify])

  useEffect(() => {
    setDisableVerify(false)
  }, [action?.isModalVerifyFailedOpen])
  const [formPhone] = Form.useForm()
  const {
    actions,
    data: { isLoadingUpdatePhone },
  } = useProfile()
  const { actions: actionTheme } = useTheme()
  const rules = {
    phone: [
      {
        required: true,
        message: t('validate.required', {
          0: t('profile.accountSetting.personalInformation.code'),
        }),
      },
    ],
  }
  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleSubmitPhone = (values) => {
    if (!disableVerify) {
      verifyOtp(values?.otp)
      setDisableVerify(true)
    }
  }

  const verifyOtp = async (otp) => {
    try {
      if (verifyNew) {
        await verifyNew
          .confirm(otp)
          ?.then(() => {
            const formData = {
              id: userInfo?._id,
              params: {
                phone_number: action?.isModalOpen?.phone,
                country_code: action?.isModalOpen?.code,
              },
            }
            actions.updatePhone(formData, () => {
              action?.handleCancel()
              action?.showModalVerifySuccess()
            })
          })
          .catch(() => action?.showModalVerifyFailed())
        setCheckVerify((prevCheckVerify) => prevCheckVerify + 1)
      } else {
        await resultPhone
          .confirm(otp)
          ?.then(() => {
            const formData = {
              id: userInfo?._id,
              params: {
                phone_number: action?.isModalOpen?.phone,
                country_code: action?.isModalOpen?.code,
              },
            }
            actions.updatePhone(formData, (res) => {
              actionTheme.updateInfoUser(res?.player_info)
              action?.handleCancel()
              action?.showModalVerifySuccess()
            })
          })
          .catch(() => action?.showModalVerifyFailed())
        setCheckVerify((prevCheckVerify) => prevCheckVerify + 1)
      }
    } catch (err) {
      notification.error({
        message: err.message,
        duration: NOTIFICATION_DURATION,
      })
    }
  }

  const closeModalVerifyPhone = () => {
    formPhone.resetFields(['otp'])
    action?.handleCancel()
    setDisableVerify(false)
  }
  useEffect(() => {
    if (!action?.isModalOpen?.isOpen) {
      formPhone.resetFields(['otp'])
      setCheckVerify(0)
      setDisableVerify(false)
    }
  }, [action?.isModalOpen])
  useEffect(() => {
    if (action?.isModalOpen?.isOpen) {
      const intervalId = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          clearInterval(intervalId)
        } else if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1)
          setSeconds(59)
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1)
        }
      }, 1000)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [action?.isModalOpen, minutes, seconds])

  const handleResendCode = async (phone) => {
    setMinutes(4)
    setSeconds(59)
    await signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
      ?.then((res) => {
        setVerifyNew(res)
      })
      ?.catch((err) => {
        window.recaptchaVerifier.clear()
        setMinutes(0)
        setSeconds(0)
        notification.error({
          message: err.message,
          duration: NOTIFICATION_DURATION,
        })
        setTimeout(() => {
          if (err?.message !== 'Firebase: Error (auth/too-many-requests).') {
            window.location.reload()
          }
        }, 2000)
      })
  }
  useEffect(() => {
    setMinutes(4)
    setSeconds(59)
  }, [action?.isModalOpen])

  return (
    <>
      <Modal
        width={572}
        centered
        maskClosable={false}
        title={
          <h3 className="flex justify-center text-[20px] font-normal text-[#fff]">
            {t('profile.accountSetting.modal.phoneVerify.title')}
          </h3>
        }
        open={action?.isModalOpen?.isOpen}
        // open={true}
        onCancel={closeModalVerifyPhone}
        footer={[]}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmitPhone}
          form={formPhone}
          translate="yes"
          autoComplete="off"
          size="middle"
          validateTrigger={['onBlur', 'onChange']}
        >
          <div className="flex justify-center gap-5 pt-10 flex-wrap gap-2 w-full mb-2">
            <div>
              <Row>
                <Col
                  span={20}
                  className="my-[-50px] mb-[30px] font__M__plus text-[16px] font-bold"
                >
                  {t('profile.accountSetting.modal.phoneVerify.message')}{' '}
                  {`(+${action?.isModalOpen?.code}) ${action?.isModalOpen?.phone}`}
                  <br />
                  {t('profile.accountSetting.modal.phoneVerify.message1')}
                </Col>

                <Col span={24} className="font__M__plus text-[16px] font-bold">
                  <div>
                    <Form.Item
                      label={
                        <span className="font__M__plus font-bold text-[16px]">
                          {t(
                            'profile.accountSetting.modal.phoneVerify.message2',
                          )}
                        </span>
                      }
                      name="otp"
                      rules={rules.phone}
                      labelAlign="left"
                    >
                      <Input
                        className="h-[44px] text-black bg-white font__M__plus text-[16px] "
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={t(
                          'profile.accountSetting.modal.phoneVerify.message2',
                        )}
                      />
                    </Form.Item>

                    {(minutes > 0 || seconds > 0) && (
                      <div className="flex items-center justify-center">
                        <span className="mr-4 text-gray-300">
                          {t(
                            'profile.accountSetting.modal.emailVerify.resendCode',
                          )}
                        </span>
                        <span>{formattedTime}</span>
                      </div>
                    )}

                    {minutes === 0 && seconds === 0 && (
                      <div className="flex items-center justify-center">
                        <Button
                          onClick={() => {
                            handleResendCode(
                              `+${action?.isModalOpen?.code}${action?.isModalOpen?.phone}`,
                            )
                          }}
                          className={`w-[200px] h-[44px] rounded-md shadow-md border-none text-[16px] bg-orange-400`}
                          style={{ color: '#fff', background: '#FAA52C' }}
                        >
                          {t(
                            'profile.accountSetting.modal.emailVerify.resendCode',
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </Col>

                <Col className="flex items-center justify-center w-full my-8 mb-[-44px] text-[16px]">
                  <Button
                    style={{ color: '#FFFFFF' }}
                    className=" w-full h-[44px] border-none text-[16px]"
                    onClick={() => {
                      action?.handleCancel()
                    }}
                  >
                    {t('profile.accountSetting.modal.phoneVerify.cancel')}
                  </Button>
                  <Button
                    loading={isLoadingUpdatePhone}
                    className={` btnOTPPhone w-full h-[44px] mt-1 ml-4 rounded-md shadow-md border-none text-[16px] ${
                      inputValue.length > 0
                        ? '!bg-orange-400'
                        : '!bg-orange-400 !bg-opacity-50'
                    }`}
                    htmlType="submit"
                    style={{ color: '#fff' }}
                  >
                    {t('profile.accountSetting.modal.phoneVerify.verify')}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}
