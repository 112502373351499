import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Popover,
  Row,
  Spin,
  Upload,
  message,
  notification,
} from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ALLOW_AVATAR_TYPES,
  API_BASE_URL,
  NOTIFICATION_DURATION,
} from '~/common/constants'
import { useTranslation } from '~/common/hooks/useTranslation'
import en from 'world_countries_lists/data/countries/en/world.json'
import { REGEX } from '~/helpers/regex'
import ChangeLanguage from './Form/ChangeLanguage'
import { ConfigProviderPhone } from '~/components/shared-components/InputPhone/config'
import { CountryPhoneInput } from '~/components/shared-components/InputPhone/phone-input'
import {
  ModalEmailVerify,
  ModalVerifyFail,
  ModalVerifySuccess,
} from './Modals/index'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import {
  AuthErrorCodes,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth'
import { auth } from '~/firebase'
import { useProfile } from '../../redux/hooks/useProfile'
import { ModalPhoneVerify } from './Modals/2ModalPhoneVerify'
import { CheckOutlined } from '@ant-design/icons'

const ProfileSettings = () => {
  const { t } = useTranslation()
  const mailInput = useRef(null)
  const [formName] = Form.useForm()
  const [formEmail] = Form.useForm()
  const [formPhone] = Form.useForm()
  const [disableVerify, setDisableVerify] = useState(false)
  const { actions: actionTheme } = useTheme()
  const {
    actions,
    data: { userInfo },
  } = useProfile()
  const [isModalEmailOpen, setIsModalEmailOpen] = useState({
    isOpen: false,
    email: null,
  })
  const [isModalPhoneOpen, setIsModalPhoneOpen] = useState({
    isOpen: false,
    phone: null,
    code: null,
  })
  const [isLoadingImage, setIsLoadingImage] = useState(false)

  const [isModalVerifySuccessOpen, setIsModalVerifySuccessOpen] =
    useState(false)
  const [isModalVerifyFailedOpen, setIsModalVerifyFailedOpen] = useState(false)
  const [resultPhone, setResultPhone] = useState('')
  const showModalEmailVerify = (email) => {
    setIsModalEmailOpen({
      isOpen: true,
      email: email,
    })
  }
  const [checkVerify, setCheckVerify] = useState(null)
  const checkNumberVerify = (number) => {
    setCheckVerify(number)
  }
  const rules = {
    name: [
      () => ({
        validator(rule, value) {
          const regex = new RegExp(REGEX.PROFILE_NAME)
          if (value) {
            // Reject if value contains hyphens
            if (value.includes('-')) {
              return Promise.reject(
                t('profile.accountSetting.personalInformation.validateName'),
              )
            }
            // Validate against the regex pattern
            if (!regex.test(value)) {
              return Promise.reject(
                t('profile.accountSetting.personalInformation.validateName'),
              )
            }
          }
          return Promise.resolve()
        },
      }),

      {
        required: true,
        message: t('validate.required', {
          0: t('profile.accountSetting.personalInformation.playerName'),
        }),
      },
    ],
    email: [
      () => ({
        validator: (rule, value) => {
          const regex = new RegExp(REGEX.PROFILE_EMAIL)
          const atIndex = value?.indexOf('@')
          if (value && atIndex !== -1) {
            const domain = value?.slice(atIndex + 1)
            if (
              value &&
              (domain.split('.').find((part) => part === '') ||
                domain.startsWith('-'))
            ) {
              return Promise.reject(
                t('profile.accountSetting.personalInformation.validateEmail'),
              )
            }
          }
          if (value && !regex.test(value)) {
            return Promise.reject(
              t('profile.accountSetting.personalInformation.validateEmail'),
            )
          }
          return Promise.resolve()
        },
      }),
    ],
  }

  const onGetOtp = async (phone, code, pathPhone) => {
    setDisableVerify(true)
    startCountdown()
    try {
      await signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
        ?.then((res) => {
          setResultPhone(res)
          setIsModalPhoneOpen({
            isOpen: true,
            phone: pathPhone,
            code: code,
          })
        })
        ?.catch((err) => {
          console.log(err)
          var id = AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER
          var id2 = AuthErrorCodes.QUOTA_EXCEEDED
          var id3 = AuthErrorCodes.INVALID_PHONE_NUMBER
          const errorCode = err.message.replace(/^.*\(([^)]+)\).*$/, '$1')
          if (errorCode === id || errorCode === id2) {
            notification.error({
              message: t(
                'profile.accountSetting.personalInformation.messageError1',
              ),
              duration: NOTIFICATION_DURATION,
            })
          } else if (errorCode === id3) {
            notification.error({
              message: t(
                'profile.accountSetting.personalInformation.messageError2',
              ),
              duration: NOTIFICATION_DURATION,
            })
          } else {
            notification.error({
              message: t(
                'profile.accountSetting.personalInformation.messageError2',
              ),
              duration: NOTIFICATION_DURATION,
            })
          }
        })
    } catch (err) {
      notification.error({
        message: err.message,
        duration: NOTIFICATION_DURATION,
      })
      window.recaptchaVerifier = null
    }
  }

  const handleSubmitName = (values) => {
    const formData = {
      id: userInfo?._id,
      params: {
        user_name: values.name,
      },
    }

    actions.updateName(formData, (res) => {
      actionTheme.updateInfoUser(res)
      refreshDataAccount()
      message.success(
        t('profile.accountSetting.personalInformation.updateName'),
      )
      if (values.name) {
        setCheckReaonlyName(true)
      }
    })
  }

  const handleSubmitEmail = (values) => {
    const formData = {
      userId: userInfo?._id,
      email: values?.email || null,
    }
    actions?.sendVerifyCode(formData)

    if (values?.email) {
      showModalEmailVerify(values?.email)
    }

    if (!values.email) {
      refreshDataAccount()
      message.success(
        t('profile.accountSetting.personalInformation.updateEmail'),
      )
    }
  }

  const setUpRecaptcha = () => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        {
          size: 'invisible',
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
          },
          'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        },
      )
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }

  const handleCancel = () => {
    setIsModalEmailOpen({
      isOpen: false,
      email: null,
    })
    setIsModalPhoneOpen({
      isOpen: false,
      phone: null,
      code: null,
    })
    refreshDataAccount()
  }

  const showModalVerifySuccess = () => {
    setIsModalVerifySuccessOpen(true)
  }

  const handleCancelModalVerifySuccess = () => {
    setIsModalVerifySuccessOpen(false)
    setCheckReaonlyPhone(true)
    refreshDataAccount()
  }

  const showModalVerifyFailed = () => {
    setIsModalVerifyFailedOpen(true)
  }

  const handleCancelModalVerifyFailed = () => {
    setIsModalVerifyFailedOpen(false)
    // refreshDataAccount()
  }
  const handleCancelVerifyFail = () => {
    handleCancelModalVerifyFailed()
    setIsModalPhoneOpen({
      isOpen: false,
      phone: null,
      code: null,
    })
    setIsModalEmailOpen({
      isOpen: false,
      email: null,
    })
  }
  const refreshDataAccount = () => {
    actions?.getProfileInfo()
  }
  useEffect(() => {
    refreshDataAccount()
    setUpRecaptcha()
  }, [])

  useEffect(() => {
    if (userInfo) {
      formName.setFieldsValue({ name: userInfo?.user_name })
      formEmail.setFieldsValue({ email: userInfo?.email })
      formPhone.setFieldsValue({ phone_number: userInfo?.phone_number })
      formPhone.setFieldsValue({ country_code: userInfo?.country_code })
    }
  }, [userInfo])

  const handleBeforUploadImage = (file) => {
    const isFileTypeValid = ALLOW_AVATAR_TYPES.includes(file.type)
    const LIMIT_SIZE_OF_IMAGE = 2 * 1000000 // 2 megabytes in bytes

    if (!isFileTypeValid) {
      message.error(t('profile.accountSetting.uploadFile.errorTypeValidate'))
    }
    if (file.size > LIMIT_SIZE_OF_IMAGE) {
      message.error(t('profile.accountSetting.uploadFile.errorSize'))
      return false // Prevent upload
    }
    return isFileTypeValid
  }
  const handleChangeAvatar = useCallback(async (fileAvatar) => {
    try {
      const isFileTypeValid = ALLOW_AVATAR_TYPES.includes(fileAvatar.file?.type)
      const LIMIT_SIZE_OF_IMAGE = 2 * 1000000 // 2 megabytes in bytes

      if (!isFileTypeValid) {
        return
      }
      if (fileAvatar?.file?.size > LIMIT_SIZE_OF_IMAGE) {
        return // Prevent upload
      }

      setIsLoadingImage(true)
      if (fileAvatar.file.status === 'done') {
        message.success(t('profile.accountSetting.uploadFile.success'))
        actions.getProfileInfo(null, (res) => {
          actionTheme.updateInfoUser({
            ...res,
          })
          setIsLoadingImage(false)
        })
      } else if (fileAvatar.file.status === 'error') {
        message.error(
          t('profile.accountSetting.uploadFile.fail', {
            nameFile: fileAvatar.file.name,
          }),
        )
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('error' + error.message)
    }
  }, [])
  const [avatarUrl, setAvatarUrl] = useState(
    `${process.env.REACT_APP_BASE_URL}${userInfo?.avatar?.replace(
      'public',
      '',
    )}?date=${new Date()}`,
  )
  useEffect(() => {
    setAvatarUrl(
      `${process.env.REACT_APP_BASE_URL}${userInfo?.avatar?.replace(
        'public',
        '',
      )}?date=${new Date()}`,
    )
  }, [userInfo?.avatar])
  //setTimout disableVerify
  const [countdown, setCountdown] = useState(30)
  const [intervalId, setIntervalId] = useState(null)
  const startCountdown = () => {
    const id = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(id)
          setDisableVerify(false)
          return 30
        }
        return prevCountdown - 1
      })
    }, 1000)
    setIntervalId(id)
  }

  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    }
  }, [intervalId])

  const [emailValue, setEmailValue] = useState(null)
  const [phoneValue, setPhoneValue] = useState(null)
  const [checkReadonlyEmail, setCheckReaonlyEmail] = useState(false)
  const [checkReadonlyPhone, setCheckReaonlyPhone] = useState(false)
  const [checkReadonlyName, setCheckReaonlyName] = useState(false)
  const [checkDisableEmail, setCheckDisableEmail] = useState(null)
  const [checkDisablePhone, setCheckDisablePhone] = useState(null)
  const [checkDisableName, setCheckDisableName] = useState(null)
  const handleChangeEmail = (e) => {
    setEmailValue(e.target.value)
    if (e.target.value === userInfo?.email) {
      setCheckDisableEmail(true)
    } else {
      setCheckDisableEmail(false)
    }
  }
  const handleChangeName = (e) => {
    if (e.target.value === userInfo?.user_name) {
      setCheckDisableName(true)
    } else {
      setCheckDisableName(false)
    }
  }

  useEffect(() => {
    setEmailValue(userInfo?.email)
    setPhoneValue(userInfo?.phone_number)
  }, [userInfo])
  useEffect(() => {
    if (userInfo?.email_verified) {
      setCheckReaonlyEmail(true)
    }
  }, [userInfo?.email_verified])
  useEffect(() => {
    if (userInfo?.phone_verified) {
      setCheckReaonlyPhone(true)
    }
    if (phoneValue === undefined) {
      setCheckDisablePhone(true)
    } else {
      setCheckDisablePhone(false)
    }
  }, [userInfo?.phone_verified])
  useEffect(() => {
    if (phoneValue === undefined) {
      setCheckDisablePhone(true)
    } else {
      setCheckDisablePhone(false)
    }
    if (
      phoneValue === userInfo?.phone_number &&
      userInfo?.phone_verified === true
    ) {
      setCheckDisablePhone(true)
    } else {
      setCheckDisablePhone(false)
    }
  }, [userInfo?.phone_verified, userInfo?.phone_number, phoneValue])
  useEffect(() => {
    if (userInfo?.user_name) {
      setCheckReaonlyName(true)
    }
  }, [userInfo?.user_name])
  const onChangePhone = (e) => {
    setPhoneValue(e.target.value)
    if (e.target.value === userInfo?.phone_number) {
      setCheckDisablePhone(true)
    } else {
      setCheckDisablePhone(false)
    }
    return formPhone.setFields([
      {
        name: 'country_code',
        errors: [],
      },
    ])
  }
  const handleSubmitPhone = (values) => {
    if (values?.country_code === undefined) {
      formPhone.setFields([
        {
          name: 'country_code',
          errors: [
            t('validate.required', {
              0: t('profile.accountSetting.personalInformation.countryCode'),
            }),
          ],
        },
      ])
      return
    }
    if (values?.player_phone || phoneValue) {
      const phone = '+' + values?.country_code + phoneValue?.replace('+', '')
      onGetOtp(phone, values?.country_code, phoneValue?.replace('+', ''))
    }
    if (phoneValue === null || !values?.player_phone) {
      const formData = {
        id: userInfo?._id,
        params: {
          phone_number: null,
          country_code: values?.country_code,
        },
      }
      actions.updatePhone(formData, (res) => {
        actionTheme.updateInfoUser(res)
      })
      refreshDataAccount()
      message.success(
        t('profile.accountSetting.personalInformation.updatePhone'),
      )
    }
  }

  return (
    <Row gutter={20}>
      <div id="recaptcha-container"></div>
      <Col
        xl={{ span: 18, order: 1 }}
        md={{ span: 18, order: 1 }}
        sm={{ span: 24, order: 2 }}
        xs={{ span: 24, order: 2 }}
      >
        <div className="text-[24px] font__Rexliafree mb-3">
          {t('profile.accountSetting.personalInformation.title')}
        </div>
        <Card>
          {/* playerName + email */}
          <Row gutter={10}>
            <Col xl={12} md={12} sm={24} xs={24}>
              <Form
                layout="vertical"
                onFinish={handleSubmitName}
                form={formName}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Row className="mb-3 md:mb-0" gutter={10}>
                  <Col xl={16} md={16} sm={24} xs={24}>
                    <Form.Item
                      label={
                        <span className="font__M__plus font-bold">
                          {t(
                            'profile.accountSetting.personalInformation.playerName',
                          )}
                          <span style={{ color: 'red' }}> *</span>
                        </span>
                      }
                      name="name"
                      rules={rules.name}
                      labelAlign="left"
                    >
                      {/* <Input
                        className={`h-[44px] font-bold ${
                          checkReadonlyName ? 'bg-[#283E79]' : ''
                        }`}
                        style={{
                          border: checkReadonlyName ? '1px solid #283e79' : '',
                        }}
                        readOnly={checkReadonlyName}
                        placeholder={t(
                          'profile.accountSetting.personalInformation.playerName',
                        )}
                        onChange={handleChangeName}
                      /> */}
                      <Input
                        className={`h-[44px] font-bold `}
                        style={{
                          border: checkReadonlyName ? '1px solid #283e79' : '',
                          background: checkReadonlyName ? '#283E79' : '',
                        }}
                        placeholder={t(
                          'profile.accountSetting.personalInformation.email',
                        )}
                        onChange={handleChangeName}
                        readOnly={checkReadonlyName}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xl={8}
                    md={8}
                    sm={24}
                    xs={24}
                    className="block items-center justify-center w-full"
                    style={{ minWidth: '100px' }}
                  >
                    <div className="h-[22px]"></div>
                    {/* <Button
                      // loading={isLoadingName}
                      className=" btnVerify bg-[#1890FF] w-full h-[42px]"
                      type="primary"
                      htmlType="submit"
                      block
                      disabled={checkDisableName}
                    >
                      {t('common.btn.save')}
                    </Button> */}
                    {!checkReadonlyName && (
                      <Button
                        className=" btnVerify bg-[#1890FF] w-full h-[42px] mt-2"
                        type="primary"
                        htmlType="submit"
                        disabled={checkDisableName}
                      >
                        <div>{t('common.btn.save')}</div>
                      </Button>
                    )}
                    {checkReadonlyName && (
                      <Button
                        className=" btnVerify bg-[#1890FF] w-full h-[42px] mt-2"
                        type="primary"
                        onClick={() => setCheckReaonlyName(false)}
                      >
                        <div>{t('common.btn.change')}</div>
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xl={12} md={12} sm={24} xs={24}>
              <Form
                id="FormSendMail"
                layout="vertical"
                onFinish={handleSubmitEmail}
                form={formEmail}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Row className="mb-3 md:mb-0" gutter={10}>
                  <Col xl={16} md={16} sm={24} xs={24}>
                    <Form.Item
                      label={
                        <span className="font__M__plus font-bold">
                          {t(
                            'profile.accountSetting.personalInformation.email',
                          )}
                        </span>
                      }
                      name="email"
                      rules={rules.email}
                      labelAlign="left"
                    >
                      <div
                        className={`${checkReadonlyEmail ? 'ReadOnly' : ''}`}
                      >
                        <Input
                          ref={mailInput}
                          value={emailValue}
                          className="h-[44px]  font-bold "
                          placeholder={t(
                            'profile.accountSetting.personalInformation.email',
                          )}
                          onChange={handleChangeEmail}
                          readOnly={checkReadonlyEmail}
                          suffix={
                            <>
                              {userInfo?.email_verified && (
                                <CheckOutlined className="text-[#52c41b]" />
                              )}
                            </>
                          }
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col
                    xl={8}
                    md={8}
                    sm={24}
                    xs={24}
                    className="flex items-center justify-center w-full"
                    style={{ minWidth: '100px' }}
                  >
                    {(!userInfo?.email_verified || !checkReadonlyEmail) && (
                      <Button
                        className=" btnVerify bg-[#1890FF] w-full h-[42px] mt-2"
                        type="primary"
                        htmlType="submit"
                        disabled={checkDisableEmail}
                      >
                        <span className="ml-[-6px]">
                          {emailValue !== userInfo?.email
                            ? emailValue
                              ? t('common.btn.saveAndVerify')
                              : t('common.btn.save')
                            : t('common.btn.verify')}
                        </span>
                      </Button>
                    )}
                    {userInfo?.email_verified && checkReadonlyEmail && (
                      <Button
                        className="bg-[#1890FF] w-full h-[42px] mt-2"
                        type="primary"
                        onClick={() => setCheckReaonlyEmail(false)}
                      >
                        <span className="ml-[-6px]">
                          {t('common.btn.change')}
                        </span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {/* phone + x connect */}
          <Row gutter={10}>
            <Col xl={12} md={12} sm={24} xs={24}>
              <Form
                layout="vertical"
                onFinish={disableVerify ? null : handleSubmitPhone}
                form={formPhone}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                initialValues={{ country_code: '84' }}
                requiredMark={false}
              >
                <Row className="mb-3 md:mb-0" gutter={10}>
                  <Col xl={16} md={16} sm={24} xs={24}>
                    <ConfigProviderPhone locale={en}>
                      <CountryPhoneInput
                        userInfo={userInfo}
                        onChangePhone={onChangePhone}
                        className="h-[44px] font-bold"
                        checkReadonlyPhone={checkReadonlyPhone}
                        phoneValue={phoneValue}
                      />
                    </ConfigProviderPhone>
                  </Col>
                  <Col
                    xl={8}
                    md={8}
                    sm={24}
                    xs={24}
                    className="flex items-center justify-center w-full"
                    style={{ minWidth: '100px' }}
                  >
                    {
                      (!userInfo?.phone_verified || !checkReadonlyPhone) &&
                        (checkDisablePhone ? (
                          <Popover
                            content={<>{t('common.btn.messageNotVerify')}</>}
                            trigger="hover" // Popover appears on hover
                          >
                            <Button
                              htmlType="submit"
                              className={`btnVerify bg-[#1890FF] w-full h-[42px] mt-2 resize-text ${
                                disableVerify ? 'cursor-not-allowed' : ''
                              }`}
                              type="primary"
                              disabled={checkDisablePhone}
                            >
                              <span className="ml-[-6px]">
                                {disableVerify ? (
                                  <div>{countdown}</div>
                                ) : (
                                  <>
                                    {phoneValue !== userInfo?.phone_number
                                      ? phoneValue
                                        ? t('common.btn.saveAndVerify')
                                        : t('common.btn.save')
                                      : t('common.btn.verify')}
                                  </>
                                )}
                              </span>
                            </Button>
                          </Popover>
                        ) : (
                          <Button
                            htmlType="submit"
                            className={`btnVerify bg-[#1890FF] w-full h-[42px] mt-2 resize-text ${
                              disableVerify ? 'cursor-not-allowed' : ''
                            }`}
                            type="primary"
                            disabled={checkDisablePhone}
                          >
                            <span className="ml-[-6px]">
                              {disableVerify ? (
                                <div>{countdown}</div>
                              ) : (
                                <>
                                  {phoneValue !== userInfo?.phone_number
                                    ? phoneValue
                                      ? t('common.btn.saveAndVerify')
                                      : t('common.btn.save')
                                    : t('common.btn.verify')}
                                </>
                              )}
                            </span>
                          </Button>
                        ))
                      // <Popover
                      //   content={<>{t('common.btn.messageNotVerify')}</>}
                      //   visible={checkDisablePhone}
                      // >
                      //   <Button
                      //     htmlType="submit"
                      //     className={`btnVerify bg-[#1890FF] w-full h-[42px] mt-2 resize-text ${
                      //       disableVerify ? 'cursor-not-allowed' : ''
                      //     }`}
                      //     type="primary"
                      //     disabled={checkDisablePhone}
                      //   >
                      //     <span className="ml-[-6px]">
                      //       {disableVerify ? (
                      //         <div>{countdown}</div>
                      //       ) : (
                      //         <>
                      //           {phoneValue !== userInfo?.phone_number
                      //             ? phoneValue
                      //               ? t('common.btn.saveAndVerify')
                      //               : t('common.btn.save')
                      //             : t('common.btn.verify')}
                      //         </>
                      //       )}
                      //     </span>
                      //   </Button>
                      // </Popover>
                    }
                    {
                      userInfo?.phone_verified &&
                        checkReadonlyPhone &&
                        (disableVerify === false ? (
                          <Button
                            className="bg-[#1890FF] w-full h-[42px] mt-2"
                            type="primary"
                            onClick={() => setCheckReaonlyPhone(false)}
                          >
                            <span className="ml-[-6px]">
                              {t('common.btn.change')}
                            </span>
                          </Button>
                        ) : (
                          <Button
                            className="bg-[#1890FF] w-full h-[42px] mt-2 cursor-not-allowed"
                            type="primary"
                          >
                            <span className="ml-[-6px]">{countdown}</span>
                          </Button>
                        ))
                      // <Button
                      //   className="bg-[#1890FF] w-full h-[42px] mt-2"
                      //   type="primary"
                      //   onClick={() => setCheckReaonlyPhone(false)}
                      // >
                      //   <span className="ml-[-6px]">
                      //     {t('common.btn.change')}
                      //   </span>
                      // </Button>
                    }
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xl={12} md={12} sm={24} xs={24}>
              {/* Language */}
              <ChangeLanguage t={t} userInfo={userInfo} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col
        xl={{ span: 6, order: 2 }}
        md={{ span: 6, order: 2 }}
        sm={{ span: 24, order: 1 }}
        xs={{ span: 24, order: 1 }}
        className="mb-3 sm:mb-0 flex"
      >
        <div className="w-full flex flex-col items-center">
          <div className="text-[24px] font__Rexliafree text-start mb-3">
            {t('profileSettings.profileImage')}
          </div>

          <div className="h-full flex items-center justify-center">
            <div>
              <Spin spinning={isLoadingImage}>
                <Image
                  className="rounded-full"
                  width={164}
                  height={164}
                  src={avatarUrl}
                  fallback={`${process.env.PUBLIC_URL}/imgs/common/avatar-default-profile.png`}
                  loading="lazy"
                />
              </Spin>
              <div
                style={{
                  position: 'absolute',
                  borderRadius: '50%',
                  backgroundColor: '#FFF',
                  width: '42px',
                  height: '42px',
                  margin: ' -47px 0 0 125px',
                }}
              >
                <Upload
                  method="PUT"
                  accept={ALLOW_AVATAR_TYPES}
                  action={`${API_BASE_URL}/admin/profile/avatar/${userInfo?._id}`}
                  headers={{
                    authorization: `Bearer ${localStorage.getItem(
                      'auth_token',
                    )}`,
                  }}
                  beforeUpload={handleBeforUploadImage}
                  listType="picture"
                  onChange={(fileAvatar) => handleChangeAvatar(fileAvatar)}
                  name="file"
                  showUploadList={false}
                >
                  <img
                    style={{ margin: '40%' }}
                    className="w-[24px] h-[24px]"
                    src={`${process.env.PUBLIC_URL}/svgs/camera.svg`}
                    alt=""
                  />
                </Upload>
              </div>
            </div>
          </div>
        </div>
      </Col>

      <ModalEmailVerify
        action={{
          isModalVerifyFailedOpen: isModalVerifyFailedOpen,
          isModalOpen: isModalEmailOpen,
          handleCancel: handleCancel,
          showModalVerifySuccess: showModalVerifySuccess,
          showModalVerifyFailed: showModalVerifyFailed,
        }}
        userInfo={userInfo}
        checkNumberVerify={checkNumberVerify}
        t={t}
      />

      <ModalPhoneVerify
        action={{
          isModalVerifyFailedOpen: isModalVerifyFailedOpen,
          isModalOpen: isModalPhoneOpen,
          handleCancel: handleCancel,
          showModalVerifySuccess: showModalVerifySuccess,
          showModalVerifyFailed: showModalVerifyFailed,
        }}
        checkNumberVerify={checkNumberVerify}
        userInfo={userInfo}
        resultPhone={resultPhone}
        t={t}
      />

      <ModalVerifySuccess
        action={{
          isModalOpen: isModalVerifySuccessOpen,
          handleCancel: handleCancelModalVerifySuccess,
        }}
        t={t}
      />
      <ModalVerifyFail
        action={{
          isModalOpen: isModalVerifyFailedOpen,
          handleCancel: handleCancelModalVerifyFailed,
          handleCancelVerifyFail: handleCancelVerifyFail,
        }}
        t={t}
        checkVerify={checkVerify}
      />
    </Row>
  )
}

export default ProfileSettings
