import React from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import ForgotPasswordForm from './form'

export default function ForgotPassword() {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <ForgotPasswordForm t={t} />
      </div>
    </>
  )
}
