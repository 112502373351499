import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import TradeChart from '../components/TradeChart'
import { useDashboard } from '~/views/app/redux/hooks/useDashboard'

const MarketplaceDashboard = () => {
  const {
    actions,
    data: { dashboardMkpDatas },
  } = useDashboard()

  // const [date, setDate] = useState(null)
  const [date, setDate] = useState(['', ''])
  useEffect(() => {
    const params = {}
    if (date[0] !== '') {
      let temp = date[0].split('-')
      let temp1 = date[1].split('-')
      params.fromTime = Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0) / 1000
      params.toTime =
        Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59) / 1000
    }
    actions.getDashBoardMkp(params)
  }, [date])

  return (
    <React.Fragment>
      <Row gutter={10}>
        <Col span={24}>
          <div className="max-h-[341px] h-[341px]">
            <TradeChart
              dashboardMkpDatas={dashboardMkpDatas}
              setDate={setDate}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default MarketplaceDashboard
