import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  updateAdminSuccess,
  updateAdminFailed,
  UPDATE_ADMIN_START,
} from '~/views/app/redux/actions/admin'

/**
 * update user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateAdminApi = (props) => {
  const uri = `/admin/manager-user/${props?.id}`
  return api.put(uri, props?.data)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdateAdmin(action) {
  try {
    const response = yield call(updateAdminApi, action?.payload)

    if (response?.statusCode !== 400) {
      yield put(updateAdminSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      } else {
        throw response?.message
      }
    }
  } catch (error) {
    yield put(updateAdminFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch update user
 */
export default function* watchUpdateAdmin() {
  yield takeLatest(UPDATE_ADMIN_START, doUpdateAdmin)
}
