import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getFeeSuccess,
  getFeeFailed,
  GET_FEE_START,
} from '../../actions/marketplace-settings'

/**
 * get fee api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getFeeApi = (props) => {
  var uri = `/admin/fee-mkp`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetFee(action) {
  try {
    const response = yield call(getFeeApi, action?.payload)
      yield put(getFeeSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
  } catch (error) {
    yield put(getFeeFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get fee
 */
export default function* watchGetFee() {
  yield takeLatest(GET_FEE_START, doGetFee)
}
