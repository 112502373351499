import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Input, Button, notification, Radio, Select } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  LIST_CHAIN,
  LIST_TOKEN_STANDARD,
  MONSTER_SEED,
  NOTIFICATION_DURATION,
  okButtonStyle,
} from '~/common/constants'
import { useCollaboration } from '~/views/app/redux/hooks/useCollaboration'
import { REGEX } from '~/helpers/regex'
import { isAddress } from 'ethers/lib/utils'
function ManagementCollaborationNFTForm(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, collaboration } = props
  const {
    actions,
    data: { isLoading },
  } = useCollaboration()
  const [limit, setLimit] = useState('unlimited')
  const [form] = Form.useForm()

  const rules = {
    monster_seed: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.monster'),
        }),
      },
    ],
    collection_name: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.collectionName'),
        }),
      },
      {
        pattern: REGEX.NOT_WHITESPACE_START,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.collectionName'),
        }),
      },
      {
        max: 255,
        message: t('validate.max256'),
      },
    ],
    market: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.market'),
        }),
      },
      {
        pattern: REGEX.NOT_WHITESPACE_START,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.market'),
        }),
      },
      {
        max: 255,
        message: t('validate.max256'),
      },
    ],
    chain: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.chain'),
        }),
      },
    ],
    token_standard: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.tokenStandards'),
        }),
      },
    ],
    limit: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.limit'),
        }),
      },
    ],
    list_token_id: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.tokenIDList'),
        }),
      },
    ],
    contract_address: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementCollaborationNFT.form.contract'),
        }),
      },
      () => ({
        validator(rule, value) {
          if (value && !isAddress(value)) {
            return Promise.reject(t('validate.contract'))
          }

          return Promise.resolve()
        },
      }),
    ],
    note: [
      {
        max: 255,
        message: t('validate.max256'),
      },
    ],
  }

  const isNumeric = (char) => /^\d+$/.test(char)

  const handleInputKeyDown = (e) => {
    const { key } = e
    if (
      !isNumeric(key) &&
      key !== 'Backspace' &&
      key !== 'Delete' &&
      key !== 'ArrowLeft' &&
      key !== 'ArrowRight'
    ) {
      e.preventDefault()
    }
  }
  const handleSubmit = (values) => {
    const param = {
      monster_seed: values?.monster_seed,
      collection_name: values?.collection_name?.trim(),
      market: values?.market?.trim(),
      chain: values?.chain?.trim(),
      limit: limit,
      token_standard: values?.token_standard?.trim(),
      list_token_id:
        limit === 'unlimited'
          ? []
          : values?.list_token_id?.map((data) => Number(data)),
      contract_address: values?.contract_address?.trim(),
      note: values?.note?.trim(),
    }

    if (id) {
      actions?.updateCollaboration({ data: { ...param }, id: id }, () => {
        onSuccess()
      })
    } else {
      actions?.createCollaboration(param, () => {
        onSuccess()
      })
    }
  }

  const onSuccess = () => {
    notification.success({
      message: id
        ? t('common.message.updateSuccessfully')
        : t('common.message.createSuccessfully'),
      duration: NOTIFICATION_DURATION,
    })
    onClose()
    refreshData()
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (id) {
      setLimit(collaboration?.limit)
      const formDetailData = {
        monster_seed: collaboration?.monster_seed,
        collection_name: collaboration?.collection_name,
        market: collaboration?.market,
        chain: collaboration?.chain,
        limit: collaboration?.limit,
        token_standard: collaboration?.token_standard,
        list_token_id: collaboration?.list_token_id,
        contract_address: collaboration?.contract_address,
        note: collaboration?.note,
      }
      form.setFieldsValue(formDetailData)
    }
  }, [id])

  return (
    <Modal
      title={
        id
          ? t('managementCollaborationNFT.modal.editTitle')
          : t('managementCollaborationNFT.modal.addTitle')
      }
      onCancel={handleClose}
      open={isShowModal}
      width={600}
      centered
      destroyOnClose
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
        initialValues={{ limit: 'unlimited' }}
      >
        <Form.Item
          label={t('managementCollaborationNFT.form.monster')}
          name="monster_seed"
          rules={rules.monster_seed}
          labelAlign="left"
        >
          <Select
            placeholder={t('managementCollaborationNFT.form.monster')}
            options={MONSTER_SEED?.map((data, i) => {
              const monster = {
                key: i?.toString(),
                value: data,
                label: t(`managementCollaborationNFT.seed.${data}`),
              }
              return monster
            })}
          />
        </Form.Item>

        <Form.Item
          label={t('managementCollaborationNFT.form.collectionName')}
          name="collection_name"
          rules={rules.collection_name}
          labelAlign="left"
        >
          <Input
            placeholder={t('managementCollaborationNFT.form.collectionName')}
          />
        </Form.Item>

        <Form.Item
          label={t('managementCollaborationNFT.form.market')}
          name="market"
          labelAlign="left"
          rules={rules.market}
        >
          <Input placeholder={t('managementCollaborationNFT.form.market')} />
        </Form.Item>

        <Form.Item
          label={t('managementCollaborationNFT.form.chain')}
          name="chain"
          labelAlign="left"
          rules={rules.chain}
        >
          <Select
            placeholder={t('managementCollaborationNFT.form.chain')}
            options={LIST_CHAIN?.map((data, i) => {
              const chain = {
                key: i?.toString(),
                value: data,
                label: t(`managementCollaborationNFT.chain.${data}`),
              }
              return chain
            })}
          />
        </Form.Item>

        <Form.Item
          label={t('managementCollaborationNFT.form.tokenStandards')}
          name="token_standard"
          labelAlign="left"
          rules={rules.token_standard}
        >
          <Select
            placeholder={t('managementCollaborationNFT.form.tokenStandards')}
            options={LIST_TOKEN_STANDARD?.map((data, i) => {
              const chain = {
                key: i?.toString(),
                value: data,
                label: data,
              }
              return chain
            })}
          />
        </Form.Item>

        <Form.Item name="limit" rules={rules.limit}>
          <Radio.Group
            onChange={(e) => {
              setLimit(e.target.value)
            }}
          >
            <Radio value="unlimited"> Unlimited tokenID </Radio>
            <Radio value="limited"> Limited tokenID </Radio>
          </Radio.Group>
        </Form.Item>
        {limit === 'limited' && (
          <Form.Item
            label={t('managementCollaborationNFT.form.tokenIDList')}
            name="list_token_id"
            rules={rules.list_token_id}
            labelAlign="left"
          >
            <Select
              mode="tags"
              style={{
                width: '100%',
              }}
              onInputKeyDown={handleInputKeyDown}
              // onChange={handleChange}
              tokenSeparators={[',']}
              // options={options}
            />
          </Form.Item>
        )}

        <Form.Item
          label={t('managementCollaborationNFT.form.contract')}
          name="contract_address"
          labelAlign="left"
          rules={rules.contract_address}
        >
          <Input placeholder={t('managementCollaborationNFT.form.contract')} />
        </Form.Item>

        <Form.Item
          label={t('managementCollaborationNFT.form.note')}
          name="note"
          labelAlign="left"
          rules={rules.note}
        >
          <Input placeholder={t('managementCollaborationNFT.form.note')} />
        </Form.Item>

        <div key="buttons" className="flex justify-center">
          <Button className="cancel" key="cancel" onClick={handleClose}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            className="border-hidden confirm"
            htmlType="submit"
            key="ok"
            type="primary"
            onClick={form.submit}
            style={okButtonStyle}
            disabled={isLoading}
            loading={isLoading}
          >
            {id ? t('common.btn.update') : t('common.btn.add')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

ManagementCollaborationNFTForm.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default ManagementCollaborationNFTForm
