import { Button, Card, Col, Form, Input, Row, notification } from 'antd'
import React, { useEffect } from 'react'
import { NOTIFICATION_DURATION } from '~/common/constants'
import { useTranslation } from '~/common/hooks/useTranslation'
import { REGEX } from '~/helpers/regex'
import { useMarketplaceSettings } from '~/views/app/redux/hooks/useMarketplaceSettings'

const onSuccess = (message) => {
  notification.success({
    message: message,
    duration: NOTIFICATION_DURATION,
  })
}

function MarketplaceSettings() {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const {
    actions,
    data: { isLoading },
  } = useMarketplaceSettings()
  const rules = {
    fee: [
      {
        required: true,
        message: t('validate.required', {
          0: t('marketplaceSettings.marketplaceFee'),
        }),
      },
      {
        pattern: REGEX.INT_NUMBER,
        message: t('validate.intNumber'),
      },
      () => ({
        validator(rule, value) {
          if (value && value > 100) {
            return Promise.reject(
              t('validate.max100', {
                0: t('marketplaceSettings.marketplaceFee'),
              }),
            )
          }
          if (value && value < 1) {
            return Promise.reject(
              t('validate.min1', {
                0: t('marketplaceSettings.marketplaceFee'),
              }),
            )
          }
          return Promise.resolve()
        },
      }),
    ],
  }

  useEffect(() => {
    actions?.getFee({}, (res) => {
      form.setFieldsValue({
        fee: res?.fee,
      })
    })
  }, [])

  const handleSubmit = (values) => {
    actions.updateFee({ fee: values.fee }, (res) => {
      onSuccess(res?.message)
    })
  }

  return (
    <Row>
      <Col xl={12} md={12} sm={24} xs={24}>
        <Card>
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            translate="yes"
            autoComplete="off"
            size="middle"
            validateTrigger={['onBlur', 'onChange']}
          >
            <Row gutter={10}>
              <Col xl={19} md={19} sm={24} xs={24}>
                <Form.Item
                  label={t('marketplaceSettings.marketplaceFee')}
                  name="fee"
                  rules={rules.fee}
                  labelAlign="left"
                >
                  <Input
                    placeholder={t('marketplaceSettings.marketplaceFee')}
                    suffix={'%'}
                  />
                </Form.Item>
              </Col>
              <Col xl={5} md={5} sm={24} xs={24}>
                <div className="flex flex-col">
                  <div className="h-[30px]"></div>
                  <Button
                    disabled={isLoading}
                    loading={isLoading}
                    htmlType="submit"
                    type="primary"
                  >
                    {t('common.btn.save')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default MarketplaceSettings
