import {
    GET_FEE_START,
    GET_FEE_SUCCESS,
    GET_FEE_FAILED,

    UPDATE_FEE_START,
    UPDATE_FEE_SUCCESS,
    UPDATE_FEE_FAILED,
} from '../actions/marketplace-settings'

const initialState = {
    isLoading: false,
    fee: null,
}

/**
 * Marketplace Settings Management reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function marketplaceSettingsManagement(state = initialState, action) {
    switch (action.type) {
        case GET_FEE_START:
        case UPDATE_FEE_START:
            return {
                ...state,
                isLoading: true,
            }
        case GET_FEE_SUCCESS:
            return {
                ...state,
                fee: action?.payload?.fee,
                isLoading: false,
            }
        case UPDATE_FEE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case GET_FEE_FAILED:
        case UPDATE_FEE_FAILED:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
