import { Button } from 'antd'
import React from 'react'
import { formatHideMail } from '~/helpers/common'

export default function SendForgotPassWordSuccess(props) {
  const { email, t } = props
  return (
    <div>
      <div className="text-[32px]">{t('auth.forgotPassword.titleSendSuccess')}</div>

      <div className="text-[16px] mb-4 font__M__plus">
        {t('auth.forgotPassword.desSuccess', { email: formatHideMail(email) })}
      </div>

      <div className="text-[16px] mb-4 font__M__plus">
        {t('auth.forgotPassword.subDesSuccess')}
      </div>

      <Button type="primary mb-4" htmlType="submit" block>
        {t('common.btn.reSendEmail')}
      </Button>
    </div>
  )
}
