/**
 * Constructor
 */
const conf = {
  /**
   * Development configurations
   */
  development: {
    rpc: 'https://rpc.sandverse.oasys.games/',
    chainId: 20197,
    scan: "https://explorer.sandverse.oasys.games/"
  },

  /**
   * Production configurations
   */
  production: {
    rpc: 'https://rpc.oasys.mycryptoheroes.net/',
    chainId: 29548,
    scan: "https://explorer.oasys.mycryptoheroes.net/"
  },
}

/**
 * Module exports
 */
export default conf;
