import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  updateNameSuccess,
  updateNameFailed,
  UPDATE_NAME_START,
} from '../../actions/profile'

/**
 * update Name api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateNameApi = (props) => {
  var uri = `/admin/profile/${props?.id}`
  return api.put(uri, props?.params)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdateName(action) {
  try {
    const response = yield call(updateNameApi, action?.payload)
      yield put(updateNameSuccess(response?.data))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response?.data)
      }
  } catch (error) {
    yield put(updateNameFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch update Name
 */
export default function* watchUpdateName() {
  yield takeLatest(UPDATE_NAME_START, doUpdateName)
}
