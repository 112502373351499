import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getHistoryTradeSuccess,
  getHistoryTradeFailed,
  GET_HISTORY_TRADE_START,
} from '../../actions/history-trade'

/**
 * get history trade api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getHistoryTradeApi = (props) => {
  var uri = `/admin/history`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetHistoryTrade(action) {
  try {
    const response = yield call(getHistoryTradeApi, action?.payload)
    if (response?.statusCode !== 400) {
      yield put(getHistoryTradeSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response)
      }
    } else {
      throw response?.message
    }
  } catch (error) {
    yield put(getHistoryTradeFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get history trade
 */
export default function* watchGetHistoryTrade() {
  yield takeLatest(GET_HISTORY_TRADE_START, doGetHistoryTrade)
}
