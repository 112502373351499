export const GET_LIST_GIFTS_START = 'GET_LIST_GIFTS_START'
export const GET_LIST_GIFTS_SUCCESS = 'GET_LIST_GIFTS_SUCCESS'
export const GET_LIST_GIFTS_FAILED = 'GET_LIST_GIFTS_FAILED'

export const SEND_GIFT_START = 'SEND_GIFT_START'
export const SEND_GIFT_SUCCESS = 'SEND_GIFT_SUCCESS'
export const SEND_GIFT_FAILED = 'SEND_GIFT_FAILED'


//------------------------- Shop ------------------------//
/**
 * [1]
 * get list gift start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListGifts(payload, onSuccess, onError) {
  return {
    type: GET_LIST_GIFTS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list gift success action
 * @param {*} payload
 * @returns {object}
 */
export function getListGiftsSuccess(payload) {
  return {
    type: GET_LIST_GIFTS_SUCCESS,
    payload: payload,
  }
}

/**
 * get list gift failed action
 * @returns {object}
 */
export function getListGiftsFailed() {
  return {
    type: GET_LIST_GIFTS_FAILED,
  }
}

/**
 * [2]
 * update status shop start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function sendGift(payload, onSuccess, onError) {
  return {
    type: SEND_GIFT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update status shop success action
 * @param {*} payload
 * @returns {object}
 */
export function sendGiftSuccess(payload) {
  return {
    type: SEND_GIFT_SUCCESS,
    payload: payload,
  }
}

/**
 * update status shop failed action
 * @returns {object}
 */
export function sendGiftFailed() {
  return {
    type: SEND_GIFT_FAILED,
  }
}

export default {
  getListGifts,
  getListGiftsSuccess,
  getListGiftsFailed,

  sendGift,
  sendGiftSuccess,
  sendGiftFailed,

}
