import { addKeyToList } from '~/helpers/transform-data'
import {
  GET_LIST_SHOP_START,
  GET_LIST_SHOP_SUCCESS,
  GET_LIST_SHOP_FAILED,
  UPDATE_STATUS_SHOP_START,
  UPDATE_STATUS_SHOP_SUCCESS,
  UPDATE_STATUS_SHOP_FAILED,
  UPDATE_PRICE_SHOP_START,
  UPDATE_PRICE_SHOP_SUCCESS,
  UPDATE_PRICE_SHOP_FAILED,
} from '../actions/shop'

const initialState = {
  isLoading: false,
  listShops: [],
  total: 0
}

/**
 * shop reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function shopManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_SHOP_START:
    case UPDATE_STATUS_SHOP_START:
    case UPDATE_PRICE_SHOP_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LIST_SHOP_SUCCESS:
      return {
        ...state,
        listShops: addKeyToList(
          action.payload?.data,
          action.payload?.page,
          action.payload?.pageSize,
        ),
        total: action?.payload?.total,
        isLoading: false,
      }

    case UPDATE_STATUS_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_PRICE_SHOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case GET_LIST_SHOP_FAILED:
    case UPDATE_STATUS_SHOP_FAILED:
    case UPDATE_PRICE_SHOP_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
