export const getColorTypeItem = (type) => {
  switch (type) {
    case 'ENERGY_BANANA_SHOP':
      return 'rgba(255, 236, 61, 1)'
    case 'REFRESH_HERB_SHOP':
      return 'rgb(78,153,16)'
    case 'GENERAL_BOX':
      return 'rgb(188,214,226)'
    case 'FAIRY_BERRY_SHOP':
      return 'rgb(221,83,133)'
    case 'CARAMEL_CAKE_SHOP':
      return 'rgb(246,202,104)'
    case 'CHIA_YOGURT_SHOP':
      return 'rgb(165,16,54)'
    case 'SATIETY_KONJACT_SHOP':
      return 'rgb(187,155,114)'
    case 'GLORIOUS_MEAT_SHOP':
      return 'rgb(131,67,31)'
    case 'SUNNY_BLOSSOM_SHOP':
      return 'rgb(232,246,243)'
    case 'LUNAR_GRASS_SHOP':
      return 'rgb(208,212,246)'
    default:
      return undefined
  }
}

export const getColorTypeGame = (type) => {
  switch (type) {
    case 'total_monster':
      return 'rgba(255, 236, 61, 1)'
    case 'current_monster':
      return 'rgb(78,153,16)'
    case 'current_memory':
      return 'rgb(188,214,226)'
    case 'monster_training_completed':
      return 'rgb(221,83,133)'
    case 'used_in_rank_match':
      return 'rgb(246,202,104)'
    case 'win_rank_match':
      return 'rgb(165,16,54)'
    case 'lose_rank_match':
      return 'rgb(187,155,114)'
    case 'used_in_tournament':
      return 'rgb(131,67,31)'
    case 'monster_rank':
      return 'rgb(232,246,243)'
    case 'training_item_use':
      return 'rgb(208,212,246)'
    case 'average_basic_parameter':
      return 'rgba(123, 45, 67, 0.56)'
    case 'average_overall':
      return 'rgba(200, 123, 34, 0.78)'
    case 'max_basic_parameter':
      return 'rgba(50, 100, 150, 0.34)'
    case 'personality':
      return 'rgba(255, 0, 0, 0.9)'
    case 'innate_traits':
      return 'rgba(130, 45, 220, 0.72)'
    case 'accquired_traits':
      return 'rgba(90, 180, 60, 0.51)'
    case 'win_tournament':
      return 'rgba(85, 170, 255, 0.47)'

    case 'new_user_today':
      return 'rgba(255, 236, 61, 1)'
    case 'total_user':
      return 'rgb(78,153,16)'
    case 'active_user':
      return 'rgb(188,214,226)'
    case 'retention_rate':
      return 'rgb(221,83,133)'
    case 'average_play_time':
      return 'rgb(246,202,104)'
    case 'average_play_count':
      return 'rgb(165,16,54)'
    case 'time_zone_distribution':
      return 'rgb(187,155,114)'
    case 'location':
      return 'rgb(131,67,31)'
    case 'number_of_sms_verification':
      return 'rgb(232,246,243)'

    case 'revenue':
      return 'rgba(255, 236, 61, 1)'
    case 'monster_mint':
      return 'rgb(78,153,16)'
    case 'nft_repair':
      return 'rgb(188,214,226)'
    case 'shop':
      return 'rgb(221,83,133)'
    case 'guild':
      return 'rgb(246,202,104)'
    case 'change_guild':
      return 'rgb(165,16,54)'

    case 'number_of_login_days':
      return 'rgba(255, 236, 61, 1)'
    case 'player_rank':
      return 'rgb(78,153,16)'
    case 'time_passed_in_game':
      return 'rgb(188,214,226)'
    case 'chosen_meal':
      return 'rgb(221,83,133)'
    case 'training_count':
      return 'rgb(246,202,104)'
    case 'intensive_training_count':
      return 'rgb(165,16,54)'
    case 'tournament_battle_count':
      return 'rgb(187,155,114)'
    case 'tournament_battle_win_count':
      return 'rgb(131,67,31)'
    case 'rest_count':
      return 'rgb(232,246,243)'
    case 'hospital_count':
      return 'rgb(208,212,246)'
    case 'exploration_count':
      return 'rgba(123, 45, 67, 0.56)'

    case 'crystalization_count':
      return 'rgba(255, 236, 61, 1)'
    case 'used_crystalize':
      return 'rgb(78,153,16)'
    case 'crystalizeed_monster':
      return 'rgb(188,214,226)'
    case 'average_crystalize_overall':
      return 'rgb(221,83,133)'
    case 'max_crystalize_overall':
      return 'rgb(246,202,104)'
    case 'monster_rank_crystalized':
      return 'rgb(165,16,54)'
    case 'average_basic_crystalize_parameter':
      return 'rgb(187,155,114)'
    case 'average_basic_crystalize_parameter_used':
      return 'rgb(131,67,31)'
    case 'total_crystalize_trait':
      return 'rgb(232,246,243)'
    case 'used_crystalize_trait':
      return 'rgb(208,212,246)'

    case 'coach_count':
      return 'rgba(255, 236, 61, 1)'
    case 'coach_used':
      return 'rgb(78,153,16)'
    case 'coach_monster':
      return 'rgb(188,214,226)'
    case 'average_coach_overall':
      return 'rgb(221,83,133)'
    case 'max_coach_overall':
      return 'rgb(246,202,104)'
    case 'monster_rank_coached':
      return 'rgb(165,16,54)'
    case 'average_basic_coach_parameter':
      return 'rgb(187,155,114)'
    case 'average_basic_coach_parameter_used':
      return 'rgb(131,67,31)'
    case 'parameter_selected_coach':
      return 'rgb(232,246,243)'
    case 'parameter_used_coach':
      return 'rgb(208,212,246)'

    case 'number_of_farm':
      return 'rgba(255, 236, 61, 1)'
    case 'farm_boots_item_used':
      return 'rgb(78,153,16)'
    case 'farm_average_parameter':
      return 'rgb(188,214,226)'
    case 'farm_trait_id':
      return 'rgb(221,83,133)'

    case 'item_mint_count':
      return 'rgba(255, 236, 61, 1)'
    case 'item_used_count':
      return 'rgb(78,153,16)'
    case 'item_distribution':
      return 'rgb(188,214,226)'
    case 'item_acquisition':
      return 'rgb(221,83,133)'

    default:
      return undefined
  }
}
const generatedColors = new Set()

export function getRandomColor() {
  let color
  do {
    const r = Math.floor(Math.random() * 156) + 100 // Giá trị r từ 100 đến 255
    const g = Math.floor(Math.random() * 156) + 100 // Giá trị g từ 100 đến 255
    const b = Math.floor(Math.random() * 156) + 100 // Giá trị b từ 100 đến 255
    const a = (Math.random() * 0.5 + 0.5).toFixed(2) // Giá trị alpha từ 0.50 đến 1.00
    color = `rgba(${r}, ${g}, ${b}, ${a})`
  } while (generatedColors.has(color))

  generatedColors.add(color)
  return color
}
