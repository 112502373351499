import React from 'react'

import { Layout } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { NAV_TYPE_TOP } from '~/common/constants'
import utils from '~/utils'
import { Logo } from './Logo'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import AppBreadcrumb from './AppBreadcrumb'
import NavProfile from './NavProfile'
import NavPanel from './NavPanel'
const { Header } = Layout

export const HeaderNav = (props) => {
  const { isMobile } = props
  const {
    data: { navCollapsed, mobileNav, navType, headerNavColor, currentTheme },
    actions: { toggleCollapsedNav, onMobileNavToggle },
  } = useTheme()

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP

  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === 'dark' ? '#00000' : '#ffffff',
      )
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()

  return (
    <Header
      className={`app-header ${navMode} shadow-none`}
      style={{ backgroundColor: headerNavColor, height: 'auto' }}
    >
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} navCollapsed={navCollapsed} />
        <div className="flex flex-col grow shadow-sm">
          <div className="w-100 flex justify-between">
            <div className="nav-left">
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                {isNavTop && !isMobile ? null : (
                  <li
                    className="ant-menu-item ant-menu-item-only-child"
                    onClick={() => {
                      onToggle()
                    }}
                  >
                    {navCollapsed || isMobile ? (
                      <MenuUnfoldOutlined className="nav-icon" />
                    ) : (
                      <MenuFoldOutlined className="nav-icon" />
                    )}
                  </li>
                )}
              </ul>
            </div>
            <div className="flex ml-auto">
              <NavProfile />
              <NavPanel />
            </div>
          </div>
          <div
            className="border-top"
            style={{
              marginLeft: 0,
              transition: 'all .2s',
            }}
          >
            <AppBreadcrumb />
          </div>
        </div>
      </div>
    </Header>
  )
}
