import React, { useState } from 'react'
import { SettingOutlined } from '@ant-design/icons'
import { Drawer, Menu } from 'antd'
// import ThemeConfigurator from './ThemeConfigurator'
import { Link } from 'react-router-dom'
import { useTranslation } from '~/common/hooks/useTranslation'

export function NavPanel() {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation()

  const showDrawer = () => {
    setIsVisible(true)
  }
  const onClose = () => {
    setIsVisible(false)
  }

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item key="panel" onClick={showDrawer}>
          <Link to="#">
            <SettingOutlined className="nav-icon mr-0" />
          </Link>
        </Menu.Item>
      </Menu>
      <Drawer
        title={t('theme.config')}
        placement={'right'}
        width={350}
        onClose={onClose}
        open={isVisible}
      >
        {/* <ThemeConfigurator /> */}
      </Drawer>
    </>
  )
}

export default NavPanel
