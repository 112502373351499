export const ICON_DOLLAR = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#1F1F1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 9.577C11.25 10.862 10.207 11.715 8.667 11.8435V13H7.612V11.832C6.57034 11.7145 5.58064 11.3144 4.75 10.675L5.526 9.612C6.2175 10.1495 6.872 10.5 7.6605 10.617V8.327C5.89 7.883 5.0655 7.2405 5.0655 5.9205C5.0655 4.659 6.096 3.7945 7.612 3.6775V3H8.667V3.701C9.49156 3.79259 10.2777 4.09877 10.947 4.589L10.2675 5.687C9.734 5.3015 9.1885 5.056 8.6185 4.9395V7.159C10.462 7.603 11.25 8.3155 11.25 9.577ZM7.66 6.9135V4.8575C6.8965 4.916 6.4965 5.325 6.4965 5.839C6.4965 6.329 6.7265 6.645 7.6605 6.914L7.66 6.9135ZM9.819 9.6585C9.819 9.1335 9.564 8.818 8.6185 8.549V10.664C9.3825 10.605 9.819 10.2195 9.819 9.659V9.6585Z"
        fill="white"
      />
    </svg>
  )
}

export const ICON_BIT = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.9531 5.04688H5.04688V10.9531H10.9531V5.04688ZM9.25 9.25H6.75V6.75H9.25V9.25Z"
        fill="black"
      />
      <path
        d="M15.5312 7.15625C15.79 7.15625 16 6.94625 16 6.6875V5.1875C16 4.92875 15.79 4.71875 15.5312 4.71875H14.0781V3.48438C14.0781 2.62156 13.3784 1.92188 12.5156 1.92188H11.2812V0.46875C11.2812 0.21 11.0712 0 10.8125 0H9.3125C9.05375 0 8.84375 0.21 8.84375 0.46875V1.92188H7.15625V0.46875C7.15625 0.21 6.94625 0 6.6875 0H5.1875C4.92875 0 4.71875 0.21 4.71875 0.46875V1.92188H3.48438C2.62156 1.92188 1.92188 2.62156 1.92188 3.48438V4.71875H0.46875C0.21 4.71875 0 4.92875 0 5.1875V6.6875C0 6.94625 0.21 7.15625 0.46875 7.15625H1.92188V8.84375H0.46875C0.21 8.84375 0 9.05375 0 9.3125V10.8125C0 11.0712 0.21 11.2812 0.46875 11.2812H1.92188V12.5156C1.92188 13.3784 2.62156 14.0781 3.48438 14.0781H4.71875V15.5312C4.71875 15.79 4.92875 16 5.1875 16H6.6875C6.94625 16 7.15625 15.79 7.15625 15.5312V14.0781H8.84375V15.5312C8.84375 15.79 9.05375 16 9.3125 16H10.8125C11.0712 16 11.2812 15.79 11.2812 15.5312V14.0781H12.5156C13.3784 14.0781 14.0781 13.3784 14.0781 12.5156V11.2812H15.5312C15.79 11.2812 16 11.0712 16 10.8125V9.3125C16 9.05375 15.79 8.84375 15.5312 8.84375H14.0781V7.15625H15.5312ZM11.9219 11.9219H4.07812V4.07812H11.9219V11.9219Z"
        fill="black"
      />
    </svg>
  )
}
