import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import navigationConfig from '~/helpers/navigation-config'
import IntlMessage from '~/components/util-components/IntlMessage'
import { HomeOutlined } from '@ant-design/icons'
import { ROUTE as ROUTES_APP } from '~/views/app/routes/config'

let breadcrumbData = {
  '/': {
    parentTitle: null,
    parentPath: null,
    message: 'Home',
  },
}

navigationConfig.forEach((elm) => {
  const assignBreadcrumb = (obj, parentTitle = null, parentPath = null) => {
    let objMes = {
      parentTitle: parentTitle,
      parentPath: parentPath,
      message: obj.title,
    }
    breadcrumbData[obj.path] = objMes
  }
  assignBreadcrumb(elm)
  if (elm.submenu) {
    elm.submenu.forEach((subElm) => {
      assignBreadcrumb(subElm, elm.title, elm.path)
      if (subElm.submenu) {
        subElm.submenu.forEach((elm) => {
          assignBreadcrumb(elm)
        })
      }
    })
  }
})

const AppBreadcrumb = withRouter((props) => {
  const { location } = props
  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const items = [
    {
      title: (
        <Link style={{ display: "flex", alignItems: "center", justifyContent: "center" }} to={ROUTES_APP.MARKETPLACE_DASHBOARD.PATH}>
          <HomeOutlined />
        </Link>
      ),
    },
  ]
  pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    if (url !== '/' &&
      breadcrumbData[url]?.parentTitle !== null) {
      items?.push({
        title: (
          <Link to={breadcrumbData[url]?.parentPath}>
            <IntlMessage id={`menu.${breadcrumbData[url]?.parentTitle}`} />
          </Link>
        ),
      })
    }

    const obj = {
      title: (
        <Link to={ROUTES_APP.MARKETPLACE_DASHBOARD.PATH}>
          <IntlMessage id={`menu.${breadcrumbData[url]?.message}`} />
        </Link>
      ),
    }

    return items?.push(obj)
  })
  return <Breadcrumb style={{ marginLeft: 15 }} separator="/" items={items} />
})

export default AppBreadcrumb
