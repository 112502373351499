import configs from '~/configs'

export const eventData = [
  {
    value: 'OrderCreated',
    label: 'For Sale',
  },
  {
    value: 'OrderSuccessful',
    label: 'Buy',
  },
]


export const assetTypeData = [
  {
    label: 'Monter',
    value: configs.ADDRESS_MONSTER
  },
  {
    label: 'Farm           ',
    value: configs.ADDRESS_FARM
  },
  {
    label: 'Genersis Hash',
    value: configs.ADDRESS_GENESIS
  },
  {
    label: 'General Hash',
    value: configs.ADDRESS_GENERAL
  },
  {
    label: 'Regeneration Hash',
    value: configs.ADDRESS_REGENERATION_ITEM
  },
  {
    label: 'Fushion Item',
    value: configs.ADDRESS_FUSION_ITEM
  },
  {
    label: 'Training Item',
    value: configs.ADDRESS_TRAINING_ITEM
  },
  {
    label: 'Enhance Item',
    value: configs.ADDRESS_ENHANCE_ITEM
  }
]
