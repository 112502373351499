import React from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import { isAuth } from '~/helpers/auth'

import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import appRoutes from '~/views/app/routes'
import authRoutes from '~/views/auth/routes'

import AppLocale from '~/common/langs'
import PageNotFound from '~/views/app/features/PageNotFound'
import { ROUTE as ROUTES_APP } from '~/views/app/routes/config'
import { ROUTE as ROUTES_AUTH } from '~/views/auth/routes/config'

import { AppLayout } from '~/layouts/app'
import AuthLayout from '~/layouts/auth'

/**Routing setup*/
export const Routes = (props) => {
  const { location } = props

  const {
    data: { locale },
  } = useTheme()
  
  const isAuthenticated = isAuth()
  const currentAppLocale = AppLocale[locale]

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider
        locale={currentAppLocale.antd}
        direction={'ltr'}
        autoInsertSpaceInButton={false}
        theme={{
          token: {
            fontFamily: 'M PLUS 1',
          },
        }}
      >
        <Switch>
          <Route exact path="/">
            {!isAuthenticated ? (
              <Redirect to={ROUTES_AUTH.LOGIN.PATH} />
            ) : (
              <Redirect to={ROUTES_APP.MARKETPLACE_DASHBOARD.PATH} />
            )}
          </Route>
          
          {authRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              render={(props) => (
                <AuthLayout location={location}>
                  <route.component {...props} />
                </AuthLayout>
              )}
            />
          ))}

          {appRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              render={(props) => (
                <AppLayout location={location}>
                  <route.component {...props} />
                </AppLayout>
              )}
            />
          ))}

          <Route path="*" render={(props) => <PageNotFound {...props} />} />
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

export default withRouter(Routes)
