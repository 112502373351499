import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
    updateFeeSuccess,
    updateFeeFailed,
    UPDATE_FEE_START,
} from '../../actions/marketplace-settings'

/**
 * get fee api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateFeeApi = (props) => {
    var uri = `/admin/fee-mkp`
    return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdateFee(action) {
    try {
        const response = yield call(updateFeeApi, action?.payload)
        console.log(response);
        yield put(updateFeeSuccess(response))

        // Call callback action if provided
        if (action.onSuccess) {
            yield action.onSuccess(response)
        }
    } catch (error) {
        yield put(updateFeeFailed())
        // Call callback action if provided

        if (action.onError) {
            yield action.onError(error)
        }
    }
}

/**
 * [Watcher]
 * Watch get fee
 */
export default function* watchUpdateFee() {
    yield takeLatest(UPDATE_FEE_START, doUpdateFee)
}
