import {
  GET_ROLE_START,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILED,
  GET_TEMA_START,
  GET_TEMA_SUCCESS,
  GET_TEMA_FAILED,
} from '../actions/common'

const initialState = {
  isLoading: false,
  allRoles: [],
  settings: [],
}

/**
 * Common reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function commonManagement(state = initialState, action) {
  switch (action.type) {
    case GET_ROLE_START:
    case GET_TEMA_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        allRoles: action?.payload,
        isLoading: false,
      }
    case GET_TEMA_SUCCESS:
      return {
        ...state,
        allTEMAs: action?.payload,
        isLoading: false,
      }
    case GET_ROLE_FAILED:
    case GET_TEMA_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
